import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { createRouter, createWebHistory } from "vue-router";
import Account from "../components/pages/Account.vue";
import FileTranscription from "../components/pages/FileTranscription.vue";
import QandA from "../components/pages/QandA";
import Realtime from "../components/pages/RealtimeWithSpeechToText";
import Result from "../components/pages/Result.vue";
import MemberList from "../components/pages/MemberList";
import RegistMember from "../components/parts/RegistMember.vue";
import EditEmail from "../components/parts/EditEmail";
import EditName from "../components/parts/EditName";
import EditPassword from "../components/parts/EditPassword";
import EditResult from "../components/parts/EditResult.vue";
import ResetPassword from "../components/parts/ResetPassword.vue";
import SignIn from "../components/parts/SignIn.vue";
import UpdateInfo from "../components/parts/UpdateInfo.vue";
import AuthAction from "../components/parts/AuthAction.vue";

// stripe
import StripeAddCard from "../components/Stripe/StripeAddCard";
import StripeAddSubscription from "../components/Stripe/StripeAddSubscription";
import StripeAddUsageTime from "../components/Stripe/StripeAddUsageTime";
import StripeCancelSubscription from "../components/Stripe/StripeCancelSubscription";
import StripeChangeCard from "../components/Stripe/StripeChangeCard";
import StripeChangePlan from "../components/Stripe/StripeChangePlan";
import StripeSubscription from "../components/Stripe/StripeSubscription";
import StripeSuccessful from "../components/Stripe/StripeSuccessful";
import StripeUpdateSubscription from "../components/Stripe/StripeUpdateSubscription";

// mixin
import StripeFunc from "../components/mixin/StripeFunc.js";


const routes = [
  {
    path: "/file_transcription",
    name: "FileTranscription",
    component: FileTranscription,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
      requiredPaidUsage: true,
    },
  },
  {
    path: "/result",
    name: "Result",
    component: Result,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
      requiresPaidUsage: true,
    },
  },
  {
    path: "/",
    name: "Realtime",
    component: Realtime,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: "/member_list",
    name: "MemberList",
    component: MemberList,
    meta: { 
      requiresAuth: true,
      requiresVerified: true
    },
  },
  {
    path: "/member_regist",
    name: "RegistMember",
    component: RegistMember,
    meta: { 
      requiresAuth: true,
      requiresVerified: true
    },
  },
  {
    path: "/editname",
    name: "EditName",
    component: EditName,
    meta: { requiresAuth: true },
  },
  {
    path: "/editemail",
    name: "EditEmail",
    component: EditEmail,
    meta: { requiresAuth: true },
  },
  {
    path: "/editpassword",
    name: "EditPassword",
    component: EditPassword,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit_result",
    name: "EditResult",
    component: EditResult,
    meta: { requiresAuth: true },
  },
  {
    path: "/terms_of_use",
    name: "TermsOfUse",
    meta: { requiresAuth: true },
    // 利用規約を外部サービスでホスティング
    beforeEnter() {
      window.location = `${process.env.VUE_APP_LP_URL}/user-policy`;
    },
  },
  {
    path: "/qanda",
    name: "QandA",
    component: QandA,
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/updateInfo",
    name: "updateInfo",
    component: UpdateInfo,
  },
  {
    path: "/landing_page",
    name: "LandingPage",
    // LPは外部サービスでホスティング
    beforeEnter() {
      window.location = process.env.VUE_APP_LP_URL;
    },
  },
  // Stripe関連
  {
    path: "/stripe_add_card",
    name: "StripeAddCard",
    component: StripeAddCard,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_add_usagetime",
    name: "StripeAddUsageTime",
    component: StripeAddUsageTime,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_add_subscription",
    name: "StripeAddSubscription",
    component: StripeAddSubscription,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_change_plan",
    name: "StripeChangePlan",
    component: StripeChangePlan,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_change_card",
    name: "StripeChangeCard",
    component: StripeChangeCard,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_subscription",
    name: "StripeSubscription",
    component: StripeSubscription,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_update_subscription",
    name: "StripeUpdateSubscription",
    component: StripeUpdateSubscription,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_successful",
    name: "StripeSuccessful",
    component: StripeSuccessful,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/stripe_canceled",
    name: "StripeCancelSubscription",
    component: StripeCancelSubscription,
    meta: {
      requiresAuth: true,
      requiresVerified: true,
    },
  },
  {
    path: "/landing_page_privacy_policy",
    name: "LandingPagePrivacyPolicy",
    // プライバシーポリシーは外部サービスでホスティング
    beforeEnter() {
      window.location = `${process.env.VUE_APP_LP_URL}/privacy-policy`;
    },
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/authaction",
    name: "AuthAction",
    component: AuthAction
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresVerified = to.matched.some(
    (record) => record.meta.requiresVerified
  );
  const requiresPaidUsage = to.matched.some(
    (record) => record.meta.requiresPaidUsage
  );

  // requiresAuth がtrueならログイン認証が必要！
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        // ログインしているときの処理

        // IPホワイトリストの確認
        let IpCheck = firebase
          .app()
          .functions("asia-northeast1")
          .httpsCallable("checkIpListExistence");
        IpCheck({ text: "test" })
          .then(async (result) => {
            // IPアドレス制限に引っかかった場合
            if (!result.data.permission) {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  alert(
                    "許可されていないIPアドレスからのアクセスです。ログアウトします。"
                  );
                  router.go({ path: "signin", force: true });
                })
                .catch((error) => {
                  // console.log(`ログアウト時にエラーが発生しました (${error})`);
                });
            } else {
              // ユーザープランの取得
              const uid = user.uid;
              const fs_res = await firebase
                .firestore()
                .collection("stripe_customers")
                .doc(uid)
                .get();
              let user_plan = "free";
              if (fs_res.data()) {
                user_plan = StripeFunc.methods.MemberClass(user, fs_res.data());
              }
              // 有料プランでしか使えない機能にフリープランできたら、リダイレクト
              if (requiresPaidUsage & (user_plan == "free")) {
                next({
                  path: "/",
                });
              } else {
                if (requiresVerified && !user.emailVerified) {
                  // メール未認証
                  alert(
                    "メールアドレスが未認証です。\n「設定」>「アカウント情報」から、メールアドレスを認証してください"
                  );
                  next({
                    path: "/account",
                    query: {
                      redirect: to.fullPath,
                    },
                  });
                } else {
                  // メール認証済み
                  next();
                }
              }
            }
          })
          .catch((error) => {
            // IPアドレス制限でのエラー発生
            // console.log(error);
          });
      } else {
        // 未ログインでサインインにリダイレクト
        next({
          path: "/signin",
          query: {
            redirect: to.fullPath,
          },
        });
      }
    });
  } else {
    // 未ログインでもそのまま使える機能
    next();
  }
});

export default router;
