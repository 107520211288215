<template>
  <nav class="side" :class="{ 'is-close': is_close }">
    <ul class="gnav_list">
      <li class="gnav_item" :class="{ 'is-active': $route.path == '/' }">
        <router-link to="/">
          <i class="iconfont iconfont-voice" aria-hidden="true"></i>
          <span>リアルタイム</span>
        </router-link>
      </li>
      <li
        class="gnav_item"
        :class="{
          'is-active': $route.path == '/file_transcription',
          'is-disable': item_plan == 'free',
        }"
      >
        <router-link
          :to="item_plan == 'free' ? '' : '/file_transcription'"
        >
          <i
            :class="{
              'is-disable': item_plan == 'free',
            }"
            class="iconfont iconfont-voice"
            aria-hidden="true"
          ></i>
          <span>ファイル読み込み</span>
        </router-link>
      </li>
      <li
        class="gnav_item"
        :class="{
          'is-active': $route.path == '/result' || $route.path == '/edit_result',
          'is-disable': item_plan == 'free',
        }"
      >
        <router-link
          :to="item_plan == 'free' ? '' : '/result'"
        >
          <i
            :class="{
              'is-disable': item_plan == 'free',
            }"
            class="iconfont iconfont-data"
            aria-hidden="true"
          ></i>
          <span>結果一覧</span>
        </router-link>
      </li>
      <li
        class="gnav_item"
        v-if="showSummarize"
        :class="{
          'is-active':
            $route.path == '/summarize' ||
            $route.path == '/answers' ||
            $route.path == '/q-setting',
        }"
      >
        <router-link to="/summarize">
          <i class="iconfont iconfont-text" aria-hidden="true"></i>
          <span>情報抽出</span>
        </router-link>
        <ul class="gnav-sub_list">
          <li
            class="gnav-sub_item"
            :class="{ 'is-active': $route.path == '/summarize' }"
          >
            <router-link to="/summarize">
              <span>質問開始</span>
            </router-link>
          </li>
          <li
            class="gnav-sub_item"
            :class="{ 'is-active': $route.path == '/answers' }"
          >
            <router-link to="/answers">
              <span>解答結果</span>
            </router-link>
          </li>
          <li
            class="gnav-sub_item"
            :class="{ 'is-active': $route.path == '/q-setting' }"
          >
            <router-link to="/q-setting">
              <span>質問設定</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li class="gnav_item" :class="{ 'is-active': $route.path == '/setting' }">
        <router-link to="/account">
          <i class="iconfont iconfont-setting" aria-hidden="true"></i>
          <span>設定</span>
        </router-link>
        <ul class="gnav-sub_list">
          <li
            class="gnav-sub_item"
            :class="{ 'is-active': $route.path == '/account' }"
          >
            <router-link to="/account">
              <span>アカウント情報</span>
            </router-link>
          </li>
          <li
            v-if="isShowMemberList()"
            class="gnav-sub_item"
            :class="{ 'is-active': $route.path == '/member_list' ||  $route.path == '/member_regist'}"
          >
            <router-link to="/member_list">
              <span>メンバー一覧</span>
            </router-link>
          </li>
          <li
            class="gnav-sub_item"
            :class="{ 'is-active': $route.path == '/terms_of_use' }"
          >
            <router-link to="/terms_of_use">
              <span>利用規約</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li class="gnav_item">
        <router-link to="/QandA">
          <span>Q & A</span>
        </router-link>
      </li>
      <li class="gnav_item">
        <a
          :href=contact_url
        >
          <span>お問い合わせ</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
// official
import firebase from "firebase/app";
// modules
import StripeFunc from "../mixin/StripeFunc";
import toggleFunctions from "../mixin/toggleFunctions";
// mixin
import Authority from "@/components/mixin/authority.js";

export default {
  name: "MySidebar",
  mixins: [StripeFunc, toggleFunctions, Authority],
  data() {
    return {
      is_close: false,
      item_plan: "free",
      contact_url: process.env.VUE_APP_CONTACT_URL,

      // 質問応答の表示
      showSummarize: false,
      user: firebase.auth().currentUser,
      userIdToken: "",
      memberOperationAuthority: null,
    };
  },
  async created() {
    let item_plan = await this.getUserPlan();
    if (item_plan) {
      this.item_plan = item_plan;
    }
    this.userIdToken = await firebase.auth().currentUser.getIdToken();
    if(this.item_plan == 'enterprise') {
      this.memberOperationAuthority = await this.getMemberOperationAuthority(this.userIdToken);
    }
  },
  computed: {
    isActive: function() {
      return false;
    },
  },
  methods: {
    isShowMemberList() {
      if(this.item_plan != 'enterprise') {
        return false;
      }
      return this.memberOperationAuthority && this.memberOperationAuthority['status'] && this.memberOperationAuthority['authorities']['user_operation_authority'];
    },
  },
};
</script>

<style scoped>
/* ========================= */
/* =   以下クラウドワークス   = */
/* ========================= */

.side {
  background-color: #00a4c9;
  width: 25rem;
  padding-top: 1rem;
  position: relative;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.side.is-close {
  width: 5.4rem;
}

.side.is-close .gnav_list {
  width: 5.4rem;
}

.side.is-close .gnav_item > a span {
  opacity: 0;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.side.is-close .gnav-sub_list {
  padding-right: 0;
  padding-left: 0;
}

.side.is-close .gnav-sub_item {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.side.is-close .gnav-sub_item::before {
  opacity: 0;
  visibility: hidden;
  line-height: 0;
}

.side.is-close .gnav-sub_item a span {
  opacity: 0;
  visibility: hidden;
  line-height: 0;
  white-space: nowrap;
  font-size: 0;
}

.side a {
  color: #fff;
}

.side .gnav_list {
  width: 25rem;
  position: fixed;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.side .gnav_item {
  border-top: 0.1rem solid rgba(74, 199, 228, 0.5);
  box-shadow: 0 0.1rem 0 0 rgba(0, 131, 161, 0.5) inset;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.side .gnav_item:last-of-type {
  border-bottom: 0.1rem solid rgba(74, 199, 228, 0.5);
  box-shadow: 0 0.1rem 0 0 rgba(0, 131, 161, 0.5) inset,
    0 0.1rem 0 0 rgba(0, 131, 161, 0.5);
}

.side .gnav_item a {
  display: block;
}

.side .gnav_item > a {
  min-height: 4.5rem;
  padding: 0.5rem 0 0.5rem 1rem;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.side .gnav_item > a .iconfont {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #16b2d5;
  width: 3.4rem;
  height: 3.4rem;
  font-size: 17px;
  font-size: 1.7rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.side .gnav_item > a span {
  padding-left: calc(3.4rem + 1.2rem);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  font-size: 13px;
  font-size: 1.3rem;
}

.side .gnav_item.is-active > a {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00a4c9),
    to(#4067a3)
  );
  background: linear-gradient(90deg, #00a4c9, #4067a3);
  position: relative;
}

.side .gnav_item.is-active > a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-style: solid;
  border-width: 0.6rem 0.8rem 0.6rem 0;
  border-color: transparent #eee transparent transparent;
}

.side .gnav_item.is-active > a .iconfont {
  background-color: #d1f6ff;
}

.side .gnav_item.is-active > a .iconfont::before {
  color: #00a4c9;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#4067a3),
    to(#00a4c9)
  );
  background: linear-gradient(0deg, #4067a3, #00a4c9);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
}

@media all and (-ms-high-contrast: none) {
  .side .gnav_item.is-active > a .iconfont::before {
    background: none;
  }
}

.side .gnav_item.is-active .gnav-sub_list {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00a4c9),
    to(#4067a3)
  );
  background: linear-gradient(90deg, #00a4c9, #4067a3);
}

.side .gnav-sub_list {
  padding: 0 1rem 1rem calc(1rem + 3.4rem + 1.2rem);
}

.side .gnav-sub_item {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.side .gnav-sub_item::before {
  content: "";
  display: block;
  width: 1rem;
  height: 0.2rem;
  background-color: #16b2d5;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.side .gnav-sub_item a {
  display: block;
  padding: 0.3rem 1rem;
  font-size: 12px;
  font-size: 1.2rem;
}

.side .gnav-sub_item a span {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.side .gnav-sub_item.is-active {
  font-weight: bold;
}

.side .gnav-sub_item.is-active::before {
  background-color: #62d5ef;
}

.is-disable {
  background: lightgray !important;
}
</style>
