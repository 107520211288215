// official
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import { loadStripe } from "@stripe/stripe-js";

export default {
  methods: {
    // stripeのインスタンス返す（公開可能APIキーで作成する）
    async getStripe() {
      let res = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      return res;
    },

    // tokenの取得
    async getToken() {
      self = this;
      const token = await self.stripe.createToken(self.card);
      return token;
    },

    // firestoreの状態取得
    async getFSinfo(uid) {
      self = this;
      let fs_res = await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(`${uid}`)
        .get();
      // stripeの登録の有無（カードの登録の有無）
      return fs_res.data();
    },

    // プラン取得
    async getUserPlan() {
      self = this;
      const uid = firebase.auth().currentUser.uid;
      const fs_info = await self.getFSinfo(uid);
      if (fs_info) {
        if (fs_info.subscription.item_plan == "premium") {
          return "premium";
        } else if (fs_info.subscription.item_plan == "enterprise") {
          return "enterprise";
        } else if (fs_info.subscription.item_plan == "standard") {
          return "standard";
        } else if (
          (fs_info.subscription.status.behavior == "canceled") &
          (fs_info.subscription.status.invoice.seconds > Date.now() / 1000)
        ) {
          return fs_info.subscription.status.before_plan;
        }
      } else {
        return "free";
      }
    },

    // 会員区分判定
    MemberClass(currentUser, stripe_fs_info) {
      // メール認証の有無
      let is_verified = currentUser.emailVerified;
      if (!is_verified && stripe_fs_info.subscription.item_plan === 'free'
        && stripe_fs_info.subscription.status.behavior !== 'canceled') {
        return "試用期間";
      }

      // エンタープライズ会員か否かの確認
      if (stripe_fs_info.subscription.item_plan == "enterprise") {
        return "エンタープライズ";
      }

      // エンタープライズ、フリー会員でなくメール未認証である確認
      if (stripe_fs_info.subscription.item_plan !== "enterprise" && stripe_fs_info.subscription.item_plan !== 'free' && !is_verified) {
        return "有料会員 with メールアドレス変更";
      }

      // ストライプの登録の有無
      if (!stripe_fs_info.source) {
        return "無料会員";
      }

      // テストユーザー
      if (stripe_fs_info.source == "test") {
        return "テストユーザー";
      }

      // サブルクリプションの有無
      let current_plan = stripe_fs_info.subscription.item_plan;
      let behavior = stripe_fs_info.subscription.status.behavior;
      if ((current_plan != "free") & (behavior != "canceled")) {
        return "有料会員";
      } else if (behavior == "canceled") {
        let period = stripe_fs_info.subscription.status.invoice;
        period = new Date(period.seconds * 1000);
        if (period > Date.now()) {
          return "有料会員 with period";
        } else {
          return "無料会員 with stripe";
        }
      } else {
        return "無料会員 with stripe";
      }
    },

    // 契約の確認(契約内容表示するパラメータ返す)
    async getContract(currentUser, stripe_fs_info) {
      const self = this;
      const functions = firebase.app().functions("asia-northeast1");
      let contract = {
        status: "free",
        plan: "Free Plan",
        card: "未登録",
        next: "請求予定はありません",
        billing: "0",
      };
      let member_class = self.MemberClass(currentUser, stripe_fs_info);
      // "試用期間"
      if (member_class == "試用期間") {
        contract = self.getTestModeTime();
        return contract;
      }

      // テストユーザー用
      if (member_class == "テストユーザー") {
        contract.status = stripe_fs_info.subscription.item_plan;
        contract.plan = stripe_fs_info.subscription.item_plan;
        return contract;
      }

      // カード登録なし(stripeなし)
      if (member_class == "無料会員") {
        return contract;
      }

      // エンタープライズプラン
      if (member_class == "エンタープライズ") {
        contract.status = "enterprise";
        contract.plan = "enterprise";
        return contract;
      }

      // カード登録あり・無料会員
      if (member_class == "無料会員 with stripe") {
        let last4 = stripe_fs_info.token.card.last4;
        contract["status"] = "free";
        contract["card"] = "**** **** **** " + last4;
        return contract;
      }

      // 期限あり有料会員
      if (member_class == "有料会員 with period") {
        stripe_fs_info.subscription.status.behavior;
        let last4 = stripe_fs_info.token.card.last4;
        let period = stripe_fs_info.subscription.status.invoice;
        period = new Date(period.seconds * 1000);
        period = this.formatDate(period, "yyyy/MM/dd");
        contract["status"] = "canceled";
        let current_plan = await self.getUserPlan();
        contract["plan"] = current_plan + " - 期限：" + period;
        contract["card"] = "**** **** **** " + last4;
        let invoiceFunc = await functions.httpsCallable("upcomingInvoice");
        let invoice_res = await invoiceFunc({
          stripe_id: stripe_fs_info.stripe_id,
        });
        if (invoice_res.data.error) {
          if (invoice_res.data.error.code == "invoice_upcoming_none") {
            contract["next"] = "請求予定はありません";
            contract["billing"] = 0;
          } else {
            contract["next"] =
              "請求エラーが発生しています。お問い合わせください。";
            contract["billing"] = 0;
          }
        } else {
          let next_payment_date = new Date(
            invoice_res.data.next_payment_attempt * 1000
          );
          next_payment_date = this.formatDate(next_payment_date, "yyyy/MM/dd");
          contract["next"] = next_payment_date;
          let billing = invoice_res.data.amount_due;
          contract["billing"] = billing;
        }
        return contract;
      }

      // 期限なし有料会員
      if (member_class == "有料会員") {
        let invoiceFunc = await functions.httpsCallable("upcomingInvoice");
        let invoice_res = await invoiceFunc({
          stripe_id: stripe_fs_info.stripe_id,
        });
        let next_payment_date = new Date(
          invoice_res.data.next_payment_attempt * 1000
        );
        next_payment_date = this.formatDate(next_payment_date, "yyyy/MM/dd");
        let billing = invoice_res.data.amount_due;
        let last4 = stripe_fs_info.token.card.last4;
        contract["status"] = "continue";
        contract["plan"] = stripe_fs_info.subscription.item_plan;
        contract["card"] = "**** **** **** " + last4;
        contract["next"] = next_payment_date;
        contract["billing"] = billing;
        return contract;
      }

      if (member_class == "有料会員 with メールアドレス変更") {
        let invoiceFunc = await functions.httpsCallable("upcomingInvoice");
        let invoice_res = await invoiceFunc({
          stripe_id: stripe_fs_info.stripe_id,
        });
        let next_payment_date = new Date(
          invoice_res.data.next_payment_attempt * 1000
        );
        next_payment_date = this.formatDate(next_payment_date, "yyyy/MM/dd");
        let billing = invoice_res.data.amount_due;
        let last4 = stripe_fs_info.token.card.last4;
        contract["status"] = "mail-invalid";
        contract["plan"] = stripe_fs_info.subscription.item_plan;
        contract["card"] = "**** **** **** " + last4;
        contract["next"] = next_payment_date;
        contract["billing"] = billing;
        return contract;
      }
    },

    // 試用期間の契約
    getTestModeTime() {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let creationStr = firebase.auth().currentUser.metadata.creationTime;
      let splitDate = creationStr.split(" ");
      let splitTime = splitDate[4].split(":");
      let monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let creationList = [
        Number(splitDate[3]),
        monthList.indexOf(splitDate[2]),
        Number(splitDate[1]),
        Number(splitTime[0]),
        Number(splitTime[1]),
        Number(splitTime[2]),
      ];
      let yesterdayList = [
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        today.getUTCHours(),
        today.getUTCMinutes(),
        today.getUTCSeconds(),
      ];
      let remainingTime =
        (creationList[2] - yesterdayList[2]) * 86400 +
        (creationList[3] - yesterdayList[3]) * 3600 +
        (creationList[4] - yesterdayList[4]) * 60 +
        (creationList[5] - yesterdayList[5]);
      today.setSeconds(today.getSeconds() + remainingTime + 86400);
      var deadline =
        today.getFullYear() +
        "年" +
        (today.getMonth() + 1) +
        "月" +
        today.getDate() +
        "日" +
        today.getHours() +
        "時" +
        today.getMinutes() +
        "分";
      var contract = {
        status: "Trial",
        plan: "試用期間" + " （有効期限：" + deadline + "）",
        card: "未登録",
        next: "未定",
        billing: "0",
      };
      return contract;
    },
  },
};
