<template>
  <div>
    <modal
      :name="modal_name"
      modal-class="modal modal-lg"
      wrapper-class="modal-wrapper"
      :enable-close="false"
    >
      <div v-if="is_loading" class="modal-content">
        <div class="heading-inner">
          <p>{{ load_header_text }}</p>
        </div>
        <div class="modal-body">
          <vue-element-loading
            background-color="rgba(255, 255, 255, .7)"
            :active="is_loading" 
            is-full-screen 
            spinner="line-scale"
            color="#00a4c9"
          />
        </div>
      </div>
      <div v-else>
        <div class="heading-inner">
          <slot name="modal-header" class="modal-header-text"></slot>
        </div>
        <div class="modal-body">
          <slot name="modal-body"></slot>
        </div>
        <div class="modal-footer">
          <div class="footer-left">
            <slot name="footer-left"></slot>
          </div>
          <div class="footer-right">
            <slot name="footer-right"></slot>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  name: "LoadingModal",
  data() {
    return {
      load_header_text: "少々お待ちください..."
    };
  },
  props: {
    modal_name: {
      type: String,
      defalult: ""
    },
    is_loading: {
      type: Boolean,
      default: false
    }
  },
  created: function() {},
  computed: {},
  methods: {
    show: function() {
      this.$modal.show(this.modal_name);
    },
    hide: function() {
      this.$modal.hide(this.modal_name);
    }
  }
};
</script>

<style scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.modal-content {
  height: 250px;
}

.modal-header {
  height: 20%;
  padding: 5px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.modal-header p {
  height: 20%;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
.modal-body {
  text-align: center;
  height: 60%;
  padding: 2rem;
  font-size: 1.6rem;
}
.modal-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 15px;
}
.footer-left {
  grid-column: 2;
  justify-self: center;
}
.footer-right {
  grid-column: 3;
  justify-self: right;
}
</style>
