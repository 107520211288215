<template>
  <div id="all">
    <vue-element-loading
      background-color="rgba(255, 255, 255, .7)"
      :active="loading" 
      is-full-screen 
      spinner="line-scale"
      color="#00a4c9"
    />
    <MyHeader @reset="onReset" @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <modal style="z-index: 5;" name="result-summarize" modal-class="modal modal-lg" wrapper-class="modal-wrapper" :enable-close="false">
            <div class="modal-header">
              <h2 class="heading-inner">AI議事録作成</h2>
            </div>
            <div class="modal-body result-summarize-modal-body">
              <li class="result-summarize-message">議事録を作成するための設定ができます。</li>
              <li class="result-summarize-message">作成した議事録は削除することで再度設定を変更し作成し直すことができます。</li>
              <div class="row">
                <div class="result-summarize-option">
                  <label for="summarize-language"><b>出力言語</b></label>
                  <select name="summarize-language" v-model="summarize_options.language" class="form_control-select">
                      <option selected value="日本語">日本語</option>
                      <option value="英語">English(英語)</option>
                      <option value="中国語">普通话(中国語)</option>
                      <option value="香港">粤語（香港）</option>
                      <option value="台湾">國語（台湾）</option>
                      <option value="韓国語">한국의(韓国語)</option>
                      <option value="ヒンディー語">हिन्दी(ヒンディー語)</option>
                      <option value="スペイン語">español(スペイン語)</option>
                      <option value="ロシア語">русский(ロシア語)</option>
                      <option value="ポルトガル語">português(ポルトガル語)</option>
                      <option value="フランス語">français(フランス語)</option>
                      <option value="ドイツ語">Deutsch(ドイツ語)</option>
                  </select>
                </div>
              </div>
              <li class="result-summarize-message advanced-summarize">
                <span @click="advancedSummarizeOptions">{{ result_summarize == 2 ? "▼ " : "▶ " }}詳細設定</span>
              </li>
              <div v-if="result_summarize == 2">
                <li class="result-summarize-message mt-1">会議内容を設定することで、議事録の精度を上げることができます。</li>
                <li class="result-summarize-message mb-1">未入力の場合はAIが自動で判断し議事録作成を行います。</li>
                <label for="meeting_name"><b>会議名</b></label>
                <p :style="meetingNameCharCountStyle">{{ summarize_options.meeting_name.length }}/{{ input_max_length.meeting_name }}</p>
                <input name="meeting_name" class="summarize-item" type="text" v-model="summarize_options.meeting_name" placeholder="例）経営会議" @input="checkSummarizeOptions" />
                <label for="meeting_date"><b>開催日時</b></label>
                <div>
                  <input name="meeting_date" class="summarize-item-date" type="date" v-model="summarize_options.meeting_date" />&nbsp;
                  <input name="meeting_start_time" class="summarize-item-time" type="time" v-model="summarize_options.meeting_start_time" />&nbsp;～&nbsp;
                  <input name="meeting_end_time" class="summarize-item-time" type="time" v-model="summarize_options.meeting_end_time" />
                </div>
                <label for="meeting_location"><b>場所</b></label>
                <p :style="meetingLocationCharCountStyle">{{ summarize_options.meeting_location.length }}/{{ input_max_length.meeting_location }}</p>
                <input name="meeting_location" class="summarize-item" type="text" v-model="summarize_options.meeting_location" placeholder="例）会議室A" @input="checkSummarizeOptions" />
                <label for="meeting_participants"><b>参加者</b></label>
                <p :style="meetingParticipantsCharCountStyle">{{ summarize_options.meeting_participants.length }}/{{ input_max_length.meeting_participants }}</p>
                <input name="meeting_participants" class="summarize-item" type="text" v-model="summarize_options.meeting_participants" placeholder="例）テキスタA、テキスタB" @input="checkSummarizeOptions" />
                <label for="meeting_purpose"><b>目的</b></label>
                <p :style="meetingPurposeCharCountStyle">{{ summarize_options.meeting_purpose.length }}/{{ input_max_length.meeting_purpose }}</p>
                <input name="meeting_purpose" class="summarize-item" type="text" v-model="summarize_options.meeting_purpose" placeholder="例）売り上げと目標の確認" @input="checkSummarizeOptions" />
              </div>
            </div> 
           <div class="modal-footer">
              <div class="btn_column">
                <button type="button" class="btn-display btn-primary" @click="resultSummarizeClose">
                  <span class="record-btn">キャンセル</span>
                </button>
                <button type="button" class="btn-display btn-primary" :disabled="!can_summarize" @click="summarizeResult">
                  <span class="record-btn">開始</span>
                </button>
              </div>
           </div>
        </modal>
        <modal style="z-index: 5;" name="result-translate" modal-class="modal modal-lg" wrapper-class="modal-wrapper" :enable-close="false">
            <div class="modal-header">
              <h2 class="heading-inner">会議結果の翻訳</h2>
            </div>
            <div class="modal-body result-translate-modal-body">
              <div class="row">
                <div class="result-translate-option">
                  <label for="translate-language"><b>翻訳言語</b></label>
                  <select name="translate-language" v-model="translate_language" class="form_control-select">
                      <option selected value="ja">日本語</option>
                      <option value="en">English(英語)</option>
                      <option value="zh-CN">普通话(中国語)</option>
                      <option value="zh-HK">粤語（香港）</option>
                      <option value="zh-TW">國語（台湾）</option>
                      <option value="ko">한국의(韓国語)</option>
                      <option value="hi">हिन्दी(ヒンディー語)</option>
                      <option value="es">español(スペイン語)</option>
                      <option value="ru">русский(ロシア語)</option>
                      <option value="pt">português(ポルトガル語)</option>
                      <option value="fr">français(フランス語)</option>
                      <option value="de">Deutsch(ドイツ語)</option>
                  </select>
                </div>
              </div>
            </div> 
           <div class="modal-footer">
              <div class="btn_column">
                <button type="button" class="btn-display btn-primary" @click="resultTranslateClose">
                  <span class="record-btn">キャンセル</span>
                </button>
                <button type="button" class="btn-display btn-primary" @click="translateResult">
                  <span class="record-btn">翻訳開始</span>
                </button>
              </div>
           </div>
        </modal>
        <div v-if="situation == 0" class="btn_column">
          <button type="button" class="btn-primary btn-lg" :class="{ 'btn-loading': list_loading }" @click="getList">
            <i class="iconfont iconfont-data" aria-hidden="true"></i>
            <span>結果一覧取得</span>
          </button>
        </div>
        <div v-else class="btn_column">
          <button type="button" class="btn-primary btn-lg" :class="{ 'btn-loading': list_loading }" @click="getList">
            <i class="iconfont iconfont-reset" aria-hidden="true"></i>
            <span>結果一覧更新</span>
          </button>
        </div>
        <div v-if="situation" class="message">
          <span v-if="situation == 1">結果一覧を取得しています。まもなく終わりますので、このままでお待ちください。</span>
          <span v-else-if="situation == 5">文字起こし結果を破棄し、選択ファイルを変更したい場合は「ファイル変更」ボタンを押してください。</span>
          <span v-else>左の完了済みリストから、文字起こし結果をご覧になりたいファイルを選択してください。</span>
        </div>
        <div v-if="situation > 1" class="wrapper">
          <div class="float_box left" :class="{
            gray:
              situation > 3 ||
              file_deleting ||
              error_deleting ||
              files_deleting ||
              filename_edit == 2 ||
              retrying == 2,
          }">
            <!-- 文字起こし実行中 -->
            <div v-if="before_list.length" class="before">
              <div class="heading-wrapper">
                <h2 class="heading-inner">
                  文字起こし実行中（文字起こし開始時刻）
                </h2>
              </div>
              <span>以下のファイルはもうしばらくお待ちください。</span>
              <ul>
                <li v-for="list in before_list" class="file-list">
                  {{ list[1] }} ({{ list[0] }})
                </li>
              </ul>
            </div>

            <!-- エラー発生したファイル -->
            <div v-if="error_list.length" class="before">
              <div class="heading-wrapper">
                <h2 class="heading-inner">エラー発生（文字起こし開始時刻）</h2>
              </div>
              <span>音声が長時間もしくは大容量である可能性があります。再度実行か削除してください。削除する場合はファイル名左のチェックボックスで選択、再度実行する場合はファイル名を選択して右側の｢再度文字起こし実行｣を選択してください。</span>
              <div class="btn_column">
                <button type="button" class="btn-display btn-primary" @click="chooseAllError">
                  <span>全選択</span>
                </button>
                <button type="button" class="btn-display btn-primary" @click="chooseNoError">
                  <span>全選択解除</span>
                </button>
                <button type="button" class="btn-display btn-primary" :class="{ 'btn-loading': !chosen_error.length }"
                  @click="deleteFiles('ERROR')">
                  <font-awesome-icon v-if="error_deleting" icon="spinner" spin />
                  <font-awesome-icon v-else icon="trash" />
                  <span>削除</span>
                </button>
              </div>
              <ul>
                <li v-for="list in error_list" class="file-list">
                  <input :id="list[2]" v-model="chosen_error" type="checkbox" name="chosen_file" :value="list[2]"
                    class="form_control-checkbox" />
                  <label :for="list[2]" class="form_control-label"></label>
                  <span class="form_control-filename" @click="chooseError(list[0], list[1], list[2], list[3])">{{
                      list[1]
                  }} ({{ list[0] }})</span>
                </li>
              </ul>
            </div>

            <!-- 文字起こし完了済みファイル -->
            <div class="after">
              <div class="heading-wrapper">
                <h2 class="heading-inner">
                  文字起こし完了済み（文字起こし開始時刻）
                </h2>
              </div>
              <div class="btn_column" v-if="(user_plan == 'premium') | (user_plan == 'enterprise')">
                <button type="button" class="btn-display btn-primary" @click="chooseAllFiles">
                  <span>全選択</span>
                </button>
                <button type="button" class="btn-display btn-primary" @click="chooseNoFile">
                  <span>全選択解除</span>
                </button>
                <button type="button" class="btn-display btn-primary" :class="{ 'btn-loading': !chosen_file.length }"
                  @click="deleteFiles('NORMAL')">
                  <font-awesome-icon v-if="files_deleting" icon="spinner" spin />
                  <font-awesome-icon v-else icon="trash" />
                  <span>削除</span>
                </button>
              </div>
              <ul>
                <li v-for="node in after_tree" class="date-group">
                  <span class="date-list" @click="toggle(node)">{{ node.isOpen ? "▼ " : "▶ " }}{{ node.date }}</span>
                  <ul v-show="node.isOpen">
                    <li v-for="file in node.children" class="file-list">
                      <template v-if="(user_plan == 'premium') | (user_plan == 'enterprise')">
                        <input :id="file.realname" v-model="chosen_file" type="checkbox" name="chosen_file" :value="file.realname"
                          class="form_control-checkbox" />
                        <label :for="file.realname" class="form_control-label"></label>
                      </template>
                      <div class="row">
                        <span :class="{'form_control-filename' : user_plan !== 'standard' }" @click="
                          chooseFile(
                            file.date,
                            file.time,
                            file.name,
                            file.realname,
                            file.summarized,
                            file.translated,
                          )
                        "><span v-if="(user_plan == 'standard')">・</span>{{ file.name }} ({{ file.time }})</span>
                        <span @click="
                          chooseFile(
                            file.date,
                            file.time,
                            file.name,
                            file.realname,
                            file.summarized,
                            file.translated,
                          )
                        "> {{file.summarized ? '議事録作成済み':''}}</span>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- 選択ボックス -->
          </div>
          <div class="float_box right">
            <div class="box property" :class="{
              gray:
                situation < 2.9 ||
                file_deleting ||
                error_deleting ||
                files_deleting ||
                filename_edit == 2 ||
                retrying == 2 ||
                text_loading,
            }">
              <div class="heading-wrapper">
                <h2 class="heading-inner">選択中のファイル</h2>
              </div>
              <ul class="property-text">
                <li class="text-row">
                  <span style="width: 30%;"><b>ファイル名</b></span>
                  <span><b>：</b></span>
                  <span>{{ filename }}</span>
                </li>
                <li class="text-row">
                  <span style="width: 30%;"><b>文字起こし開始日時</b></span>
                  <span><b>：</b></span>
                  <span>{{ file_dt }}</span>
                </li>
              </ul>
              <div class="btn_column">
                <button v-show="!translated" type="button" class="btn-display btn-primary" :class="{ 'btn-loading': retrying || filename_edit }"
                  @click="translateModal">
                  <font-awesome-icon icon="language" />
                  <span>翻訳</span>
                </button>
                <button v-show="translated" type="button" class="btn-display btn-primary" :class="{ 'btn-loading': retrying || filename_edit }"
                  @click="deleteTranslatedFile(realname)">
                  <font-awesome-icon icon="trash" />
                  <span>翻訳削除</span>
                </button>
                <button v-show="!summarized" type="button" class="btn-display btn-primary" :class="{ 'btn-loading': retrying || filename_edit }"
                  @click="summarizeModal">
                  <font-awesome-icon icon="file-signature" />
                  <span>AI議事録作成</span>
                </button>
                <button v-show="summarized" type="button" class="btn-display btn-primary" :class="{ 'btn-loading': retrying || filename_edit }"
                  @click="deleteSummarisedFile(realname)">
                  <font-awesome-icon icon="trash" />
                  <span>議事録削除</span>
                </button>
                <button type="button" class="btn-display btn-primary" :class="{ 'btn-loading': retrying || filename_edit }"
                  @click="changeFile">
                  <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                  <span>ファイル変更</span>
                </button>
                <button type="button" class="btn-display btn-primary" :class="{ 'btn-loading': retrying || filename_edit  }"
                  @click="editFilename1" v-if="user_plan == 'premium' | user_plan == 'enterprise'">
                  <font-awesome-icon icon="edit" />
                  <span>名称変更</span>
                </button>
              </div>
              <div v-show="filename_edit">
                <div class="filename-input">
                  <label for="filename">変更後のファイル名：</label>
                  <input name="filename" id="filename" v-model="newname" type="text" />
                  <p v-if="isInValidFileName" class="filename-input-error">ファイル名に「/」は使用できません</p>
                </div>
                <div class="btn_column">
                  <button type="button" class="btn-display btn-primary" @click="notEditFilename">
                    <!-- <font-awesome-icon icon="edit" /> -->
                    <span>キャンセル</span>
                  </button>
                  <button type="button" class="btn-display btn-primary" :class="{ 'btn-loading': isInValidFileName }" @click="editFilename2">
                    <font-awesome-icon v-if="filename_edit == 2" icon="spinner" spin />
                    <font-awesome-icon v-else icon="edit" />
                    <span>変更確定</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="retrying == 1 || retrying == 2" class="btn_column" @click="retry">
              <button type="button" class="btn-primary btn-lg" :class="{ 'btn-loading': retrying == 2 }">
                <font-awesome-icon v-if="retrying == 2" icon="spinner" spin />
                <span>再度文字起こし実行</span>
              </button>
            </div>
            <div v-else-if="situation > 2.9" class="box result">
              <div class="heading-wrapper">
                <h2 class="heading-inner">結果</h2>
              </div>
                <input id="minutes-text-result" class="result-check" type="checkbox" v-model="text_loaded">
                <div class="transcription-text-result-row">
                  <button type="button" class="result-label transcription-text-result" @click="editFile" for="minutes-text-result">
                    文字起こし
                  </button>
                </div>
                <div v-show="text_loaded" class="result-content">
                  <div v-if="text_loading" class="text_loading">
                    <h4>
                      <font-awesome-icon icon="spinner" spin />
                      ただいまテキストをダウンロード中
                    </h4>
                    <span>ページから離れず、このままでお待ち下さい</span>
                  </div>
                  <div class="result_display-contents">
                      <pre class="script">{{ script }}</pre>
                  </div>
                </div>
                <hr v-show="summarized">
                <input id="minutes-summarized-result" class="result-check" type="checkbox" v-model="summarize_loaded">
                <div class="minutes-summarized-result-row">
                  <label v-show="summarized" class="result-label" for="minutes-summarized-result">
                    <span>議事録</span>
                  </label>
                  <button v-show="summarized" @click="showSummarizedResult">
                    <font-awesome-icon :icon="['fas', 'external-link-alt']" size="lg" />
                  </button>
                  <div v-if="summarize_show" class="result-popup">
                    <div v-show="!summarize_edit_show" class="result-popup-content">
                      <div class="result-popup-header">
                        <button class="header-button" @click="summarizeCopyToClipboard('plane')">
                          <font-awesome-icon :icon="['far', 'copy']" />
                          <span class="button-tooltip">クリップボードへコピー</span>
                        </button>
                        <button class="header-button" @click="summarizeCopyToClipboard('markdown')">
                          <font-awesome-layers>
                            <font-awesome-icon :icon="['far', 'copy']" />
                            <font-awesome-layers-text style="color: black;font-size: 6px; position: relative; top: -8px; left: 2px; font-weight: bold;">M</font-awesome-layers-text>
                          </font-awesome-layers>
                          <span class="button-tooltip">クリップボードへコピー（markdown）</span>
                        </button>
                        <button class="header-button" @click="downloadSummarizedResult()">
                          <font-awesome-icon :icon="['fas', 'download']" />
                          <span class="button-tooltip">ダウンロード</span>
                        </button>
                        <button class="header-button" @click="summarizeEdit()">
                          <font-awesome-icon :icon="['fas', 'edit']" style="font-size: 15px; vertical-align: middle; margin: auto;" />
                          <span class="button-tooltip">編集</span>
                        </button>
                        <button 
                          @click="summarizeResultPopupClose()" 
                          class="result-popup-close"
                        >
                          <font-awesome-icon :icon="['fas', 'times-circle']" />
                        </button>
                      </div>
                      <div v-if="summarize_loading" class="text_loading">
                        <h4>
                          <font-awesome-icon icon="spinner" spin />
                          ただいまテキストをダウンロード中
                        </h4>
                        <span>ページから離れず、このままでお待ち下さい</span>
                      </div>
                      <div class="result_display-contents-summarize" v-html="summarizeToHtml"></div>
                    </div>
                    <div v-show="summarize_edit_show" class="result-popup-content">
                      <div class="result-popup-header">
                        <button class="header-button" @click="summarizeCancel()">
                          <font-awesome-icon :icon="['fas', 'undo-alt']" />
                          <span class="button-tooltip">キャンセル</span>
                        </button>
                        <button class="header-button" @click="summarizeSave()">
                          <font-awesome-icon :icon="['fas', 'save']" />
                          <span class="button-tooltip">保存</span>
                        </button>
                        <button class="header-button" @click="summarizePreview()">
                          <font-awesome-icon :icon="['fas', 'eye']" />
                          <span class="button-tooltip">プレビュー</span>
                        </button>
                        <button class="header-button" @click="textFormmattingRulesShow()">
                          <font-awesome-icon :icon="['fas', 'info-circle']" />
                          <span class="button-tooltip">テキスト整形ルール</span>
                        </button>
                        <button 
                          @click="summarizeResultPopupClose()" 
                          class="result-popup-close"
                        >
                          <font-awesome-icon :icon="['fas', 'times-circle']" />
                        </button>
                      </div>
                      <div class="result_display-contents-summarize-edit">
                        <textarea v-model="summarize_edit" class="summarize-edit"></textarea>
                      </div>
                    </div>
                  </div>
                  <div v-if="summarize_preview_show" class="result-preview">
                    <div class="result-preview-content">
                      <div class="result-preview-header">
                        <button class="header-button" @click="summarizeCancel()">
                          <font-awesome-icon :icon="['fas', 'undo-alt']" />
                          <span class="button-tooltip">キャンセル</span>
                        </button>
                        <button class="header-button" @click="summarizeSave()">
                          <font-awesome-icon :icon="['fas', 'save']" />
                          <span class="button-tooltip">保存</span>
                        </button>
                        <button class="header-button" @click="summarizePreviewCancel()">
                          <font-awesome-icon :icon="['fas', 'eye-slash']" />
                          <span class="button-tooltip">プレビューキャンセル</span>
                        </button>
                        <button class="header-button" @click="textFormmattingRulesShow()">
                          <font-awesome-icon :icon="['fas', 'info-circle']" />
                          <span class="button-tooltip">テキスト整形ルール</span>
                        </button>
                        <button 
                          @click="summarizeResultPopupClose()" 
                          class="result-popup-close"
                        >
                          <font-awesome-icon :icon="['fas', 'times-circle']" />
                        </button>
                      </div>
                      <div class="result_display-contents-summarize" v-html="summarizeEditToHtml"></div>
                    </div>
                  </div>
                  <TextFormattingRules class="result_display-contents-summarize" v-show="text_formmatting_rules_show" @close="textFormmattingRulesClose()" />
                </div>
                <hr v-show="translated">
                <input id="minutes-translated-result" class="result-check" type="checkbox" v-model="translate_loaded">
                <div class="minutes-translated-result-row">
                  <label v-show="translated" class="result-label" for="minutes-translated-result">
                    <span>翻訳</span>
                  </label>
                  <button v-show="translated" @click="showTranslatedResult">
                    <font-awesome-icon :icon="['fas', 'external-link-alt']" size="lg" />
                  </button>
                  <div v-if="translate_show" class="result-popup">
                    <div class="result-popup-content">
                      <div class="result-popup-header">
                        <button class="header-button" @click="translateCopyToClipboard()">
                          <font-awesome-icon :icon="['far', 'copy']" />
                          <span class="button-tooltip">クリップボードへコピー</span>
                        </button>
                        <button class="header-button" @click="downloadTranslatedResult()">
                          <font-awesome-icon :icon="['fas', 'download']" />
                          <span class="button-tooltip">ダウンロード</span>
                        </button>
                        <button 
                          @click="translate_show = false" 
                          class="result-popup-close"
                        >
                          <font-awesome-icon :icon="['fas', 'times-circle']" />
                        </button>
                      </div>
                      <div v-if="translate_loading" class="text_loading">
                        <h4>
                          <font-awesome-icon icon="spinner" spin />
                          ただいまテキストをダウンロード中
                        </h4>
                        <span>ページから離れず、このままでお待ち下さい</span>
                      </div>
                      <div class="result_display-contents-translate">
                        <pre class="translate">{{ translate }}</pre>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <p class="footer_copyright">©Texta. Developed by TECHNODIGITAL.</p>
        </footer>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { marked } from 'marked';
import { remark } from 'remark';

import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import TextFormattingRules from "../modules/TextFormattingRules.vue";

import StripeFunc from "../mixin/StripeFunc";
import toggleFunctions from "../mixin/toggleFunctions";
import stripMarkdown from 'strip-markdown';
import DOMPurify from 'dompurify';

export default {
  name: "Result", // toggleSidebar呼び出しのため
  components: {
    MyHeader,
    MySidebar,
    TextFormattingRules,
  },
  mixins: [StripeFunc, toggleFunctions],
  data() {
    return {
      list_loading: false,
      text_loading: false,
      file_downloading: false,
      retrying: 0, // 1:chosen, 2:retry, 3:finish
      filename_edit: 0, // 1:editting, 2;decide
      file_deleting: false,
      script_show: false,
      situation: 0, // 1:list_loading, 2:before_select, 3:select, 4:decide, 5:finish, 2.9:error_select
      isOpen: false,
      before_list: "",
      error_list: "",
      after_tree: [],
      filename: "<ファイル未選択>",
      newname: "",
      realname: "",
      bucket_number: "",
      file_dt: "<ファイル未選択>",
      script: "",

      // ▼ エラーファイル ▼ //
      error_deleting: false,
      all_error: [],
      chosen_error: [], // 選択したエラーファイル
      // ▲ エラーファイル ▲ //
      // ---------------- //
      // ▼ 完了済ファイル ▼ //
      files_deleting: false,
      chosen_file: [], // 選択した正常終了ファイル
      all_file: [],
      // ▲ 完了済ファイル ▲ //

      // ユーザー情報
      user_id: firebase.auth().currentUser.uid,
      userIdToken: "",
      user_plan: "",
      summarize_loading: false,
      can_summarize: true,
      result_summarize: 0,
      summarized: false,
      summarize_show: false,
      summarize: "",
      summarize_edit: "",
      summarize_options: {
        "language": "日本語",
        "meeting_name": "",
        "meeting_date": "",
        "meeting_start_time": "",
        "meeting_end_time": "",
        "meeting_location": "",
        "meeting_participants": "",
        "meeting_purpose": "",
      },
      input_max_length: {
        "meeting_name": 50,
        "meeting_location": 50,
        "meeting_participants": 100,
        "meeting_purpose": 100,
      },
      loading: false,
      text_loaded:false,
      summarize_loaded:false,
      translate_loaded:false,
      translate_loading: false,
      translated: false,
      translate_show: false,
      translate: "",
      translate_language: "ja",
      summarize_edit_show: false,
      summarize_preview_show: false,
      text_formmatting_rules_show: false,
    };
  },
  async created() {
    // 無料アカウントの場合はリアルタイムに移動
    this.loading = true;
    let userSubscription = await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(`${this.user_id}`)
        .get();
    if (userSubscription.data().subscription.item_plan == "free" &&
      !((userSubscription.data().subscription.status.behavior == "canceled") &&
      (userSubscription.data().subscription.status.invoice.seconds > Date.now() / 1000))) {
      alert("フリープランではこちらの機能をご利用いただけません\nご利用いただくにはプランをアップグレートしてください")
      this.$router.push("/");
    }

    // マークダウン記法
    marked.use({ gfm: true, breaks: true });

    this.userIdToken = await firebase.auth().currentUser.getIdToken();
    this.user_plan = await this.getUserPlan();
    this.getList();
  },
  updated() {
    const aTags = document.querySelectorAll('.result_display-contents-summarize a');
    aTags.forEach(link => {
      link.target = '_blank';
      link.rel = 'nofollow noreferrer noopener';
    });
  },
  computed: {
    meetingNameCharCountStyle() {
      return {
        color: this.summarize_options.meeting_name.length > this.input_max_length.meeting_name ? 'red' : 'black',
        float: 'right'
      };
    },
    meetingLocationCharCountStyle() {
      return {
        color: this.summarize_options.meeting_location.length > this.input_max_length.meeting_location ? 'red' : 'black',
        float: 'right'
      };
    },
    meetingParticipantsCharCountStyle() {
      return {
        color: this.summarize_options.meeting_participants.length > this.input_max_length.meeting_participants ? 'red' : 'black',
        float: 'right'
      };
    },
    meetingPurposeCharCountStyle() {
      return {
        color: this.summarize_options.meeting_purpose.length > this.input_max_length.meeting_purpose ? 'red' : 'black',
        float: 'right'
      };
    },
    ...mapState([
      "fileName",
      "recordedTime",
      "realname",
      "isRealtime",
    ]),
    summarizeToHtml() {
      if (!this.summarize) {
        return '';
      }
      const html = marked.parse(this.summarize);
      return  DOMPurify.sanitize(html);
    },
    summarizeToPlainText() {
      if (!this.summarize) {
        return '';
      }
      // markdown形式を削除
      let plainText = remark().use(stripMarkdown).processSync(this.summarize).toString();
      // エスケープ文字列（\）を削除
      plainText = plainText.replace(/([^\\])\\([^\\])/g, '$1$2').replace(/\\\\/g, '\\');
      return plainText;
    },
    summarizeEditToHtml() {
      if (!this.summarize_edit) {
        return '';
      }
      const html = marked.parse(this.summarize_edit);
      return  DOMPurify.sanitize(html);
    },
    isInValidFileName(){
      return this.newname.includes("/");
    },
  },
  methods: {
    ...mapActions([
      "setFileName",
      "setRealname",
      "setIsRealtime"
    ]),
    // リスト一覧の取得
    getList() {
      this.loading = true;
      const self = this;
      self.onReset();
      self.situation = 1;
      self.list_loading = true;
      const headers = { Authorization: "Bearer " + self.userIdToken };
      axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_list",
          { headers: headers }
        )
        .then((response) => {
          self.before_list = response.data.before_datas;
          self.error_list = response.data.error_datas;
          self.all_error = response.data.error_datas;
          self.after_tree = [];
          const after_list = response.data.after_datas;
          // 処理済みリスト作成
          for (let i = 0; i < after_list.length; i++) {
            let element = after_list[i];
            if (element[1] == "") {
              let array = { date: element[0], isOpen: false, children: [] };
              self.after_tree.push(array);
            } else {
              let array = {
                date: element[0],
                time: element[1],
                name: element[2],
                realname: element[3],
                summarized: element[4] ? element[4] : false,
                translated: element[5] ? element[5] : false,
              };
              let target = self.after_tree.find((f) => {
                return f.date == element[0];
              });
              target.children.push(array);
              self.all_file.push(element[3]);
            }
          }
          if (self.user_plan == "standard") {
            alert(
              "スタンダードプランでは、3日以内の文字起こしのみが表示されます。\nダウンロードして文字起こし結果をご利用ください。"
            );
            // 最近3日以内のファイルのみ表示
            let new_after_tree = [];
            for (let i = 0; i < self.after_tree.length; i++) {
              // 時刻取得
              let element = self.after_tree[i];
              let file_date = Date.parse(element.date);
              let current_date = Date.now();
              if (current_date - file_date < 3 * 24 * 3600 * 1000) {
                new_after_tree.push(element);
              }
            }
            self.after_tree = new_after_tree;
          }
          self.situation = 2;
          self.list_loading = false;
        })
        .catch((error) => {
          alert(
            "不明なエラー(Error00)\n結果一覧の取得に失敗しました。不明なエラーです。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
          self.list_loading = false;
        })
        .finally(() => {
          self.loading = false;
        });
    },

    // 正常ファイル選択の処理
    chooseFile(date, time, name, realname, summarized, translated) {
      this.situation = 3;
      this.filename = name;
      this.realname = realname;
      this.summarized = summarized;
      this.translated = translated;
      this.file_dt = date + " " + time;
    },
    chooseAllFiles() {
      for (let node of this.after_tree) {
        node.isOpen = true
        node.children.forEach(file => {
          this.chosen_file.push(file.realname)
        });
      }
    },
    chooseNoFile() {
      this.chosen_file = [];
    },

    // エラーファイル選択の処理
    chooseError(time, name, realname, bucket_number) {
      this.retrying = 1;
      this.situation = 2.9;
      this.filename = name;
      this.realname = realname;
      this.bucket_number = bucket_number;
      this.file_dt = time;
    },
    chooseAllError() {
      this.chosen_error = this.all_error.map((error) => {
        return error[2];
      });
    },
    chooseNoError () {
      this.chosen_error = [];
    },

    // ファイルの削除
    deleteFiles(which_type) {
      const self = this;
      let res = confirm("本当に削除しますか？");
      if (!res) {
        return true;
      }

      self.error_deleting = true;
      const realname = [];
      if (which_type == "ERROR") {
        self.error_deleting = true;
        for (let error of self.chosen_error) {
          realname.push(error);
        }
      } else if (which_type == "NORMAL") {
        self.files_deleting = true;
        for (let file of self.chosen_file) {
          realname.push(file);
        }
      }
      const currentRealtimeFileName = this.fileName + '-' + this.recordedTime + '.txt';
      const headers = { Authorization: "Bearer " + self.userIdToken };
      const params = {
        user_id: self.user_id,
        realname: realname,
        which_type: which_type,
      };

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/delete_files",
          params,
          { headers: headers }
        )
        .then((response) => {
          alert("選択したファイルを削除しました。一覧を更新します。");
          if (realname.includes(currentRealtimeFileName)) {
            window.location.reload();
          } else {
            self.getList();
          }
        })
        .catch((error) => {
          self.error_deleting = false;
          self.files_deleting = false;
          alert(
            "不明なエラー(Error00)\n選択したファイルの削除に失敗しました。不明なエラーです。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
        });
    },

    // 文字起こし失敗したファイルのリトライ
    retry() {
      let self = this;
      self.retrying = 2;

      const params = {
        user_id: self.user_id,
        realname: self.realname,
      };
      const startTime = Date.now();
      const headers = { Authorization: "Bearer " + self.userIdToken };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/file-transcription/retry_transcript",
          params,
          { headers: headers }
        )
        .then((response) => {
          alert("選択したファイルの文字起こしを開始します。");
          self.getList();
        })
        .catch((error) => {
          const endTime = Date.now();
          if (endTime - startTime > 5300000) {
            alert(
              "実行制限時間超過(Error10)\n音声ファイルが長い、もしくは現在サービスが大変混雑しております。音声ファイルが長い場合は分割して再度実行してください。"
            );
          } else if (endTime - startTime < 300000) {
            alert(
              "文字起こし開始検知失敗(Error01)\n文字起こし開始の検知に失敗しました。時間をあけ再度実行し同じエラーが表示される場合、音声ファイルの再生時間が長すぎることが原因と考えられます。当該ファイルは削除した上で、ファイルを分割し再実行してください。"
            );
          } else {
            alert(
              "不明なエラー(Error00)\n文字起こしに失敗しました。不明なエラーです。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
            );
          }
          self.retrying = 1;
        });
    },

    changeFile() {
      let self = this;
      if (self.retrying == 3) {
        self.getList();
      } else {
        self.text_loading = false;
        self.summarize_loading = false;
        self.translate_loading = false;
        self.text_loaded = false;
        self.summarize_loaded = false;
        self.translate_loaded = false;
        self.file_downloading = false;
        self.script_show = false;
        self.summarize_show = false;
        self.translate_show = false;
        self.situation = 2;
        self.retrying = 0;
        self.filename = "<ファイル未選択>";
        self.newname = "";
        self.realname = "";
        (self.bucket_number = ""), (self.file_dt = "<ファイル未選択>");
        self.script = "";
        self.summarize = "";
        self.translate = "";
        self.filename_edit = 0;
      }
    },

    // 名称変更ボタンを押したときの関数
    editFilename1() {
      this.filename_edit = 1;
      this.situation = 4;
      this.newname = this.filename;
    },

    // ファイル名変更
    editFilename2() {
      const self = this;
      const new_name = self.realname.replace(self.filename, self.newname);
      self.filename_edit = 2;

      const params = {
        user_id: self.user_id,
        old_name: self.realname,
        new_name: new_name,
      };
      const headers = { Authorization: "Bearer " + self.userIdToken };

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/edit_filename",
          params,
          { headers: headers }
        )
        .then((response) => {
          alert("選択したファイル名の変更に成功しました！一覧を更新します。");
        })
        .catch((error) => {
          self.notEditFilename();
          alert("選択したファイル名の変更に失敗しました。");
        })
        .finally(() => {
          if(self.filename == this.fileName){
            this.setFileName(self.newname);
          }
          self.getList();
        });
    },

    notEditFilename() {
      this.filename_edit = 0;
      this.situation = 4;
    },

    onReset() {
      this.list_loading = false;
      this.text_loading = false;
      this.file_downloading = false;
      this.retrying = 0;
      this.filename_edit = 0;
      this.file_deleting = false;
      this.script_show = false;
      this.summarize_show = false;
      this.translate_show = false;
      this.situation = 0;
      this.isOpen = false;
      this.before_list = "";
      this.error_list = "";
      this.after_tree = [];
      this.filename = "<ファイル未選択>";
      this.newname = "";
      this.realname = "";
      this.file_dt = "<ファイル未選択>";
      this.script = "";
      this.error_deleting = false;
      this.chosen_error = [];
      this.all_error = [];
      this.files_deleting = false;
      this.chosen_file = [];
      this.all_file = [];
      this.text_loaded = false;
      this.summarize_loaded = false;
      this.translate_loaded = false;
      this.result_summarize = 0;
      this.summarize_loading = false;
      this.translate_loading = false;
      this.summarized = false;
      this.translated = false;
      this.summarize_show = false;
      this.translate_show = false;
      this.summarize = "";
      this.summarize_edit = "";
      this.translate = "";
      this.translate_language = "ja";
      this.summarize_options = {
        "language": "日本語",
        "meeting_name": "",
        "meeting_date": "",
        "meeting_start_time": "",
        "meeting_end_time": "",
        "meeting_location": "",
        "meeting_participants": "",
        "meeting_purpose": "",
      };

    },

    // AI議事録作成
    summarizeModal() {
      this.result_summarize = 1;
      this.situation = 4;
      this.$modal.show('result-summarize');
    },

    // AI議事録作成キャンセル
    resultSummarizeClose() {
      this.situation = 4;
      this.$modal.hide('result-summarize');
    },

    // AI議事録作成詳細設定
    advancedSummarizeOptions() {
      if (this.result_summarize == 1) {
        this.result_summarize = 2;
      } else {
        this.result_summarize = 1;
      }
    },

    // 設定バリデーション
    checkSummarizeOptions() {
      if (this.summarize_options.meeting_name.length > this.input_max_length.meeting_name ||
        this.summarize_options.meeting_location.length > this.input_max_length.meeting_location ||
        this.summarize_options.meeting_participants.length > this.input_max_length.meeting_participants ||
        this.summarize_options.meeting_purpose.length > this.input_max_length.meeting_purpose
      ) {
        this.can_summarize = false;
      } else {
        this.can_summarize = true;
      }
    },

    // AI議事録作成処理行う
    summarizeResult() {
      this.loading = true;
      const self = this;
      const params = {
        user_id: self.user_id,
        text_name: self.realname,
        options: {
          ...self.summarize_options,
        }
      };
      const headers = { Authorization: "Bearer " + self.userIdToken };

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/result_summarize",
          params,
          { headers: headers }
        )
        .then(function (response) {
          alert("選択したファイルのAI議事録作成が成功しました！一覧を更新します。");
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error) {
            alert("選択したファイルのAI議事録作成が失敗しました。\n" + error.response.data.error);
          } else {
            alert("選択したファイルのAI議事録作成が失敗しました。\n文字数や会議内容の設定を見直し、再度実行してください。\n");
          }
        })
        .finally(() => {
          self.resultSummarizeClose()
          self.getList();
        });
    },

    // gcsから議事録を取得
    async getSummarize() {
      this.loading = true;
      const self = this;
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
      return new Promise(function (sumFunc /* ngFunc */) {
        const params = {
          file_name: self.realname,
          user_id: self.user_id,
        };
        const headers = { Authorization: "Bearer " + self.userIdToken };
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/get_summarize",
            params,
            { headers: headers }
          )
          .then((response) => {
            sumFunc({
              status: true,
              summarize: response.data.summarize
            });
          })
          .catch((res) => {
            sumFunc({ status: false });
          })
          .finally(() => {
            self.loading = false;
          });
      });
    },

    // 議事録結果表示
    async showSummarizedResult() {
      const self = this;
      self.summarize_loading = true;
      self.situation = 4;
      self.summarize_show = true;

      // 既にダウンロードしている場合
      if (self.summarize !== "") {
        self.situation = 5;
        self.summarize_loading = false;

        return true;
      }

      const api_result = await self.getSummarize();
      if (api_result) {
        const summarize = api_result.summarize;
        self.summarize = summarize;
        self.summarize_loading = false;
        self.situation = 5;
      } else {
        self.situation = 3;
        self.summarize_loading = false;
        alert(
          "ダウンロードエラー(Error02)\nテキストのダウンロードに失敗しました。結果一覧から文字起こしが正常に終了しているか確認してください。"
        );
      }
    },

    summarizeResultPopupClose() {
      if ((this.summarize_edit_show || this.summarize_preview_show) && this.summarize != this.summarize_edit) {
        if (!confirm('編集中の内容は保存されません。よろしいですか？')) {
          return;
        }
      }
      
      this.summarize_show = false;
      this.summarize_edit = "";
      this.summarize_edit_show = false;
      this.summarize_preview_show = false;
    },

    // 議事録のダウンロード
    async downloadSummarizedResult() {
      this.loading = true;
      const self = this;
      self.file_downloading = true;
      self.situation = 4;

      if (self.summarize !== "") {
        // ダウンロードファイル作成
        var blob = new Blob([self.summarizeToPlainText], { type: "text/plain" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "[議事録]" + self.filename;
        link.click();
        self.situation = 5;
        self.file_downloading = false;
        self.loading = false;
        return true;
      } else {
        const api_result = await self.getSummarize();
        if (api_result) {
          // ダウンロードファイル作成
          const summarize = api_result.summarize;
          self.summarize = summarize;
          var blob = new Blob([self.summarizeToPlainText], { type: "text/plain" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "[議事録]" + self.filename;
          link.click();
          self.situation = 5;
          self.file_downloading = false;
          self.loading = false;
        } else {
          alert(
            "ダウンロードエラー(Error02)\n議事録のダウンロードに失敗しました。結果一覧から議事録が正常に終了しているか確認してください。"
          );
          self.situation = 3;
          self.file_downloading = false;
          self.loading = false;
        }
      }
    },

    // 議事録をクリップボードへコピー
    async summarizeCopyToClipboard(format) {
      try {
        if (format === 'plane') {
          await navigator.clipboard.writeText(this.summarizeToPlainText);
        } else {
          await navigator.clipboard.writeText(this.summarize);
        }
      } catch (err) {
        alert('クリップボードにコピーできませんでした');
      }
    },

    // 議事録編集
    summarizeEdit() {
      this.summarize_edit = this.summarize;
      this.summarize_edit_show = true;
    },

    // 議事録保存
    async summarizeSave() {
      this.loading = true;
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
      this.summarize = this.summarize_edit;

      var formData = new FormData();
      formData.append("user_id", this.user_id);
      formData.append("file_name", this.realname);
      formData.append("summarize_edit", this.summarize_edit);
      const headers = { Authorization: "Bearer " + this.userIdToken };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/save_summarize",
          formData,
          { headers: headers }
        )
        .then((response) => { })
        .catch((error) => { })
        .finally(() => {
          this.loading = false;
          this.summarize_edit_show = false;
          this.summarize_edit = "";
          this.summarize_preview_show = false;
          }
        );
    },

    // 議事録プレビュー
    summarizePreview() {
      this.summarize_preview_show = true;
    },

    // 議事録プレビューキャンセル
    summarizePreviewCancel() {
      this.summarize_preview_show = false;
    },

    // テキスト整形ルール表示
    textFormmattingRulesShow() {
      this.text_formmatting_rules_show = true;
    },

    // テキスト整形ルール閉じる
    textFormmattingRulesClose() {
      this.text_formmatting_rules_show = false;
    },

    // 議事録編集キャンセル
    summarizeCancel() {
      if (this.summarize != this.summarize_edit) {
        if (!confirm('編集中の内容は保存されません。よろしいですか？')) {
          return;
        }
      }

      this.summarize_edit = "";
      this.summarize_edit_show = false;
      this.summarize_preview_show = false;
    },

    // 議事録削除
    deleteSummarisedFile(realname) {
      this.loading = true;
      const self = this;
      let res = confirm("本当に削除しますか？");
      if (!res) {
        this.loading = false;
        return true;
      }
      const headers = { Authorization: "Bearer " + self.userIdToken };
      const params = {
        user_id: self.user_id,
        realname: realname
      };

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/delete_summarized_file",
          params,
          { headers: headers }
        )
        .then(function (response) {
          alert("選択した議事録を削除しました。一覧を更新します。");
          self.getList();
        })
        .catch((error) => {
          alert(
            "不明なエラー(Error00)\n選択したファイルの削除に失敗しました。不明なエラーです。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
        })
        .finally(() => {
          self.loading = false;
        });
    },

    // 議事録翻訳
    translateModal() {
      this.situation = 4;
      this.$modal.show('result-translate');
    },

    // 議事録翻訳キャンセル
    resultTranslateClose() {
      this.situation = 4;
      this.$modal.hide('result-translate');
    },

    // 翻訳処理行う
    translateResult() {
      this.loading = true;
      const self = this;
      const params = {
        user_id: self.user_id,
        text_name: self.realname,
        target: self.translate_language
      };
      const headers = { Authorization: "Bearer " + self.userIdToken };

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/result_translate",
          params,
          { headers: headers }
        )
        .then(function (response) {
          alert("選択したファイルの翻訳が成功しました！一覧を更新します。");
        })
        .catch((error) => {
          alert("選択したファイルの翻訳が失敗しました。\n再度実行してください。\n");
        })
        .finally(() => {
          self.resultTranslateClose()
          self.getList();
        });
    },

    // gcsから翻訳を取得
    async getTranslate() {
      this.loading = true;
      const self = this;
      return new Promise(function (sumFunc /* ngFunc */) {
        const params = {
          file_name: self.realname,
          user_id: self.user_id,
        };
        const headers = { Authorization: "Bearer " + self.userIdToken };
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/get_translate",
            params,
            { headers: headers }
          )
          .then((response) => {
            sumFunc({
              status: true,
              translate: response.data.translate
            });
          })
          .catch((res) => {
            sumFunc({ status: false });
          })
          .finally(() => {
            self.loading = false;
          });
      });
    },

    // 翻訳結果表示
    async showTranslatedResult() {
      const self = this;
      self.translate_loading = true;
      self.situation = 4;
      self.translate_show = true;

      // 既にダウンロードしている場合
      if (self.translate !== "") {
        self.situation = 5;
        self.translate_loading = false;

        return true;
      }

      const api_result = await self.getTranslate();
      if (api_result) {
        const translate = api_result.translate;
        self.translate = translate;
        self.translate_loading = false;
        self.situation = 5;
      } else {
        self.situation = 3;
        self.translate_loading = false;
        alert(
          "ダウンロードエラー(Error02)\nテキストのダウンロードに失敗しました。結果一覧から文字起こしが正常に終了しているか確認してください。"
        );
      }
    },

    // 翻訳のダウンロード
    async downloadTranslatedResult() {
      this.loading = true;
      const self = this;
      self.file_downloading = true;
      self.situation = 4;

      if (self.translate !== "") {
        // ダウンロードファイル作成
        var blob = new Blob([self.translate], { type: "text/plain" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "[翻訳]" + self.filename;
        link.click();
        self.situation = 5;
        self.file_downloading = false;
        self.loading = false;
        return true;
      } else {
        const api_result = await self.getTranslate();
        if (api_result) {
          // ダウンロードファイル作成
          const translate = api_result.translate;
          self.translate = translate;
          var blob = new Blob([translate], { type: "text/plain" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "[翻訳]" + self.filename;
          link.click();
          self.situation = 5;
          self.file_downloading = false;
          self.loading = false;
        } else {
          alert(
            "ダウンロードエラー(Error02)\n翻訳のダウンロードに失敗しました。結果一覧から翻訳が正常に終了しているか確認してください。"
          );
          self.situation = 3;
          self.file_downloading = false;
          self.loading = false;
        }
      }
    },

    // 翻訳削除
    deleteTranslatedFile(realname) {
      this.loading = true;
      const self = this;
      let res = confirm("本当に削除しますか？");
      if (!res) {
        this.loading = false;
        return true;
      }
      const headers = { Authorization: "Bearer " + self.userIdToken };
      const params = {
        user_id: self.user_id,
        realname: realname
      };

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/delete_translated_file",
          params,
          { headers: headers }
        )
        .then(function (response) {
          alert("選択した翻訳を削除しました。一覧を更新します。");
          self.getList();
        })
        .catch((error) => {
          alert(
            "不明なエラー(Error00)\n選択したファイルの削除に失敗しました。不明なエラーです。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
        })
        .finally(() => {
          self.loading = false;
        });
    },

    // 翻訳をクリップボードへコピー
    async translateCopyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.translate);
      } catch (err) {
        alert('クリップボードにコピーできませんでした');
      }
    },

    // 詳細、編集ボタンを押したとき
    async editFile() {
      const self = this;
      self.loading = true;
      this.setFileName(this.filename);
      this.setRealname(this.realname);
      this.setIsRealtime(false);
      this.$router.push("/edit_result");
      self.loading = false;
    },
  }
};
</script>

<style scoped>
.message {
  margin-bottom: 3.5rem;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.float_box {
  width: 48%;
}

.left {
  background-color: #fff;
  box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.05);
  text-align: left;
  padding: 3rem;
}

.left ul {
  padding-left: 2.2rem;
  word-break: break-word;
}

hr {
  margin: 1.6rem 0;
}

.before {
  margin-bottom: 6rem;
}

.date-group:not(:first-of-type) {
  margin-top: 0.8rem;
}

.date-list {
  cursor: pointer;
  font-weight: bold;
}

.file-list span {
  cursor: pointer;
}

.property-text {
  text-align: left;
  .text-row {
    display: flex;
  }
}

button .fa-trash,
button .fa-language,
button .fa-file-signature,
button .fa-edit {
  vertical-align: -22%;
  margin-right: 1rem;
  font-size: 2.2rem;
}

.filename-input {
  position: relative;
}

.filename-input label {
  display: inline-block;
  position: relative;
  margin-right: 1.2rem;
}

.filename-input input {
  border: 0.1rem solid gray;
  border-radius: 0.2rem;
  width: 60%;
  padding: 0 1rem;
  vertical-align: 1%;
}

.filename-input-error {
  color: red;
}

.transcription-text-result-row {
  display: flex;
  justify-content: start;
}

.transcription-text-result {
  font-weight: bold;
}

.transcription-text-result:hover {
  text-decoration: underline;
  font-weight: bold;
}

.minutes-summarized-result-row {
  display: flex;
  justify-content: start;
}

.minutes-translated-result-row {
  display: flex;
  justify-content: start;
}
/* .text_loading {
  text-align: left;
  margin-left: 4rem;
} */

/* ========================= */
/* =   以下クラウドワークス   = */
/* ========================= */

/* チェックボックス */
.form_control-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form_control-label {
  display: block;
  position: relative;
  cursor: pointer;
  line-height: 1.8rem;
}

.form_control-label::before,
.form_control-label::after {
  content: "";
  position: absolute;
  z-index: 1;
}

.form_control-label::before {
  top: 0.46rem;
  left: 0;
  width: 2rem;
  height: 2rem;
  border: 0.1rem solid #dcdcdc;
  background-color: #f6f6f6;
}

:disabled+.form_control-label {
  cursor: not-allowed;
  opacity: 0.5;
}

:disabled+.form_control-label:hover {
  opacity: 0.5;
}

.form_control-label:hover {
  opacity: 0.8;
}

.form_control-checkbox+.form_control-label::before,
.form_control-checkbox+.form_control-label::after {
  border-radius: 0;
}

.form_control-checkbox:checked+.form_control-label::before {
  border-color: #00a4c9;
  background-color: #fff;
}

.form_control-checkbox:checked+.form_control-label::after {
  top: 0.8rem;
  left: 0.4rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #00a4c9;
}

.form_control-filename {
  margin-left: 3rem;
}
.row {
  display: flex;
  justify-content: space-between;
}
.row button:last-of-type {
  margin-left: auto;
}

.form_control-select {
  display: block;
  position: relative;
  padding-left: calc(1.8rem + 1rem);
  border-style: solid;
  background-image: linear-gradient(90deg,
      #00a4c9 0%,
      #00a4c9 50%,
      #4067a3 100%);
  /* background-color: #00a4c9; */
  color: white;
  font-weight: bold;
  font-size: 1.5em;
}

.form_control-select option {
  color: black;
  background: white;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.result-summarize-modal-body {
  padding: 2rem 2rem 0 2rem;
  text-align: left !important;
  .result-summarize-option {
    margin-top: 1rem;
    width: 50%;
  }
  .summarize-max-output{
    border-style: solid;
    width: 90%;
    font-size: 1.5em;
  }
  .summarize-item {
    border-style: solid;
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .summarize-item-date {
    border-style: solid;
    width: 25%;
    margin: 0 0 1rem 0;
  }
  .summarize-item-time {
    border-style: solid;
    width: 15%;
    margin: 0 0 1rem 0;
  }
  .advanced-summarize {
    color: #00a4c9;
    cursor: pointer;
    margin-top: 1rem;
  }
}

.modal-footer {
  padding-bottom: 2rem;
}

.result-check{
	display: none;
}

.result-label{
  text-align: left;
	color: #333;
	margin: 5px 10px 1px 0;
  line-height: 4rem;
  font-weight: bold;
}

.result_display-contents-summarize {
  --base-size-16: 1rem;
  text-align: left;
  padding: 0 20px 20px 20px;
  height: calc(100% - 30px);
  overflow-y: auto;
}

.result_display-contents-summarize :deep(table) {
  border-collapse: collapse;
  max-width: 100%;
}

.result_display-contents-summarize :deep(thead th) {
  background-color: #f2f2f2;
  font-weight: bold;
}

.result_display-contents-summarize :deep(th), 
.result_display-contents-summarize :deep(td) {
  border: 1px solid #000000;
  padding: 8px;
}

.result_display-contents-summarize :deep(ul) {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.result_display-contents-summarize :deep(li) {
  margin-left: 20px;
}

.result_display-contents-summarize :deep(ul > li::marker) {
  content: "• ";
}

.result_display-contents-summarize :deep(ul ul > li::marker) {
  content: "◦ ";
}

.result_display-contents-summarize :deep(ul ul ul > li::marker) {
  content: "▪ ";
}

.result_display-contents-summarize :deep(ol) {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.result_display-contents-summarize :deep(ol li) {
  list-style-type: decimal;
}

.result_display-contents-summarize :deep(ol ol li) {
  list-style-type: lower-roman;
}

.result_display-contents-summarize :deep(ol ol ol li) {
  list-style-type: lower-alpha;
}

.result_display-contents-summarize :deep(h1, h2, h3, h4, h5, h6) {
  margin-bottom: var(--base-size-16);
}

.result_display-contents-summarize :deep(p) {
  margin-bottom: var(--base-size-16);
}

.result_display-contents-summarize :deep(blockquote) {
    padding: 0 1em;
    color: #636c76;
    border-left: .25em solid #d0d7de;
    margin: 0;
}

.result_display-contents-summarize :deep(pre) {
    padding: var(--base-size-16);
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    color: #1f2328;
    background-color: #f6f8fa;
    border-radius: 6px;
    word-wrap: normal;
    margin-top: 0;
    margin-bottom: var(--base-size-16);
}

.result_display-contents-summarize :deep(pre code) {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    font-size: 100%;
}

.result_display-contents-summarize :deep(pre>code) {
    padding: 0;
    margin: 0;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
}

.result_display-contents-summarize :deep(code) {
    padding: .2em .4em;
    margin: 0;
    font-size: 85%;
    white-space: break-spaces;
    background-color: #afb8c133;
    border-radius: 6px;
}

.result_display-contents-summarize-edit {
  padding: 0 0 0 0;
  height: calc(100% - 30px);
}

.result_display-contents-summarize-edit .summarize-edit {
  resize: none;
  height: 100%;
  width: 100%;
  padding: 0 0 0 20px;
}

.result_display-contents-summarize-edit .summarize-edit:focus {
  background-color: #fcfade;
}

.result_display-contents-translate {
  text-align: left;
  padding: 0 20px 20px 20px;
  height: calc(100% - 30px);
  overflow-y: auto;
}

.result_display-contents-translate pre {
    white-space: pre-wrap;
    font-family: -apple-system,BlinkMacSystemFont,游ゴシック体,YuGothic,Yu Gothic Medium,Helvetica Neue,Verdana,sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    text-align: left;
}

.result_display-contents {
  padding-left: 20px;
}

.loading-mask {
  z-index:10;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-translate-modal-body {
  padding: 2rem 2rem 0 2rem;
  text-align: left !important;
  .result-translate-option {
    margin-top: 1rem;
    width: 100%;
    .form_control-select {
      width: 100%;
      text-align-last: center; 
    }
  }
}

.result-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.result-popup-content {
  position: relative;
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  border-radius: 5px;
  width: 80%;
  height: 90%;
}

.result-popup-header {
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  border-bottom: 1px solid gray;
}

.result-popup-header .header-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  height: 25px;
  width: 25px;
  position: relative;
}

.result-popup-header .header-button:hover {
  background-color: #c1c0c0;
}

.result-popup-header .header-button .button-tooltip {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.result-popup-header .header-button:hover .button-tooltip {
  display: block;
}

.result-popup-close {
  margin-left: auto;
  position: absolute;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}

.result-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.result-preview-content {
  position: relative;
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  border-radius: 5px;
  width: 80%;
  height: 90%;
  overflow: hidden;
}

.result-preview-header {
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  border-bottom: 1px solid gray;
}

.result-preview-header .header-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  height: 25px;
  width: 25px;
  position: relative;
}

.result-preview-header .header-button:hover {
  background-color: #c1c0c0;
}

.result-preview-header .header-button .button-tooltip {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.result-preview-header .header-button:hover .button-tooltip {
  display: block;
}

@media (max-width: 1150px) {
  .result-label {
    display: block;
  }
}
</style>
