<template>
  <div id="all">
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">Q & A</h2>
          </div>

          <!-- 動作環境 -->
          <p class="question__title">動作環境</p>
          <hr />
          <p class="question">
            Q.
            使用できるWEB会議の種類（ブラウザ型、アプリ型、ブラウザ依存の有無）について知りたい
          </p>
          <p class="answer">
            A. Textaは、他のweb会議ツールと併用していただく仕様です。
            基本的にどのような会議ツールでも構いません。PCのマイクから音声が取得できればご利用頂けます。<br />
            現在はブラウザ（Google
            Chromeのみ）上でのみサービス提供しております。
            今後iPadなどでもご使用頂けるようにアップデートを予定しております。
          </p>
          <br />

          <!-- 議事録作成 -->
          <p class="question__title">議事録作成</p>
          <hr />
          <p class="question">Q. 音声が入力されません</p>
          <p class="answer">A. 以下をご確認ください。</p>
          <p class="answer__nest">
            ・Chrome
            でTextaを起動しているか（Textaご利用時は、Chromeを推奨しております）
          </p>
          <p class="answer__nest">
            ・Chromeの設定でマイクの利用許可されているか
          </p>
          <br />
          <p class="question">Q. 相手の声が議事録に反映されません</p>
          <p class="answer">
            A.
            文字起こしをする際の音声は、textaを起動しているパソコンのマイクから取得しております。スピーカーの音量、マイクの感度が原因で、相手方の声がマイクに届いていないと考えられます。
          </p>
          <p class="answer__nest">
            textaを起動しているパソコンのスピーカー音量を大きくすると、反映されやすくなります。
          </p>
          <br />
          <p class="question">Q. 利用時間に制限はありますか</p>
          <p class="answer">A. 利用時間に制限はありません。</p>
          <br />
          <p class="question">
            Q. イヤホン利用時、通話相手の発言をテキスト化する方法はあるか
          </p>
          <p class="answer">
            A.
            イヤホンを使用して会話相手の音声を文字起こしする手順は下記をご参照ください。
          </p>
          <a
            target="_blank"
            href="https://www.tcdigital.jp/group_companies/texta_use_02"
            class="answer__nest"
            >・イヤホンでの文字起こし(Mac版)</a
          ><br />
          <a
            target="_blank"
            href="https://www.tcdigital.jp/group_companies/texta_use_01"
            class="answer__nest"
            >・イヤホンでの文字起こし(Windows版)</a
          ><br />
          <br />
          <p class="question">
            q.
            音声をダウンロードした後、音声ファイルがデフォルトchromeで開くようになっているため、巻き戻し・進むができません。
          </p>
          <p class="answer">
            A. お使いのPCでデフォルトで開くアプリを設定することができます。
            <a
              href="https://support.microsoft.com/ja-jp/windows/windows-10-%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0%E3%82%92%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B-e5d82cad-17d1-c53b-3505-f10a32e1894d"
              target="_blank"
              >Windows10の場合(Microsoftのサポートページに移動します)
            </a>
            <a
              href="https://support.apple.com/ja-jp/guide/mac-help/mh35597/11.0/mac/11.0"
              target="_blank"
              >Macの場合(macOSユーザーガイドに移動します)
            </a>
          </p>
          <br />

          <!-- 音声読み込み -->
          <br />
          <p class="question__title">音声読み込み</p>
          <hr />
          <p class="question">Q. ファイルサイズに制限はありますか</p>
          <p class="answer">A. 120MB未満のファイルでご利用ください。</p>
          <br />
          <p class="question">
            Q.
            文字起こしをする場合、文字起こしと相性のよいファイル形式はありますか
          </p>
          <p class="answer">A. flac形式を推奨しております。</p>
          <br />

          <!-- 共通 -->
          <br />
          <p class="question__title">議事録作成・音声読み込み共通</p>
          <hr />
          <p class="question">Q. 対応している言語について教えてください</p>
          <p class="answer">A. 以下12か国語に対応しております</p>
          <p class="answer__nest">
            日本語,英語,中国語,香港,台湾,韓国語,ヒンディー語,スペイン語,ロシア語,ポルトガル語,フランス語,ドイツ語
          </p>
          <br />
          <p class="question">
            Q. テキストデータ、音声データは保存されていますか
          </p>
          <p class="answer">
            A. テキストデータ、音声データはサーバーに保存されます。
          </p>
          <br />

          <!-- セキュリティ -->
          <br />
          <p class="question__title">セキュリティ</p>
          <hr />
          <p class="question">Q. 情報セキュリティ方針について教えてください</p>
          <p class="answer">
            A. 利用規約とプライバシーポリシーをご参照ください。
          </p>
          <a
            :href=termsofuse_url
            target="_blank"
            class="answer__nest"
            >利用規約</a
          ><br />
          <a
            href="https://www.tcdigital.jp/assets/privacy.html"
            target="_blank"
            class="answer__nest"
            >プライバシーポリシー</a
          >
          <br />
          <br />
          <p class="question">Q. データ漏洩対策措置はされていますか</p>
          <p class="answer">A. 以下のような対策を行っております。</p>
          <p class="answer__nest">・通信に関して：https を使って暗号化</p>
          <p class="answer__nest">・データベースに関して：パスワードを暗号化</p>
          <p class="answer__nest">
            ・ユーザーを確認できる管理画面閲覧権限は社内に限定、また、ユーザ確認は社内でも限られた社員しか確認できないようにしている
          </p>
          <br />

          <!-- サービス全般 -->
          <br />
          <p class="question__title">サービス全般</p>
          <hr />
          <p class="question">
            Q. アカウント登録後、メール認証の手続きを失念してしまいました
          </p>
          <p class="answer">
            A.「設定>アカウント情報」から、認証メールの再送信が可能です。
          </p>
          <br />
          <p class="question">Q. 退会する方法を教えてください</p>
          <p class="answer">
            A. 「設定>アカウント情報>退会する」で退会が可能です。
          </p>
          <br />
          <p class="question">Q.保存したデータを削除したい</p>
          <p class="answer">
            A.
            <a href="https://www.tcdigital.jp/group_companies/texta_delete" target="_blank">
              こちらの専用ページ
            </a>
            よりデータ削除申請をお願いします。
          </p>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import toggleFunctions from "../mixin/toggleFunctions";

export default {
  name: "QandA",
  mixins: [toggleFunctions], //toggleSidebar呼び出しのため
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      termsofuse_url: `${process.env.VUE_APP_LP_URL}/user-policy`,
    }
  }
};
</script>

<style scoped>
.box h1 {
  word-wrap: normal;
  text-align: left;
}
.box h2 {
  word-wrap: normal;
  text-align: left;
}
.box {
  text-align: left;
}

.question {
  color: #3cb684;
}
.question__title {
  font-weight: bold;
  color: #435266;
}
.answer {
  color: #435266;
}
.answer__nest {
  margin-left: 2vh;
}
</style>
