<template>
  <div>
    <MyHeader @sidebar="toggleSidebar" />
    <loading-modal
      ref="l_modal"
      modal_name="start_sub"
      :is_loading="is_loading"
    >
      <template #modal-header-loading>
        <p>少々お待ちください..</p>
      </template>
      <template #modal-header>
        <p>Complite</p>
      </template>
      <template #modal-body>
        <p>{{ modal_body }}</p>
      </template>
      <template #footer-right>
        <button @click="reload" class="submit-btn btn-display btn-primary">
          再読み込み
        </button>
      </template>
    </loading-modal>
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="wrapper">
          <div class="heading-inner">プランの開始</div>
          <div class="card plan_1">
            <div class="card-content">
              <router-link
                :to="{
                  name: 'StripeChangePlan'
                }"
                >◀︎ プランの申込へ戻る</router-link
              >
              <br />
              <h3>月の途中でのプランのダウングレードはできません</h3>
              <p>決済元クレジットカード</p>
              **** **** **** {{ card.last4 }} (exp. {{ card.exp_month }}/{{
                card.exp_year
              }})
              <p>{{ plan_name }}を開始します</p>
            </div>
            <div class="card-link">
              <button  class="btn-display btn-primary" @click="createSubscription">サブスクリプションの開始</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// officail
import Stripe from "stripe";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

// modules
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";
import LoadingModal from "@/components/modules/LoadingModal.vue";

// mixin
import StripeFunc from "@/components/mixin/StripeFunc.js";
import UsageTime from "@/components/mixin/usageTime.js";
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import { ref } from "vue";

export default {
  mixins: [StripeFunc, UsageTime, BaseFunctions],
  name: "StripeSubscription",
  components: {
    MyHeader,
    MySidebar,
    LoadingModal,
  },
  data: () => ({
    // base
    currentUser: firebase.auth().currentUser,

    // 利用サービスの種類
    plan_name: "",
    plan_id: "",

    // stripe
    stripe: "",
    item_info: {},
    stripe_fs_info: "",

    // カード情報の登録
    card: {},
    sources: {},
    stripeCustomerInitialized: false,

    // モーダル
    modal_body: "",
    modal_header: "",
    is_loading: false,
  }),

  async created() {
    const self = this;
    let stripe_fs_info = await self.getFSinfo(self.currentUser.uid);
    self.stripe_fs_info = stripe_fs_info;
    self.card = stripe_fs_info.token.card;

    if (self.currentUser == void 0) {
      self.$router.push("/account");
    }
    self.plan_name = self.$route.query.next_plan;
    if (self.plan_name == "standard") {
      self.plan_id = process.env.VUE_APP_STRIPE_STANDARD_PLAN_ID;
    } else if (self.plan_name == "premium") {
      self.plan_id = process.env.VUE_APP_STRIPE_PREMIUM_PLAN_ID;
    }

    // デバック
    self.item_info = {
      quantity: "1",
      plan_id: self.plan_id,
      plan_name: self.plan_name,
    };

    // stripe インスタンス作成
    const stripe_ins = await self.getStripe();
    // stripe と elements のインスタンス作成
    self.stripe = stripe_ins;
    let elements = stripe_ins.elements();
  },

  methods: {
    // firestore にサブスクリプション情報追加
    async addFSSubscription(stripe_res) {
      const self = this;

      // 次の請求
      let next_invoice = new Date(stripe_res.data.current_period_end * 1000);

      let set_data = {
        id: stripe_res.data.id,
        plan_id: self.item_info.plan_id,
        item_plan: self.item_info.plan_name,
        status: {
          behavior: "continue",
          invoice: next_invoice,
          before_plan: "",
        },
      };
      try {
        var FS_result = await firebase
          .firestore()
          .collection("stripe_customers")
          .doc(self.currentUser.uid)
          .set({ subscription: set_data }, { merge: true });
      } catch (e) {
        return e;
      }
      return FS_result;
    },

    // サブスクリプションの作成
    async createSubscription() {
      const self = this;
      let res = confirm(
        "月の途中でのプランのダウングレードはできません。プランを開始してもよろしいですか。"
      );
      if (res) {
        // 0.モーダル 表示
        self.is_loading = true;
        self.modalSwitch(true, "start_sub");

        // 1. stripe側でsubscriptionの開始
        const item = self.item_info;
        item["stripe_id"] = self.stripe_fs_info.stripe_id;

        const subscription_func = await firebase
          .app()
          .functions("asia-northeast1")
          .httpsCallable("createStripeSubscription");

        var stripe_res = await subscription_func(item);

        if (stripe_res.error !== void 0) {
          self.modalSwitch(false, "start_sub");
          alert("予期せぬエラーが発生しました\n決済は中断されます");
          return "error";
        }

        // 2. firebase にサブスクリプション追加
        const FS_result = await self.addFSSubscription(stripe_res);
        if (FS_result !== void 0) {
          self.modalSwitch(false, "start_sub");
          return "error";
        }

        // 4. 成功したらリダイレクト
        self.$router.push({ name: "StripeSuccessful" });
      }
    },
  },
};
</script>
<style scoped>
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

.test {
  margin: 10px 10px 10px 10px;
  display: block;
}
.card--format {
  justify-content: center;
  margin: 48px;
  max-width: 980px;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.wrapper {
  position: relative;
  margin: 6vw auto;
  width: 40vw;
  max-width: 60vw;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  flex-direction: column;
}
.card {
  margin: 30px auto;
  width: 30vw;
  background: #fff;
  border-radius: 5px;
}
.card-img {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  height: auto;
}
.card-content {
  margin: auto;
  width: max-content;
}
.title {
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}
.card-link {
  text-align: center;
  color: white;
  padding: 20px;
}
.card-link a {
  text-decoration: none;
  color: #0bd;
  margin: 0 10px;
}
</style>
