<template>
  <div>
    <!-- subscription 再開用 -->
    <loading-modal ref="l_modal" modal_name="tiption" :is_loading="is_loading">
      <template #modal-header-loading>
        <p>処置中...</p>
      </template>
      <template #modal-header>
        <p>{{ modal_title }}</p>
      </template>
      <template #modal-body>
        <p>{{ before_plan === 'premium' ? 'プレミアムプランの' : before_plan === 'standard' ? 'スタンダードプランの' : '' }}{{ modal_body }}</p>
      </template>
      <template #footer-left>
        <button @click="restartSubscription()" class="submit-btn btn-display btn-primary">
          再開
        </button>
      </template>
      <template #footer-right>
        <button @click="modalSwitch(false, 'reSubscription')" class="submit-btn cancel-btn">
          キャンセル
        </button>
      </template>
    </loading-modal>
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="wrapper">
          <div class="heading-inner">プランの変更</div>

          <div class="plans">
            <!-- Free plan -->
            <div class="card plan_1">
              <!-- <img class="card-img" src="./assets/free.jpg" alt /> -->
              <div class="card-content">
                <h1 class="card-title heading-inner">フリープラン</h1>
                <p class="card-text">リアルタイム文字起こし</p>
                <p class="card-text">文字起こし結果のダウンロード</p>
              </div>
              <!-- すでに来月以降フリープランへ移行予定の場合 -->
              <div v-if="(behavior == 'canceled') & within_deadline" class="card-link">
                <router-link to>
                  <span>{{ period }}に自動移行します</span>
                </router-link>
              </div>
              <!-- 現状freeプランの場合 -->
              <div v-else-if="current_plan == 'free'" class="card-link">
                <router-link to>
                  <span>現在のプラン</span>
                </router-link>
              </div>
              <!-- standard|premiumプランから翌月freeプランへ変更する場合 -->
              <div v-else class="card-link">
                <router-link :to="{
                    name: 'StripeCancelSubscription'
                  }">
                  <span class="btn-display btn-primary">プランの変更</span>
                </router-link>
              </div>
            </div>

            <!-- スタンダードプラン -->
            <div class="card plan_1">
              <!-- <img class="card-img" src="./assets/room_syosai.png" alt /> -->
              <div class="card-content">
                <h1 class="card-title heading-inner">スタンダードプラン</h1>
                <p class="card-text">リアルタイム文字起こし</p>
                <p class="card-text">文字起こし結果のダウンロード</p>
                <p class="card-text">音声ファイル文字起こし</p>
                <p class="card-text">文字起こし利用時間: 3時間</p>
              </div>

              <!-- カードなしならカードの登録へ -->
              <div v-if="stripe_fs_info.source == void 0" class="card-link">
                <router-link :to="{
                    name: 'StripeAddCard',
                    query: {
                      next_plan: 'standard',
                    },
                  }">
                  <span class="btn-display btn-primary">プランの変更</span>
                </router-link>
              </div>

              <!-- 現状standard & キャンセル予定なし -->
              <div v-else-if="current_plan == 'standard'" class="card-link">
                <router-link to>
                  <span>現在のプラン</span>
                </router-link>
              </div>

              <!-- 次月にstandardからfreeに変更予定 -->
              <div v-else-if="
                  (before_plan == 'standard') &
                  (behavior == 'canceled') &
                  within_deadline
                " class="card-link">
                <router-link to>
                  <span class="btn-display btn-primary" @click="modalSwitch(true, 'tiption')">プランの再開</span>
                </router-link>
              </div>

              <!-- premiumからのプラン変更 -->
              <div v-else-if="
                  (current_plan == 'premium') |
                    ((before_plan == 'premium') & within_deadline)
                " class="card-link">
                <!-- <router-link
                  :to="{
                    name: 'StripeUpdateSubscription',
                    params: {
                      stripe_fs_info: stripe_fs_info,
                      next_plan: 'standard',
                    },
                  }"
                > -->
                <router-link to="">
                  <span>Freeプランへ移行した後に、再度申込ください</span>
                </router-link>
              </div>

              <!-- Freeからのプラン変更 -->
              <div v-else class="card-link">
                <router-link :to="{
                    name: 'StripeSubscription',
                    query: {
                      next_plan: 'standard',
                    },
                  }">
                  <span class="btn-display btn-primary">プランの変更</span>
                </router-link>
              </div>
            </div>

            <!-- プレミアムプラン -->
            <div class="card plan_1">
              <!-- <img class="card-img" src="./assets/room_syosai.png" alt="" /> -->
              <div class="card-content">
                <h1 class="card-title heading-inner">プレミアムプラン</h1>
                <p class="card-text">リアルタイム文字起こし</p>
                <p class="card-text">文字起こし結果のダウンロード</p>
                <p class="card-text">音声ファイル文字起こし</p>
                <p class="card-text">ファイル管理機能</p>
                <p class="card-text">文字起こし利用時間: 10時間</p>
              </div>
              <!-- カードなしならカードの登録へ -->
              <div v-if="stripe_fs_info.source == void 0" class="card-link">
                <router-link :to="{
                    name: 'StripeAddCard',
                    query: {
                      next_plan: 'premium',
                    },
                  }">
                  <span class="btn-display btn-primary">プランの変更</span>
                </router-link>
              </div>

              <!-- 現状premium & 来月もpremiumの場合 -->
              <div v-else-if="current_plan == 'premium'" class="card-link">
                <router-link to>
                  <span>現在のプラン</span>
                </router-link>
              </div>
              <!-- 現状premium & 来月premium以外 -->
              <div v-else-if="
                  (before_plan == 'premium') &
                  (behavior == 'canceled') &
                  within_deadline
                " class="card-link">
                <router-link to>
                  <span class="btn-display btn-primary" @click="modalSwitch(true, 'reSubscription')">プランの再開</span>
                </router-link>
              </div>

              <!-- 現状Standard -->
              <div v-else-if="
                  (current_plan == 'standard') |
                    ((before_plan == 'standard') & within_deadline)
                " class="card-link">
                <router-link :to="{
                    name: 'StripeUpdateSubscription',
                    query: {
                      next_plan: 'premium',
                    },
                  }">
                  <span>プランのアップグレード</span>
                </router-link>
              </div>

              <!-- 現状Free & カードあり -->
              <div v-else class="card-link">
                <router-link :to="{
                    name: 'StripeSubscription',
                    query: {
                      next_plan: 'premium',
                    },
                  }">
                  <span class="btn-display btn-primary">プランの変更</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// official
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

// modules
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import LoadingModal from "@/components/modules/LoadingModal.vue";

// mixin
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import StripeFunc from "@/components/mixin/StripeFunc.js";

export default {
  mixins: [ArrangeDate, BaseFunctions, StripeFunc],
  name: "StripeChangePlan",
  components: {
    MyHeader,
    MySidebar,
    LoadingModal,
  },
  data() {
    return {
      // base
      currentUser: firebase.auth().currentUser,
      // stripe
      period: "",
      within_deadline: false,
      current_plan: "",
      before_plan: "",
      behavior: "",
      stripe_fs_info: {},
      // モーダル
      is_loading: false,
      modal_title: "プランの再開",
      modal_body: "サブスクリプションを再開しますか？",
    };
  },
  async created() {
    let self = this;
    let stripe_fs_info = await self.getFSinfo(self.currentUser.uid);
    self.stripe_fs_info = stripe_fs_info;

    if (self.currentUser == void 0) {
      self.$router.push("/account");
    }

    // 契約の状態
    let current_plan = stripe_fs_info.subscription.item_plan;
    self.current_plan = current_plan;
    let behavior = stripe_fs_info.subscription.status.behavior;
    self.behavior = behavior;
    let before_plan = stripe_fs_info.subscription.status.before_plan;
    self.before_plan = before_plan;
    if (behavior == "canceled") {
      let period = stripe_fs_info.subscription.status.invoice;
      period = new Date(period.seconds * 1000);
      if (period > Date.now()) {
        self.within_deadline = true;
      }
      self.period = this.formatDate(period, "yyyy/MM/dd");
    }
  },
  computed: {},
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggleSide();
    },

    // stripe側の再開
    async stripeRestart() {
      let sub_id = this.stripe_fs_info.subscription.id;
      let restartStripeSubscriptionFunc = await firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("restartStripeSubscription");

      let stripe_res = await restartStripeSubscriptionFunc({
        sub_id: sub_id,
      });
      return stripe_res;
    },

    // FS側の書き換え
    async updateFSSubscription(stripe_res) {
      let self = this;
      let FS_result = await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(self.currentUser.uid)
        .update(
          {
            "subscription.item_plan": self.before_plan,
            "subscription.status.behavior": "continue",
          }
        );
      return FS_result;
    },

    // 再開の一連の流れ
    async restartSubscription() {
      const self = this;
      self.is_loading = true;

      // 1.stripe側の更新
      const stripe_res = await self.stripeRestart();
      // エラーハンドリング
      if (stripe_res.data.error !== void 0) {
        self.modalSwitch(false, "reSubscription");
        self.modal_title = "Failed";
        self.modal_body = "予期せぬエラーが発生しました";
        return "error";
      }

      // firestore側のsubsctiptionの情報更新
      const FS_result = await self.updateFSSubscription(stripe_res);

      self.$router.push({
        name: "Account",
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  margin: 6vw auto;
  max-width: 75vw;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  flex-direction: column;
}
.card {
  position: relative;
  width: 20vw;
  height: 45vh;
  margin: 30px 2vw;
  background: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 2px 5px #ccc;
}
.card-img {
  border-radius: 5px 5px 0 0;
  height: 40%;
  width: 100%;
}

.card-link {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plans {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.title {
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-title {
  margin-bottom: 20px;
}
.card-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
  padding: 0 20px;
}
.card-link a {
  text-decoration: none;
  color: #0bd;
  margin: 0 10px;
}
.submit-btn {
  display: inline-block;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: bold;
    padding: 1.2rem 1rem;
    font-size: 14px;
    font-size: 1.4rem;
}
.cancel-btn {
  transform: translate(0, 10px);
}
</style>
