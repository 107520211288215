<template>
    <div id="all">
      <div class="wrapper">
        <form class="signup">
          <div class="title">
            <h1>Update imformation</h1>
            <p>アカウント情報の更新にご協力ください</p>
        </div>
          <div class="input-box">
            会社名
            <input type="" placeholder="" v-model="company_name" />
            <p v-if="!companyNameIsValid" class="error-message">必須項目です</p>
          </div>
          <div class="input-box">
            勤務先規模
            <br>
            <select type="" v-model="company_size">
              <option disabled value="">選択してください</option>
              <option>1. 0-100人</option>
              <option>2. 100-1000人</option>
              <option>3. 1000-10000人</option>
              <option>4. 10000人以上</option>
              </select>
              <p v-if="!companySizeIsValid" class="error-message">必須項目です</p>
          </div>
          <div class="input-box">
            職種
            <br>
            <select type="" v-model="occupation">
              <option disabled value="">選択してください</option>
              <option>1. 営業</option>
              <option>2. 経理</option>
              <option>3. 企画・管理</option>
              <option>4. 事務・アシスタント</option>
              <option>5. 販売・サービス職</option>
              <option>6. 専門職（コンサルや監査法人など）</option>
              <option>7. 金融系専門職</option>
              <option>8. 公務員・教員・農林水産関連職</option>
              <option>9. 技術職</option>
              <option>10. 医療系専門職</option>
              <option>11. クリエイター・クリエイティブ職</option>
              <option>12. その他</option>
              </select>
              <p v-if="!occupationIsValid" class="error-message">必須項目です</p>
          </div>
          <div class="input-box">
            役職
            <input type="" placeholder="" v-model="position"/>
            <p v-if="!positionIsValid" class="error-message">必須項目です</p>
          </div>
          <div class="btn-column">
            <button @click="updateInfo" type="submit" class="signup-btn" :disabled="!formIsValid">
              アカウント情報を更新
            </button>    
          </div>
          <div>
            <p class="what-texta">
              <router-link to="/landing_page" class="link">Textaとは？</router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
import authentication from "../mixin/authentication";

  export default {
    name: "updateInfo",
    mixins: [authentication],
    data() {
      return {
        userDataFromFirestore:[],
        name: "",
        email: "",
        password: "",
        password_sub: "",
        company_name: "",
        position: "",
        occupation: "",
        company_size: ""
      };
    },
    computed:{
      companyNameIsValid(){
        return this.company_name.length > 0
      },
      positionIsValid(){
        return this.position.length > 0
      },
      occupationIsValid(){
        return this.occupation.length > 0
      },
      companySizeIsValid(){
        return this.company_size.length > 0
      },
      formIsValid() {
        return this.companyNameIsValid 
        && this.positionIsValid && this.occupationIsValid && this.companySizeIsValid
      }
      
    },
    methods: {
      filledForm: function(){
      const company_size_num = {
        "1":"1. 0-100人",
        "2":"2. 100-1000人",
        "3":"3. 1000-10000人",
        "4":"4. 10000人以上",
      };
      const occupation_num = {
        "1":"1. 営業",
        "2":"2. 経理",
        "3":"3. 企画・管理",
        "4":"4. 事務・アシスタント",
        "5":"5. 販売・サービス職",
        "6":"6. 専門職（コンサルや監査法人など）",
        "7":"7. 金融系専門職",
        "8":"8. 公務員・教員・農林水産関連職",
        "9":"9. 技術職",
        "10":"10. 医療系専門職",
        "11":"11. クリエイター・クリエイティブ職",
        "12":"12. その他",
      };
      this.company_name = this.userDataFromFirestore.questionnaire.company_name,
      this.company_size = company_size_num[this.userDataFromFirestore.questionnaire.company_size],
      this.occupation = occupation_num[this.userDataFromFirestore.questionnaire.occupation],
      this.position = this.userDataFromFirestore.questionnaire.position
        },
    },
    beforeMount(){
        this.filledForm()
    },
    created(){
    this.userDataFromFirestore=JSON.parse(this.$route.params.userData);
  }
  };


  </script>
  
  <style scoped>
  .wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 480px;
    width: 100%;
    padding: 24px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    text-align: left;
  }
  
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .input-box {
    align-items: stretch;
    padding: 20px 20px 0;
    font-size: 12px;
  }
  
  input {
    display: block;
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid gray;
  }
  
  input:focus {
    border-bottom: 1px solid blue;
    outline: none;
  }
  
  .btn-column {
    display: flex;
    padding: 20px 20px 0;
    justify-content: flex-end;
  }


  button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
  
  .terms_of_use_link {
    text-decoration: underline #00a4c9;
  }
  
  .privacy_policy_link {
    text-decoration: underline #00a4c9;
  }
  
  .signup-btn {
    background: #3f51b5;
    color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    height: 36px;
    line-height: 35px;
    padding: 1px 16px 0;
    border-radius: 2px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .what-texta {
    display: flex;
    margin-top: 2vw;
    font-size: 1vw;
  }
  
  .input-box select {
    font-size: 1.1em;
    padding-left: 3px;
    margin-top: 3px;
  }
  
  .error-message {
    font-size: 1.2rem;
    color: red;
    margin-top: 10px;
  }

  select {
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
  appearance: menulist;
}
  </style>
  