<template>
  <div id="all">
    <vue-element-loading
            background-color="rgba(255, 255, 255, .7)"
            :active="is_loading" 
            is-full-screen 
            spinner="line-scale"
            color="#00a4c9"
      />
    <modal
      name="error"
      modal-class="modal modal-lg"
      wrapper-class="modal-wrapper"
      :enable-close="false"
    >
      <vue-element-loading
            background-color="rgba(255, 255, 255, .7)"
            :active="is_loading" 
            is-full-screen 
            spinner="line-scale"
            color="#00a4c9"
      />
      <div v-if="!is_loading" class="modal-content">
        <div class="heading-inner">
          <p>{{ error_message }}</p>
        </div>
          <div class="modal-body">
            <p>このメールアドレスは以前使用されていたようです。</p>
            <p><span v-if="period">ご利用を再開するには「利用を再開する」をクリックしてください。</span><span v-else>再度利用を希望する場合は<a :href=contact_url>こちら</a>よりお問い合わせください。</span></p>
          </div>
        <div class="modal-footer">
          <button v-show="period" class="footer-left btn-display btn-primary" @click="restartAccount">
            利用を再開する
          </button>
          <button class="footer-right submit-btn cancel-btn" @click="hide">キャンセル</button>
        </div>
      </div>
    </modal>
    <div class="wrapper">
      <form class="signin">
        <div class="title">
          <h1>Sign in</h1>
        </div>
        <div class="input-box">
          メールアドレス
          <input type="email" placeholder="" v-model="email" />
        </div>
        <div class="input-box">
          パスワード
          <input type="password" placeholder="" v-model="password" />
        </div>
        <div class="btn-column">
          <button @click="performSignIn" type="submit" class="signin-btn">
            サインイン
          </button>
          <button class="forget-btn">
            <router-link to="/reset">パスワードを忘れた方はこちら</router-link>
          </button>
        </div>
        <div>
          <p class="what-texta">
            <router-link to="/landing_page" class="link"
              >Textaとは？</router-link
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import authentication from "../mixin/authentication";
export default {
  name: "SignIn",
  mixins: [authentication],
  data() {
    return {
      // 判定
      is_loading: false,

      // 変数
      email: "",
      password: "",
      error_message: "",
      period: true,
      contact_url: process.env.VUE_APP_CONTACT_URL,
    };
  },
  created: function() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("chrome") == -1) {
      alert("お使いのブラウザは非推奨です。Google Chromeをご利用ください。");
    }
  },
  methods: {
    async performSignIn(event){
      const self = this;
      try {
        self.is_loading = true;
        await self.signIn(event);
      } catch (e) {
        self.is_loading = false;
      }
    },
    async checkAccountPlan(){
      const self = this;
      self.is_loading = true;
      let getAccountPlanFunc = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("getAccountPlan");
      const getAccountPlan_res = await getAccountPlanFunc({
        email: self.email,
      });
      if (getAccountPlan_res.data) {
        let accountPlan = getAccountPlan_res.data[0];
        let subscriptionStatus = getAccountPlan_res.data[1];
        let { behavior, invoice: { _seconds } } = subscriptionStatus;
        this.period = behavior === "canceled" && _seconds * 1000 >= Date.now() && accountPlan === 'free';
        self.is_loading = false;
      } else {
        alert("再ログインが必要です。");
        location.reload();
      }
    },
    async restartAccount() {
      const self = this;
      self.is_loading = true;
      let restartAccountFunc = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("restartAccount");
      const restartAccount_res = await restartAccountFunc({
        email: self.email,
      });
      if (restartAccount_res.error !== void 0) {
        self.is_loading = false;
        return "error";
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((user) => {
            self.$router.push("/");
          })
          .catch((error) => {
            alert("再ログインが必要です。");
            location.reload();
          });
      }
    },
    show: function() {
      this.checkAccountPlan();
      this.$modal.show("error");
    },
    hide: function() {
      this.$modal.hide("error");
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  padding: 24px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
}
.signin {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-box {
  align-items: stretch;
  padding: 20px 20px 0;
  font-size: 12px;
}
input {
  display: block;
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid gray;
}
input:focus {
  border-bottom: 1px solid blue;
  outline: none;
}

.btn-column {
  display: flex;
  padding: 20px 20px 0;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.link {
  /* text-decoration: underline #00a4c9; */
}
.what-texta {
  display: flex;
  margin-top: 2vw;
  font-size: 1vw;
}
.forget-btn {
  color: #00a4c9;
}
.signin-btn {
  background: #3f51b5;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  height: 36px;
  line-height: 35px;
  padding: 1px 16px 0;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
}

/* モーダル  */
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.modal-header {
  height: 20%;
  padding: 5px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.modal-header p {
  height: 20%;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
.modal-body {
  text-align: center;
  height: 60%;
  padding: 2rem;
  font-size: 1.6rem;
}
.modal-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 15px;
}
.footer-left {
  grid-column: 2;
  justify-self: center;
}
.footer-right {
  grid-column: 3;
  justify-self: right;
}
.submit-btn {
  display: inline-block;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: bold;
    padding: 1.2rem 1rem;
    font-size: 14px;
    font-size: 1.4rem;
}
.cancel-btn {
  transform: translate(0, 10px);
}
</style>
