<template>
  <div>
    <MyHeader />
    <!-- モーダル  -->
    <loading-modal
      ref="l_modal"
      modal_name="cancel_subscription"
      :is_loading="is_loading"
    >
      <template #modal-header-loading>
        <p>少々お待ちください...</p>
      </template>
      <template #modal-header>
        <p>{{ modal_title }}</p>
      </template>
      <template #modal-body>
        <p>{{ modal_body }}</p>
      </template>
      <template #footer-right>
        <router-link :to="{ name: 'Account' }">
          <button class="submit-btn btn-display btn-primary">HOME</button>
        </router-link>
      </template>
    </loading-modal>

    <!-- freeプランへ移行 -->
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="wrapper">
          <div class="heading-inner">プランの開始</div>
          <div class="card plan_1">
            <div class="card-content">
              <error-message :error_message="error_message"></error-message>
              <p>free plan へ移行します。</p>
              <p>現在のプランは {{ invoice }}までご利用可能です。</p>
            </div>
            <div class="card-link">
              <br />
              <button class="btn-display btn-primary" @click="cancelSubscription">Free plan へ移行</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// official
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

// module
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";
import ErrorMessage from "@/components/modules/ErrorMessage.vue";
import LoadingModal from "@/components/modules/LoadingModal.vue";

// mixin
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import StripeFunc from "@/components/mixin/StripeFunc.js";

export default {
  mixins: [ArrangeDate, BaseFunctions, StripeFunc],
  name: "StripeCancelSubscription",
  components: {
    MyHeader,
    MySidebar,
    LoadingModal,
    ErrorMessage,
  },
  data() {
    return {
      // base
      currentUser: firebase.auth().currentUser,

      // モーダル
      is_loading: false,
      modal_title: "",
      modal_body: "",

      // エラーハンドリング
      error_message: "",

      // stripe関連
      invoice: "",
      stripe_fs_info: {},
    };
  },
  async created () {
    let self = this;
    let stripe_fs_info = await self.getFSinfo(self.currentUser.uid);

    if (self.currentUser == void 0) {
      self.$router.push("/account");
    }
    self.stripe_fs_info = stripe_fs_info;

    let invoice = stripe_fs_info.subscription.status.invoice;
    invoice = new Date(invoice.seconds * 1000);
    self.invoice = this.formatDate(invoice, "yyyy/MM/dd");
  },
  computed: {},
  beforeRouteLeave(to, from, next) {
    if(!this.is_loading) {
      const answer = window.confirm(
        "free plan へ移行されていませんが、変更せずに遷移しますか？"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    // 型判定
    judgeObj(obj) {
      var toString = Object.prototype.toString;
      return toString.call(obj);
    },

    // stripe側の停止
    async stripeCancel() {
      let sub_id = this.stripe_fs_info.subscription.id;
      let cancelSubscriptionFunc = await firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("cancelStripeSubscription");

      let stripe_res = await cancelSubscriptionFunc({
        sub_id: sub_id,
      });
      return stripe_res;
    },

    // FS側の書き換え
    async updateFSSubscription(stripe_res) {
      let self = this;
      let before_plan = self.stripe_fs_info.subscription.item_plan;
      let FS_result = await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(self.currentUser.uid)
        .update(
          {
            "subscription.item_plan": "free",
            "subscription.status.behavior": "canceled",
            "subscription.status.before_plan": before_plan,
            "subscription.status.updated_at": Math.floor(Date.now() / 1000),
          }
        );
      return FS_result;
    },

    // cancelの一連の流れ
    async cancelSubscription() {
      const self = this;

      // モーダル
      self.is_loading = true;
      self.modalSwitch(true, "cancel_subscription");

      // stripe側の更新
      const stripe_res = await self.stripeCancel();
      // エラーハンドリング
      if (stripe_res.data.error !== void 0) {
        self.modalSwitch(false, "cancel_subscription");
        self.error_message = "予期せぬエラーが発生したため処理を中断しました";
        return "error";
      }

      // firestore側のsubsctiptionの情報更新
      const FS_result = await self.updateFSSubscription(stripe_res);
      self.modalSwitch(false);
      self.$router.push({
        name: "Account",
      });
    },
    show() {
      this.$modal.show("cancelPlan");
    },
    hide() {
      this.$modal.hide("cancelPlan");
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  margin: 6vw auto;
  width: 40vw;
  max-width: 60vw;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  flex-direction: column;
}
.card {
  margin: 30px auto;
  width: 30vw;
  background: #fff;
  border-radius: 5px;
}
.card-img {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  height: auto;
}
.card-content {
  margin: auto;
  width: max-content;
}
.title {
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}
.card-link {
  text-align: center;
  color: white;
  padding: 20px;
}
.card-link a {
  text-decoration: none;
  color: #0bd;
  margin: 0 10px;
}

.modal-content {
  height: 100%;
}

.modal-header {
  height: 20%;
  padding: 5px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.modal-header p {
  height: 20%;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
.modal-body {
  text-align: center;
  height: 60%;
  padding: 5px 25px;
}
.modal-footer {
  position: relative;
  height: 20%;
  padding: 5px 25px;
  border-top: 1px solid #ddd;
}
</style>
