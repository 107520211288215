<template>
  <Field
    v-slot="{ errors, value: val, handleChange }"
    :name="vid || name"
    :rules="rules"
    :label="name"
  >
    <slot :errors="errors" :value="val" :handleChange="handleChange" />
  </Field>
</template>

<script>
import { Field } from 'vee-validate'
export default {
  name: 'ValidationProvider',
  components: {
    Field,
  },
  props: {
    rules: {
      type: [Object, String, Function],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    vid: {
      type: String,
    }
  },
}
</script>