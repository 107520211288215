<template>
  <div id="all">
    <vue-element-loading
      background-color="rgba(255, 255, 255, .7)"
      :active="loading"
      is-full-screen
      spinner="line-scale"
      color="#00a4c9"
    />
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <modal
          name="member-authority"
          modal-class="modal modal-lg"
          wrapper-class="modal-wrapper"
          :enable-close="false"
        >
          <div class="modal-header">
            <h2 class="heading-inner">権限</h2>
          </div>
          <div class="modal-body result-modal-body">
            <select
              class="form_control-select"
              v-model="modalSelectedOption.member_authority_mst_id"
            >
              <option
                v-for="memberAuthority in memberAuthorities"
                :value="memberAuthority.member_authority_mst_id"
                :key="memberAuthority.member_authority_mst_id"
              >
                {{ memberAuthority.member_authority_name }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <div class="btn_column">
              <button
                type="button"
                class="btn-display btn-primary"
                v-on:click="closeModal"
              >
                <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                <span>キャンセル</span>
              </button>
              <button
                type="button"
                class="btn-display btn-primary"
                @click="
                  updateMemberAuthority(
                    modalSelectedOption.member_mst_id,
                    modalSelectedOption.member_authority_mst_id
                  )
                "
              >
                <font-awesome-icon icon="edit" />
                <span>保存</span>
              </button>
            </div>
          </div>
        </modal>
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">メンバー一覧</h2>
          </div>
          <div class="main-content">
            <div class="page-content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 py-0">
                    <div class="card">
                      <div class="member-btn">
                        <button
                          type="button"
                          class="btn-display btn-primary"
                          :class="{ 'blocked btn-blocked': isMembersCountExceed(maximumMembersCount, membersCount) }"
                          :disabled="isMembersCountExceed(maximumMembersCount, membersCount)"
                          @click="memberRegister()">
                          <span>メンバー登録</span>
                        </button>
                        <td class="member-limit">メンバー登録枠: {{ membersCount }} / {{ maximumMembersCount }} 人</td>
                      </div>
                      <table ref="table" 
                        class="table table-hover table-bordered dt-responsive nowrap"
                      >
                        <thead>
                          <tr>
                            <th scope="col">ユーザー名</th>
                            <th scope="col">権限</th>
                            <th scope="col">メールアドレス</th>
                            <th scope="col">作成日時</th>
                            <th scope="col">操作</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(member, index) in members" :key="index">
                            <td scope="row">
                              {{ member.member_name }}
                            </td>
                            <td>
                                <span>
                                {{
                                  this.memberAuthorities.find(
                                    (memberAuthority) =>
                                      memberAuthority.member_authority_mst_id ===
                                      member.member_authority_mst_id
                                  ).member_authority_name
                                }}
                                </span>
                            </td>
                            <td>{{ member.mail_address }}</td>
                            <td>
                              {{ dateFormat(member.created_at) }}
                            </td>
                            <td>
                              <div v-if="isNotMyColumn(member.member_mst_id)" class="dropdown">
                                <i class="dropdown-toggle" @click="toggleDropDown(index)"><font-awesome-icon :icon="['fas', 'ellipsis-v']" /></i>
                                <ul v-if="isDropDownOpen === index" class="dropdown-menu">
                                  <li class="dropdown-item">
                                    <button @click="openModal(member)" class="dropdown-btn">権限変更</button>
                                    <button @click="delete_member(member.member_mst_id)" class="dropdown-btn-del">削除</button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// official
import firebase from "firebase/app";
import axios from "axios";
// modules
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";
import { mapState, mapActions } from "vuex";
// mixin
import toggleFunctions from "@/components/mixin/toggleFunctions";
import Authority from "@/components/mixin/authority.js";

export default {
  mixins: [toggleFunctions, Authority],
  name: "MemberList",
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      // base
      currentUser: firebase.auth().currentUser,

      // 判定
      loading: false,
      members: [],
      memberAuthorities: [],
      modalSelectedOption: [],
      corpMstIdByFirebaseUserid: [],
      memberOperationAuthority: null,
      corpId: '',
      isDropDownOpen: null,
      membersCount: '',
      maximumMembersCount: '',
    };
  },
  async mounted() {
    this.userIdToken = await firebase.auth().currentUser.getIdToken();
    this.loading = true;
    // ユーザ操作権限の有無チェック
    this.memberOperationAuthority = await this.getMemberOperationAuthority(this.userIdToken);
    if(this.memberOperationAuthority) {
      this.setMemberId(this.memberOperationAuthority['authorities']['member_mst_id']);
    }
    if(!Boolean(this.memberOperationAuthority['authorities']['user_operation_authority'])) {
        alert("権限がありません。");
        this.$router.push("/");
    }else {
      // FirebaseUIDから企業ID取得
      this.corpMstIdByFirebaseUserid = await this.getCorpMstIdByMemberId(this.userIdToken, this.memberId);
      this.corpId = this.corpMstIdByFirebaseUserid['data'];
      this.getMemberAuthority();
      this.getMemberListByCorpId(this.corpId);
      this.maximumMembersCount = await this.getMaximumMembersCountByCorpId(this.userIdToken, this.corpId);
      this.membersCount = await this.getMembersCountByCorpId(this.userIdToken, this.corpId);
    }
    window.addEventListener('click', this._onBlurHandler = (event) => {
      if(this.$refs.table && !this.$refs.table.contains(event.target)) {
        this.isDropDownOpen = null;
      }
    });
    this.loading = false;
  },
  beforeUnmount() {
    window.removeEventListener('click', this._onBlurHandler);
  },
  computed: {
    ...mapState([
        "memberId",
    ]),
  },
  methods: {
    ...mapActions([
      "setMemberId",
    ]),
    // ユーザートークンの取得
    async renewUserIdToken() {
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
    },
    // 権限マスタから権限情報取得
    async getMemberAuthority() {
      this.renewUserIdToken();
      const headers = { Authorization: "Bearer " + this.userIdToken };
      axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_member_authority_msts",
          { headers: headers }
        )
        .then((response) => {
          this.memberAuthorities = response.data.authorities;
        })
        .catch((error) => {
        });
    },
    // 企業IDからメンバーリスト取得
    async getMemberListByCorpId(corpId) {
      this.renewUserIdToken();
      const headers = { Authorization: "Bearer " + this.userIdToken };
      const params = {
        corp_id: corpId,
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/get_member_list_by_corp_id",
          params,
          { headers: headers }
        )
        .then((response) => {
          this.members = response.data.member;
        })
        .catch((error) => {
        });
    },
    // 権限変更処理
    async updateMemberAuthority(targetMemberId, targetMemberAuthorityMstId) {
      this.renewUserIdToken();
      const headers = { Authorization: "Bearer " + this.userIdToken };
      const params = {
        target_member_authority_mst_id: targetMemberAuthorityMstId,
        target_member_id: targetMemberId,
        member_id: this.memberId,
      };
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_member_authority",
          params,
          { headers: headers }
        )
        .then((response) => {
          if(response.data.status) {
            var index = this.members.findIndex(
              (member) => member.member_mst_id === targetMemberId
            );
            Object.assign(this.members[index], this.modalSelectedOption);
            alert("権限を更新しました");
          }else if(response.data.error_code == 'USER_NOT_HAVE_AUTHORITY'){
            alert("権限がないため、権限更新できません。");
            this.$router.replace("/");
          }else {
            alert("権限更新に失敗しました");
          }
        })
        .catch((error) => {
          alert("権限更新に失敗しました");
        })
        .finally(() => {
          this.closeModal();
          this.loading = false;
        });
    },
    // メンバー削除処理
    async delete_member(memberId) {
      this.renewUserIdToken();
      const headers = { Authorization: "Bearer " + this.userIdToken };
      const params = {
        member_id: memberId,
      };
      // ユーザ操作権限の有無チェック
      this.memberOperationAuthority = await this.getMemberOperationAuthority(this.userIdToken);
      if(!Boolean(this.memberOperationAuthority['authorities']['user_operation_authority'])) {
        alert("ユーザ操作権限がありません。");
        this.$router.push("/");
      }else {
        if(confirm("削除すると、メンバーのデータも削除します。")) {
          this.loading = true;
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/delete_member",
              params,
              { headers: headers }
            )
            .then((response) => {
              if (response.data.status) {
                alert("メンバーを削除しました");
                this.$router.go({path: this.$router.currentRoute.path, force: true});
              } else {
                alert("メンバーの削除に失敗しました");
              }
            })
            .catch((error) => {
            })
            .finally(() => {
              this.loading = false;
            });
        }else {
          this.isDropDownOpen = null;
        }
      }
    },
    openModal(selectedOption) {
      this.modalSelectedOption = JSON.parse(JSON.stringify(selectedOption));
      this.$modal.show("member-authority");
    },
    closeModal() {
      this.$modal.hide("member-authority");
      this.isDropDownOpen = null;
    },
    toggleDropDown(index) {
      if(this.isDropDownOpen == index) {
        this.isDropDownOpen == null;
      }else {
        this.isDropDownOpen = index;
      }
    },
    memberRegister() {
      this.$router.push({
        path: "/member_regist",
      });
    },
    isNotMyColumn(memberMstId) {
      return memberMstId !== this.memberId;
    },
    isMembersCountExceed(maximumMembersCount, membersCount) {
      const result = parseInt(maximumMembersCount) <= parseInt(membersCount);
      return result;
    },
    dateFormat(dateValue) {
      if(dateValue !== "") {
        let date = (new Date(dateValue))
        let y = date.getUTCFullYear();
        let m = ("00" + (date.getUTCMonth() + 1)).slice(-2);
        let d = ("00" + date.getUTCDate()).slice(-2);
        let h = ("00" + date.getUTCHours()).slice(-2);
        let min = ("00" + date.getUTCMinutes()).slice(-2);
        return y + "-" + m + "-" + d + " " + h + ":" + min;
      }
    },
  },
};
</script>

<style scoped>
div + .heading-wrapper {
  margin: 3rem -3rem 2.5rem;
}

.main-content {
  margin-left: 60px;
}

.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: solid 2px #eff2f7;  
  margin-bottom: 1rem;
}

.table thead {
  background: #00a4c9ff;
  color: white;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eff2f7;
  border:1px solid #eff2f7;
}

.table-hover tbody tr:hover {
  color: #495057;
  background-color: #f8f9fa;
}

.table-hover .table-active:hover {
  background-color: #e9ecef;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e9ecef;
}

.btn-focus-0:focus {
  outline: none;
  box-shadow: none;
}

.table-hover tr:hover {
  cursor: default;
}

.result-modal-body {
  padding: 2rem;
  text-align: left !important;
}

.modal-footer {
  padding-bottom: 2rem;
}

.form_control-select {
  display: block;
  position: relative;
  padding-left: calc(1.8rem + 1rem);
  border-style: solid;
  width: -webkit-fill-available;
  background-image: linear-gradient(90deg, #00a4c9 0%, #00a4c9 50%, #4067a3 100%);
  /* background-color: #00a4c9; */
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 10px;
}

.form_control-select option {
  color: black;
  background: white;
}

.member-btn {
  display: grid;
  justify-content: end;
  padding-bottom: 5px;
}

.member-limit {
  display: flex;
  justify-content: end;
  align-items: center;
  align-content: center;
  padding-top: 5px;
}

.btn-regist {
  display: inline-block;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0.1rem solid #fff;
  border-radius: 10rem;
  background-image: -webkit-gradient(linear, left top, right top, from(#00a4c9ff), color-stop(50%, #00a4c9ff), to(#00a4c9ff));
  background-image: linear-gradient(90deg, #00a4c9ff 0%, #00a4c9ff 50%, #00a4c9ff 100%);
  background-size: 400% 100%;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 1.2rem 4rem;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.05);
}

.btn-regist:hover {
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(#ebfbff));
  background-image: linear-gradient(90deg, #00a4c9ff 50%, #00a4c9 100%);
  background-size: 100% 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-0.2rem);
  transform: translateY(-0.2rem);
}

.btn-withdrawal {
  display: inline-block;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0.1rem solid #fff;
  border-radius: 10rem;
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(201, 0, 0)), color-stop(50%, rgb(201, 0, 0)), to(rgb(201, 0, 0)));
  background-image: linear-gradient(90deg, rgb(201, 0, 0) 0%, rgb(201, 0, 0) 50%, rgb(201, 0, 0) 100%);
  background-size: 400% 100%;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 1.2rem 4rem;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.05);
}

.btn-withdrawal:hover {
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(#ebfbff));
  background-image: linear-gradient(90deg, rgb(201, 0, 0) 50%, rgb(201, 0, 0) 100%);
  background-size: 100% 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-0.2rem);
  transform: translateY(-0.2rem);
}

.btn-regist:focus,
.btn-withdrawal:focus {
  outline: 0;
}

.btn-regist span,
.btn-withdrawal span {
  display: inline-block;
  max-width: 100%;
}

.blocked {
  pointer-events: none;
  color: #ccc;
}

.btn-blocked {
  background-image: linear-gradient(#ccc, #ccc);
  box-shadow: none;
  border: 1px solid #ddd;
  color: #fff
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  width: 200px;
  transform: translate(-128%, -36%);
  z-index: 1000;
}

.dropdown-item {
  display: inline-grid;
  align-items: center;
  padding: 10px;
}

.dropdown-btn {
  padding: 10px;
  font-weight: bold;
}

.dropdown-btn-del {
  padding: 10px;
  color: red;
  font-weight: bold;
}
</style>
