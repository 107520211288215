<template>
  <div id="all">
    <modal
      name="withdrawal"
      modal-class="modal modal-lg"
      wrapper-class="modal-wrapper"
      :enable-close="false"
    >
      <vue-element-loading
            background-color="rgba(255, 255, 255, .7)"
            :active="is_loading" 
            is-full-screen 
            spinner="line-scale"
            color="#00a4c9"
      />
      <div
        v-if="!is_loading && !is_error && !is_withdrawal"
        class="modal-content"
      >
        <div  class="heading-inner">
          <p>本当に退会しますか？</p>
        </div>
        <div class="modal-body">
            <p v-if="is_confirmed == false" class="confirm-text">
              退会するとこのアカウントデータは全て削除します。<br>
              <span v-if="user_plan == 'enterprise'">
                データの復旧はできませんので、必要なデータはダウンロードし保管してください。<br>
              </span>
              今後、再度利用を希望する場合は<a :href=contact_url>こちら</a>よりお問い合わせください。
            </p>
          <p v-else>「退会を完了する」をクリックすると退会が完了します。</p>
        </div>
        <div class="modal-footer">
          <button v-if="is_confirmed == false" class="footer-left btn-display btn-primary" v-on:click="withdrawalConfirmation()">
            退会する
          </button>
          <button v-else class="footer-left btn-display btn-primary" v-on:click="withdrawal">
            退会を完了する
          </button>
          <button class="footer-right submit-btn cancel-btn" v-on:click="hide">キャンセル</button>
        </div>
      </div>
      <div
        v-if="!is_loading && !is_error && is_withdrawal"
        class="modal-content"
      >
        <div class="heading-inner">
          <p>退会が完了しました</p>
        </div>
        <div class="modal-body">
          <p>ご利用頂きありがとうございました。</p>
          <p>またご利用いただけることをお待ちしております。</p>
          <br />
          <p>5秒後に textaランディングページに移動します。</p>
        </div>
      </div>
      <div
        v-if="!is_loading && is_error && !is_withdrawal"
        class="modal-content"
      >
        <div class="heading-inner">
          <p>再ログインをしてください</p>
        </div>
        <div class="modal-body">
          <p>安全に退会処理を完了させるために再ログインをしてください。</p>
        </div>
        <div class="modal-footer">
          <button class="footer-right btn-display btn-primary" v-on:click="signOut">
            サインアウト
          </button>
        </div>
      </div>
    </modal>
    <button class="withdrawal-button" @click="show">退会する</button>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import authentication from "../mixin/authentication";
export default {
  name: "withdrawal",
  mixins: [authentication],
  data() {
    return {
      is_loading: false,
      is_withdrawal: false,
      is_error: false,
      is_confirmed: false,
      is_on_period:false,
      user_plan:"",
      contact_url: process.env.VUE_APP_CONTACT_URL,
    };
  },
  props:[
    "stripe_fs_info"
  ],
  methods: {
    to_landingPage() {
      let self = this;
      self.$router.push("/landing_page");
    },
    wait(sec) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, sec * 1000);
      });
    },
    async withdrawal() {
      const self = this;
      self.is_loading = true;
      let user = firebase.auth().currentUser;

      // 退会処理
      let withdrawalfunc = await firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("withdrawal");
      let withdrawal_res = await withdrawalfunc({
        uid: user.uid,
        on_period: this.is_on_period,
      });
      if (withdrawal_res.error !== void 0) {
        self.is_loading = false;
        self.is_withdrawal = false;
        self.is_error = true;
        return "error";
      } else {
        self.is_loading = false;
        self.is_withdrawal = true;
        await self.wait(5);
        self.to_landingPage();
      }
    },
    show: function() {
      this.$modal.show("withdrawal");
      this.user_plan = this.stripe_fs_info.subscription.item_plan;
      if (['premium','standard'].includes(this.user_plan)) {
        this.is_confirmed = true;
      } else {
        const { behavior, invoice: { seconds } } = this.stripe_fs_info.subscription.status;
        this.is_on_period = behavior === "canceled" && seconds * 1000 >= Date.now();
        if(this.is_on_period == true ) {
          this.is_confirmed = true;
        } else {
          this.is_confirmed = false;
        }
      }
    },
    hide: function() {
      this.$modal.hide("withdrawal");
    },
    async withdrawalConfirmation() {
      this.is_confirmed = true
    }
  },
};
</script>

<style scoped>
.withdrawal-button {
  color: #ea352d;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.modal-header {
  height: 20%;
  padding: 5px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.modal-header p {
  height: 20%;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
.modal-body {
  text-align: center;
  height: 60%;
  padding: 2rem;
  font-size: 1.6rem;
}
.modal-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 15px;
}
.footer-left {
  grid-column: 2;
  justify-self: center;
}
.footer-right {
  grid-column: 3;
  justify-self: right;
}
.submit-btn {
  display: inline-block;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: bold;
    padding: 1.2rem 1rem;
    font-size: 14px;
    font-size: 1.4rem;
}
.cancel-btn {
  transform: translate(0, 10px);
}
.confirm-text {
  color: #ea352d;
}

.confirm-text a {
  color: #ea352d;
  text-decoration: underline;
}
</style>
