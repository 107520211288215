<template>
    <div id="all">
        <div class="wrapper">
            <form v-if="isFormRequire === true" class="set-new-password">
                <div class="title">
                    <h1>パスワードの再設定</h1>
                </div>
                <p>メールアドレス: <b>{{ email }}</b></p>
                <div class="input-box">
                    パスワード (12文字以上かつ『英大文字』『英小文字』『数字』『記号』の4種のうち3種以上必須)
                    <input type="password" v-model="new_password" autocomplete="new-password" minlength="12" required />
                </div>
                <div class="input-box">
                    新しいパスワード（確認)
                    <input type="password" v-model="new_password_sub" minlength="12" required />
                </div>
                <div class="btn-column">
                    <button @click="updateProfilePassword" type="button" class="reset-btn">保存
                    </button>
                </div>
            </form>
            <template v-else>
                <div class="title">
                    <h2>{{ messageHeader}}</h2>
                </div>
                <p class="message">
                    {{ messageContent }}
                    <router-link :to="messageURL" >
                        <span>{{ messageLink }}</span>
                    </router-link>
                </p>
            </template>
        </div>
    </div>
</template>
  
<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import authentication from "../mixin/authentication";

export default {
    name: "AuthAction",
    mixins: [authentication],
    data() {
        return {
            email: "",
            actionInfo:"",
            actionCode: "",
            new_password: "",
            new_password_sub: "",
            messageHeader: "",
            messageContent: "",
            messageURL:"",
            messageLink:"",
            isFormRequire: false,
        };
    },
    computed: {},
    methods: {
        getActionInfo() {
            let self = this;
            this.actionCode = this.$route.query.oobCode;
            var auth = firebase.auth();
            auth
                .checkActionCode(this.actionCode)
                .then((response) => {
                    self.actionInfo = response
                    self.email = self.actionInfo.data.email
                    self.applyAuthAction(self.actionInfo.operation)
                })
                .catch(() => {
                    this.messageHeader = "再度確認してください"
                    this.messageContent = "リクエストの期限が切れたか、リンクがすでに使用されています"
                });
        },
        applyAuthAction(actionOperation){
            let self = this;
            var auth = firebase.auth();
            if (actionOperation === "VERIFY_EMAIL") {
                auth
                    .applyActionCode(this.actionCode)
                    .then(() => {
                        this.messageHeader = "メールアドレスは確認済みです"
                        this.messageContent = "新しいアカウントでログインできるようになりました"
                    })
                    .catch(() => {
                        this.messageHeader = "メールアドレスを再度確認してください"
                        this.messageContent = "メールアドレスの確認のリクエストの期限が切れたか、リンクがすでに使用されています"
                    });
            } else if (actionOperation === "RECOVER_EMAIL") {
                auth
                    .applyActionCode(this.actionCode)
                    .then(() => {
                        this.messageHeader = "メールアドレスを更新しました"
                        this.messageContent = "ログイン メールアドレスが "+ self.email +" に戻されました。 \nログイン メールアドレスの変更をリクエストしていない場合は、誰かがあなたのアカウントにアクセスしようとしている可能性があります。"
                        this.messageLink = "今すぐパスワードを変更してください。"
                        this.messageURL = "/editpassword"
                    })
                    .catch(() => {
                        this.messageHeader = "メールアドレスを更新できません"
                        this.messageContent = "ログイン メールアドレスを元に戻す際に問題が発生しました。 \n再度試してもメールアドレスを再設定できない場合は、管理者にお問い合わせください。"
                    });
            } else if (actionOperation === "PASSWORD_RESET") {
                this.isFormRequire = true;
            }
        },
        updateProfilePassword(e) {
            let self = this;
            var auth = firebase.auth();
            if (this.new_password == this.new_password_sub) {
                const judge = this.validatePassword(this.new_password);
                if (!judge["status"]) {
                    alert(judge["message"]);
                    return false;
                } else {
                    auth
                        .verifyPasswordResetCode(self.actionCode)
                        .then(() => {
                            auth
                                .confirmPasswordReset(self.actionCode, self.new_password)
                                .then(async () => {
                                    alert("パスワードが更新されました" + "\n" + "ログインページに移動します。");
                                    this.$router.replace("/signin");
                                })
                                .catch((error) => {
                                    if (error.code == "auth/weak-password") {
                                        alert(
                                            "パスワードが弱いです。12文字以上かつ英字と数字を混ぜてください。"
                                        );
                                    } else {
                                        alert("パスワードの更新に失敗しました！");
                                    }
                                })
                        })
                }
            }
        },
    },
    mounted() {
        this.getActionInfo();
    }
};
</script>
  
<style scoped>
.wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 480px;
    width: 100%;
    padding: 24px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    text-align: left;
}

.set-new-password {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-box {
    align-items: stretch;
    padding: 20px 20px 0;
    font-size: 12px;
}

input {
    display: block;
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid gray;
}

input:focus {
    border-bottom: 1px solid blue;
    outline: none;
}

.btn-column {
    display: flex;
    padding: 20px 20px 0;
    justify-content: flex-end;
}

.reset-btn {
    background: #3f51b5;
    color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    height: 36px;
    line-height: 35px;
    padding: 1px 16px 0;
    border-radius: 2px;
    font-weight: 500;
    cursor: pointer;
}

.message{
    margin-top: 25px;
}
</style>