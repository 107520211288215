<template>
  <div>
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="wrapper">
          <div class="heading-inner">完了</div>
          <div class="card plan_1">
            <div class="card-content">
              <p>サブスクリプションが開始されました</p>
            </div>
            <div class="card-link">
              <router-link :to="{ name: 'Realtime' }">
                <span class="btn-display btn-primary">Homeに戻る</span>
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// modules
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";

// mixin
import BaseFunctions from "@/components/mixin/BaseFunctions.js";

export default {
  mixins: [BaseFunctions],
  name: "StripeSuccessful",
  components: {
    MyHeader,
    MySidebar,
  },
  data: () => ({}),
  created: function() {},

  methods: {},
};
</script>
<style scoped>
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

.test {
  margin: 10px 10px 10px 10px;
  display: block;
}
.card--format {
  justify-content: center;
  margin: 48px;
  max-width: 980px;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.wrapper {
  position: relative;
  margin: 6vw auto;
  width: 40vw;
  max-width: 60vw;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  flex-direction: column;
}
.card {
  margin: 30px auto;
  width: 30vw;
  background: #fff;
  border-radius: 5px;
}
.card-img {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  height: auto;
}
.card-content {
  padding: 20px;
  text-align: center
}
.title {
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}
.card-link {
  text-align: center;
  color: white;
  padding: 20px;
}
.card-link a {
  text-decoration: none;
  color: #0bd;
  margin: 0 10px;
}
.card-link span {
  text-decoration: none;
  color: white;
  margin: 0 10px;
}
</style>
