<template>
  <header class="header">
    <div class="header_inner">
      <div class="header_logo">
        <router-link to="/">
          <img class="header_logo-image" src="../../assets/texta_logo_new.png" alt="TEXTA" />
        </router-link>
      </div>
      <div class="header_btn-menu">
        <button
          @click="toggleSideSwitch"
          type="button"
          class="btn-menu"
          :class="{ 'is-close': is_close }"
        >
          <i
            class="iconfont iconfont-menu-close"
            aria-label="メニューを閉じる"
          ></i>
        </button>
      </div>
      <div class="header_title">
        <h1 v-if="$route.path == '/'" class="header_title-text">
          <i class="iconfont iconfont-voice" aria-hidden="true"></i>
          <span>音声読み込み</span>
        </h1>
        <h1 v-else-if="$route.path == '/result'" class="header_title-text">
          <i class="iconfont iconfont-data" aria-hidden="true"></i>
          <span>結果一覧</span>
        </h1>
        <!-- <h1 v-else-if="$route.path == '/summarize'" class="header_title-text">
          <i class="iconfont iconfont-data" aria-hidden="true"></i>
          <span>議事録要約</span>
        </h1> -->
        <h1 v-else-if="$route.path == '/realtime'" class="header_title-text">
          <i class="iconfont iconfont-voice" aria-hidden="true"></i>
          <span>議事録作成</span>
        </h1>
        <h1 v-else-if="$route.path == '/account'" class="header_title-text">
          <i class="iconfont iconfont-setting" aria-hidden="true"></i>
          <span>アカウント設定</span>
        </h1>
        <h1 v-else-if="$route.path == '/editname'" class="header_title-text">
          <i class="iconfont iconfont-setting" aria-hidden="true"></i>
          <span>アカウント名変更</span>
        </h1>
        <h1 v-else-if="$route.path == '/editemail'" class="header_title-text">
          <i class="iconfont iconfont-setting" aria-hidden="true"></i>
          <span>メールアドレス変更</span>
        </h1>
        <h1
          v-else-if="$route.path == '/editpassword'"
          class="header_title-text"
        >
          <i class="iconfont iconfont-setting" aria-hidden="true"></i>
          <span>パスワード変更</span>
        </h1>
        <h1
          v-else-if="$route.path == '/contract_info'"
          class="header_title-text"
        >
          <i class="iconfont iconfont-setting" aria-hidden="true"></i>
          <span>契約内容の詳細</span>
        </h1>
        <p class="user">
          アカウント名：{{ user.displayName }}
          <template v-if="!user.emailVerified && is_initial_valified"
            >(未認証状態：{{ remainingTime }})</template
          >
        </p>
        <div class="header_title-btn">
          <!-- <button
            v-if="$route.path == '/' || $route.path == '/result'"
            @click="onReset"
            type="resetInput"
            class="btn-bright btn-sm"
          >
            <i class="iconfont iconfont-reset" aria-hidden="true"></i>
            <span>リセット</span>
          </button> -->
          <button @click="signOut" type="resetInput" class="btn-bright btn-sm">
            <i class="iconfont iconfont-reset" aria-hidden="true"></i>
            <span>サインアウト</span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import firebase from "firebase/app";
import authentication from "../mixin/authentication";
import toggleFunctions from "../mixin/toggleFunctions";
import AccountInfo from "../mixin/accountInfo.js";

export default {
  name: "MyHeader",
  mixins: [authentication, toggleFunctions, AccountInfo],
  data() {
    return {
      is_close: false,
      is_initial_valified: false,
    };
  },
  async created() {
    this.is_initial_valified = await this.getInitialValified();
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
    remainingTime() {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let creationStr = firebase.auth().currentUser.metadata.creationTime;
      let splitDate = creationStr.split(" ");
      let splitTime = splitDate[4].split(":");
      let monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let creationList = [
        Number(splitDate[3]),
        monthList.indexOf(splitDate[2]),
        Number(splitDate[1]),
        Number(splitTime[0]),
        Number(splitTime[1]),
        Number(splitTime[2]),
      ];
      let yesterdayList = [
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate(),
        today.getUTCHours(),
        today.getUTCMinutes(),
        today.getUTCSeconds(),
      ];
      let remainingTime =
        (creationList[2] - yesterdayList[2]) * 86400 +
        (creationList[3] - yesterdayList[3]) * 3600 +
        (creationList[4] - yesterdayList[4]) * 60 +
        (creationList[5] - yesterdayList[5]);
      if (remainingTime > 0) {
        let h = Math.floor(remainingTime / 3600);
        let m = Math.floor((remainingTime % 3600) / 60);
        var remainingStr = "残り" + h + "時間" + m + "分";
      } else {
        var remainingStr = "試用期間外";
      }
      return remainingStr;
    },
  },
  methods: {
    onReset() {
      this.$emit("reset");
    },
    async getInitialValified(){
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
      return await this.getIsInitialValified(this.userIdToken);
    },
  },
};
</script>

<style scoped>
.header {
  background-color: rgba(243, 243, 243, 0.95);
  border-bottom: 0.1rem solid rgba(220, 220, 220, 0.95);
  box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.1),
    0 -1px 0 0 rgba(255, 255, 255, 0.95) inset;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
}

.header_inner {
  height: 7.5rem;
  position: relative;
  display: -webkit-box;
  display: flex;
}

.header_logo {
  background-color: #ebf1f2;
  min-width: 25rem;
  height: calc(7.5rem - 0.2rem);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.header_logo .header_logo-image {
  width: 128px;
  height: 38px;
}

.header_logo > * {
  padding-left: 3rem;
}

.header_title {
  -webkit-box-flex: 1;
  flex: 1;
  padding: 0 6rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.header_title-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
}

.header_title-text .iconfont {
  vertical-align: 15%;
  margin-right: 1rem;
  font-size: 30px;
  font-size: 3rem;
  color: #00a4c9;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#4067a3),
    to(#00a4c9)
  );
  background: linear-gradient(0deg, #4067a3, #00a4c9);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
}

@media all and (-ms-high-contrast: none) {
  .header_title-text .iconfont {
    background: none;
  }
}

.header_title-text span {
  color: #00a4c9;
  font-weight: normal;
  font-size: 20px;
  font-size: 2rem;
}

.header_title-btn {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}

.header_title-btn > *:not(:last-of-type) {
  margin-right: 2rem;
}

.btn-menu {
  width: 3rem;
  height: 3rem;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 26rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0.1rem solid #fff;
  border-radius: 10rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00a4c9),
    color-stop(50%, #00a4c9),
    to(#4067a3)
  );
  background-image: linear-gradient(
    90deg,
    #00a4c9 0%,
    #00a4c9 50%,
    #4067a3 100%
  );
  background-size: 400% 100%;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.btn-menu:focus {
  outline: 0;
}

.btn-menu:not(:disabled),
.btn-menu:not(.is-disabled) {
  cursor: pointer;
}

.btn-menu span {
  display: inline-block;
  max-width: 100%;
}

.btn-menu:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00a4c9),
    to(#4067a3)
  );
  background-image: linear-gradient(90deg, #00a4c9 0%, #4067a3 100%);
  background-size: 100% 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-0.2rem);
  transform: translateY(-0.2rem);
}

.btn-menu.is-disabled,
.btn-menu:disabled {
  opacity: 0.7;
  box-shadow: none;
  pointer-events: none;
}

.btn-menu .iconfont {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.btn-menu:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00a4c9),
    to(#4067a3)
  );
  background-image: linear-gradient(90deg, #00a4c9, #4067a3);
  background-size: 400% 100%;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.8;
}

.btn-menu .iconfont {
  color: #fff;
  font-size: 15px;
  font-size: 1.5rem;
}

.btn-menu.is-close .iconfont {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.user {
  flex-grow: 2;
  text-align: left;
  margin-left: 2.4rem;
}
</style>
