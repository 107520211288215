<template>
  <div id="all">
    <vue-element-loading
            background-color="rgba(255, 255, 255, .7)"
            :active="is_loading" 
            is-full-screen 
            spinner="line-scale"
            color="#00a4c9"
    />
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">メールアドレス変更</h2>
          </div>
          <div class="content">
            <div class="profile">
              <p class="profile-title">現在のメールアドレス</p>
              <p class="profile-value">
                {{ user.email
                }}<span v-if="!user.emailVerified">（未認証）</span>
              </p>
            </div>
            <div class="profile">
              <p class="profile-title">新しいメールアドレス</p>
              <input type="text" v-model="email" required />
            </div>
            <div class="dummy">
              <input type="text" />
            </div>
            <div class="profile">
              <p class="profile-title">現在のパスワード</p>
              <input type="password" v-model="password" required />
            </div>
            <div class="btn_column">
              <button
                @click="updateProfileEmail"
                type="button"
                class="btn-display btn-primary"
              >
                <span>保存</span>
              </button>
              <router-link to="/account">
                <button type="button" class="btn-display btn-primary">
                  <span>キャンセル</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import toggleFunctions from "../mixin/toggleFunctions";

export default {
  name: "EditName",
  mixins: [toggleFunctions], //toggleSidebar呼び出しのため
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      email: "",
      password: "",
      is_loading: false,
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    async updateProfileEmail(e) {
      this.is_loading = true;
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.password
      );
      let changeable = true;
      if (!this.email || this.email != user.email) {
        await user.reauthenticateWithCredential(credential)
          .catch((error) => {
            alert("パスワードが誤っています ", error.message);
            changeable = false;
            this.is_loading = false;
          });
        await user.updateEmail(this.email)
          .catch(error => {
              if (
                error.message ==
                "This operation is sensitive and requires recent authentication. Log in again before retrying this request."
              ) {
                alert(
                  "メールアドレスが変更されませんでした\n一度ログインをし直して再度お試しください"
                );
              } else if (
                error.message ==
                "The user's credential is no longer valid. The user must sign in again."
              ) {
                alert(
                  "メールアドレスが変更されませんでした\n一度ログインをし直して再度お試しください"
                );
              } else if (error.code == "auth/email-already-in-use") {
                alert("すでに登録されているメールアドレスです");
              } else if (error.code == "auth/invalid-email") {
                alert(
                  "メールアドレスは'***@***.com'、'***@***.ne.jp'、'***@***.co.jp'などの形にしてください"
                );
              } else {
                alert("メールアドレスの更新に失敗しました！");
              }
              changeable = false;
              this.is_loading = false;
          })
        await user.sendEmailVerification()
          .catch(error => {
              alert("確認メールが送れませんでした", error.message);
              changeable = false;
              this.is_loading = false;
          })
        // stripeの登録変更
        const functions = firebase.app().functions("asia-northeast1");
        const updateFunc = functions.httpsCallable("updateEmailCustomer");
        await updateFunc({
          email: this.email
        }).catch(error => {
          alert('メールの更新に失敗しました ', error.message)
          return false
        })
        .then(() => {
          if(changeable == true) {
            alert("認証メールが送信されました。認証メール記載のリンクは72時間で失効します。\n届かない場合または失効した場合は認証メールを再送してください。");
            this.$router.replace("/account");
          }
        }).finally(() => {
          this.is_loading = false;
        });
      } else {
        alert("新しいメールアドレスを入力してください");
        this.is_loading = false;
        return false
      }
    },
  },
};
</script>

<style scoped>
input {
  background: #eee;
  width: 500px;
  height: 50px;
  display: inline-flex;
  padding-left: 10px;
}
.dummy input {
  width: 0;
  height: 0;
}

.btn_column {
  padding: 15px 0 15px 60px;
  width: 500px;
  display: inline-flex;
}
.profile {
  padding: 0 0 15px 60px;
}
.profile-title {
  font-weight: bold;
  font-size: 2rem;
}
.profile-value {
  font-size: 1.8rem;
}
</style>
