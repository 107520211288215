<template>
    <div id="all">
        <vue-element-loading background-color="rgba(255, 255, 255, .7)" :active="loading" is-full-screen
            spinner="line-scale" color="#00a4c9" />
        <MyHeader @sidebar="toggleSidebar" />
        <div class="container">
            <MySidebar ref="sidebar" />
            <main class="main">
                <modal name="realtime-result-edit" modal-class="modal modal-lg" wrapper-class="modal-wrapper"
                    :enable-close="false">
                    <div class="modal-header">
                        <h2 class="heading-inner">文字起こし結果のファイル名</h2>
                    </div>
                    <div class="modal-body result-modal-body">
                        <li>ファイル名：{{ currentTextFileName }}</li>
                        <input class="text-name-input" type="text" v-model="textFilename"
                            placeholder="ファイル名を入力してください" />
                        <p v-if="isInValidFileName" class="text-name-input-error">ファイル名に「/」は使用できません</p>
                    </div>
                    <div class="modal-footer">
                        <div class="btn_column">
                            <button type="button" class="btn-display btn-primary" v-on:click="modalClose">
                                <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                                <span>キャンセル</span>
                            </button>
                            <button type="button" class="btn-display btn-primary" :class="{ 'btn-loading': isInValidFileName }" @click="showResult">
                                <font-awesome-icon icon="edit" />
                                <span>名称変更</span>
                            </button>
                        </div>
                    </div>
                </modal>
                <div class="box">
                    <div class="heading-wrapper">
                        <h2 class="heading-inner">{{ "文字起こし" }}</h2>
                    </div>
                    <table>
                        <tr class="audio-wrapper" v-if="isShowAudio()">
                            <td>会議音声</td>
                            <td colspan="2">
                                <audio id="player" controls preload="none" controlslist="nodownload"
                                    oncontextmenu="return false;">
                                    <source />
                                    <a href></a>
                                </audio>
                            </td>
                        </tr>
                        <tr class="edit-wrapper">
                            <td style="font-weight: bold">文字起こし結果</td>
                            <td colspan="2" class="edit-wrapper-textarea">
                                <div class="form_group result-text">
                                    <div class="checkbox_group">
                                        <input v-if="isShowNameCheckBox()" type="checkbox" name="namerecord"
                                            v-model="nameRecordValue" id="namerecord" class="form_control-checkbox" />
                                        <label v-if="isShowNameCheckBox()" for="namerecord"
                                            class="form_control-label">話者を表示する</label>
                                        <input v-if="isShowTimeCheckBox()" type="checkbox" name="time"
                                            v-model="timeRecordValue" id="time" class="form_control-checkbox" />
                                        <label v-show="isShowTimeCheckBox()" for="time"
                                            class="form_control-label">時間を表示する</label>
                                        <input v-if="isShowAudioCheckBox()" type="checkbox" name="playaudio"
                                            v-model="playAudioValue" id="playaudio" class="form_control-checkbox" />
                                        <label v-if="isShowAudioCheckBox()" for="playaudio"
                                            class="form_control-label">音声を再生する</label>
                                    </div>
                                    <div class="result-edit">
                                        <button v-if="resultDisabled" v-on:click="editTextContent" type="button"
                                            class="btn-display btn-primary">
                                            <font-awesome-icon icon="edit" />
                                            <span>内容編集</span>
                                        </button>
                                        <template v-else>
                                            <button v-on:click="cancelTextContent" type="button"
                                                class="btn-display btn-primary">
                                                <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                                                <span>キャンセル</span>
                                            </button>
                                            <button v-on:click="saveTextContent" type="button"
                                                class="btn-display btn-primary">
                                                <i class="iconfont iconfont-save" aria-hidden="true"></i>
                                                <span>保存</span>
                                            </button>
                                        </template>
                                    </div>
                                </div>
                                <div class="record-content">
                                    <div class="record-content-flex" v-for="n of recordedCount" v-bind:key="n">
                                        <audio style="display: none;" class="player_texts" :id="'player_' + n" controls
                                            preload="none" :data-audio="n" :data-start="recordedStart[n - 1]"
                                            :data-end="recordedEnd[n - 1]">
                                            <source />
                                        </audio>
                                        <i class="record-control" :data-audio="n"
                                            style="font-size: 14px; font-weight: normal;"
                                            :data-start="recordedStart[n - 1]" :data-end="recordedEnd[n - 1]">
                                            <div class="record-text-time" v-if="timeRecordValue" :data-audio="n"
                                                :data-start="recordedStart[n - 1]" :data-end="recordedEnd[n - 1]">
                                                {{ (recordedTimer[n - 1])?recordedTimer[n - 1]:'[00:00]' }}
                                            </div>
                                            <span class="record-name-input" v-if="nameRecordValue">
                                                <input style="width:100%" :disabled="resultDisabled" type="text"
                                                    v-model="recordedUser[n - 1]" placeholder="会議参加者" />
                                            </span>
                                            <textarea v-on:mousedown="playAudioForRow(n)" :class="getRowClass(n)"
                                                :readonly="resultDisabled" v-if="recordedText[n - 1].length <= 44"
                                                type="text" rows="1" cols="50" v-model="recordedText[n - 1]"></textarea>
                                            <textarea v-on:mousedown="playAudioForRow(n)" :class="getRowClass(n)"
                                                :readonly="resultDisabled" v-else-if="recordedText[n - 1].length <= 88"
                                                type="text" rows="2" v-model="recordedText[n - 1]"></textarea>
                                            <textarea v-on:mousedown="playAudioForRow(n)" :class="getRowClass(n)"
                                                :readonly="resultDisabled" v-else-if="recordedText[n - 1].length <= 132"
                                                type="text" rows="3" v-model="recordedText[n - 1]"></textarea>
                                            <textarea v-on:mousedown="playAudioForRow(n)" :class="getRowClass(n)"
                                                :readonly="resultDisabled" v-else-if="recordedText[n - 1].length < 176"
                                                type="text" rows="4" v-model="recordedText[n - 1]"></textarea>
                                            <textarea v-on:mousedown="playAudioForRow(n)" :class="getRowClass(n)"
                                                :readonly="resultDisabled" v-else-if="recordedText[n - 1].length < 220"
                                                type="text" rows="5" v-model="recordedText[n - 1]"></textarea>
                                            <textarea v-on:mousedown="playAudioForRow(n)" :class="getRowClass(n)"
                                                :readonly="resultDisabled" v-else type="text" rows="6"
                                                v-model="recordedText[n - 1]"></textarea>
                                        </i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="filename-wrapper">
                            <td>ファイル名</td>
                            <td>
                                {{ currentTextFileName }}.txt
                            </td>
                            <td class="text-right">
                                <button v-on:click="resultModal()" type="button" class="btn-display btn-primary">
                                    <font-awesome-icon icon="edit" />
                                    <span>名称変更</span>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="btn_column">
                    <button @click="downloadTextRealtime" type="button"
                        class="btn-display btn-primary btn-lg" :class="{ 'blocked btn-blocked': !resultDisabled }"
                        :disabled="!resultDisabled">
                        <i class="iconfont iconfont-cloud-download" aria-hidden="true"></i>
                        <span>テキスト保存</span>
                    </button>
                    <button v-if="isAudioSaved" @click="downloadAudioRealtime" type="button" class="btn-display btn-primary btn-lg" id="download-button">
                        <a href id="download-audio"></a>
                        <i class="iconfont iconfont-cloud-download" aria-hidden="true"></i>
                        <span>音声保存</span>
                    </button>
                    <button v-if="isRealtime" @click="reloadRealtime" type="button"
                        class="btn-display btn-primary btn-lg">
                        <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                        <span>新しい文字起こしを作成</span>
                    </button>
                    <button v-if="!isRealtime" @click="reloadResult" type="button"
                        class="btn-display btn-primary btn-lg">
                        <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                        <span>結果一覧に戻る</span>
                    </button>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";

// mixin
import UsageTime from "../mixin/usageTime";

export default {
    name: "EditResult",
    mixins: [UsageTime],
    components: {
        MyHeader,
        MySidebar
    },
    data() {
        return {
            resultDisabled: true,
            downloadLink: null,

            // ユーザー情報
            user_id: firebase.auth().currentUser.uid,
            userIdToken: "",

            nameRecordValue: false,
            timeRecordValue: true,
            tempNameRecordValue: false,
            tempTimeRecordValue: false,
            playAudioValue: false,
            recordedUserList: [],
            canSave: true,
            loading: false,
            currentTime: 0,
            isAudioSaved: false,
            audioUrl: null,
            textFilename: "",
            currentTextFileName: "",
            audioTimeOutID: null
        };
    },
    beforeRouteLeave(to, from, next) {
        if (!this.resultDisabled) {
            const confirmMessage = "文字起こし結果は保存されていませんが、保存せずに遷移しますか？";
            if (confirm(confirmMessage)) {
                this.resetSpeechSituation();
                this.cancelTextContent();
                next();
            } else {
                next(false);
                return;
            }
        } else {
            this.resetSpeechSituation();
        }
        next();
    },
    async beforeMount() {
        this.loading = true;
        if(typeof this.realname === 'undefined') {
            window.location.href = '/result';
        }
        await this.getTranscriptionInfo(this.realname);
    },
    async mounted() {
        this.userIdToken = await firebase.auth().currentUser.getIdToken();
        this.currentTextFileName = this.fileName;
        if (this.currentTextFileName !== "") {
            this.textFilename = this.currentTextFileName;
        }
        const lastIndex = this.realname.lastIndexOf('-');
        this.setRecordedTime(this.realname.substring(lastIndex + 1));
        await this.showEditTextResult();
        this.audioUrl = await this.showAudioResult(this.realname);
        this.$nextTick(() => {
            if (this.audioUrl !== '') {
                // ページの再生のHTML要素にURLを埋め込む
                // audioタグに転送
                document.getElementById("player").src = this.audioUrl;
                // audioタグのplayer_textsクラスに転送
                var audio_tags = document.getElementsByClassName("player_texts");
                for (var i = 0; i < this.recordedCount; i++) {
                    var start = this.recordedStart[i];
                    var end = this.recordedEnd[i];
                    audio_tags[i].src = this.audioUrl + `#t=${start},${end}`;
                }
                // ダウンロードボタンに転送
                this.downloadLink = document.getElementById("download-audio");
                this.downloadLink.href = this.audioUrl;
                const audio = document.getElementById("player");
                audio.addEventListener("timeupdate", this.updateCurrentTime);
                audio.addEventListener("timeupdate", this.scrollToHighlight);
            }
            this.loading = false;
        });
    },
    computed: {
        ...mapState([
            "recordedCount",
            "recordedChunks",
            "recordedStart",
            "recordedEnd",
            "recordedTimer",
            "recordedText",
            "recordedUser",
            "fileName",
            "recordedTime",
            "realname",
            "isRealtime",
        ]),
        isInValidFileName(){
            return this.textFilename.includes("/");
        },
    },
    methods: {
        ...mapActions([
            "resetSpeechSituation",
            "setFileName",
            "saveRecordedText",
            "setRecordedTime",
            "setRecordedStart",
            "setRecordedEnd",
            "setRecordedCount",
            "saveRecordedText",
            "setRecordedTimer",
            "setRecordedUser",
            "setRealname",
        ]),
        // サイドバーの開け閉め
        toggleSidebar() {
            this.$refs.sidebar.toggleSide();
        },
        editTextContent() {
            this.resultDisabled = false;
            this.recordedUserList = this.recordedUser.slice();
            this.currentRecordedText = Object.assign({}, this.recordedText);
        },
        cancelTextContent() {
            this.setRecordedUser(this.recordedUserList);
            this.saveRecordedText(Object.values(this.currentRecordedText));
            this.resultDisabled = true;
            this.timeRecordValue = this.tempTimeRecordValue;
            this.nameRecordValue = this.tempNameRecordValue;
        },
        // gcsから要約を取得
        async getSummarize(textnamePlusTime) {
            this.renewUserIdToken();
            const params = {
                file_name: textnamePlusTime,
                user_id: this.user_id,
            };
            const headers = { Authorization: "Bearer " + this.userIdToken };
            await axios.post(
                `${process.env.VUE_APP_API_ENDPOINT}/texta-basic-api/get_summarize`,
                params,
                { headers: headers }
            )
                .then((response) => {
                    this.canSave = response.data.status == false ? true : false;
                })
                .catch(() => {
                    this.canSave = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async saveTextContent() {
            this.loading = true;
            let self = this;
            let now = new Date();
            let year = now.getFullYear();
            let month = ("0" + (now.getMonth() + 1)).slice(-2);
            let day = ("0" + now.getDate()).slice(-2);
            let hour = ("0" + now.getHours()).slice(-2);
            let minute = ("0" + now.getMinutes()).slice(-2);
            let second = ("0" + now.getSeconds()).slice(-2);
            let time = year + month + day + hour + minute + second;
            let textnamePlusTime = this.currentTextFileName + "-" + time + ".txt";
            let resultText = "";
            let lines = [];
            await this.getSummarize(textnamePlusTime);
            if (this.canSave == true) {
                for (let count = 0; count < this.recordedCount; count++) {
                    let line = '';
                    if (this.recordedTimer[count] === undefined) {
                        line += "[00:00]" + " ";
                    } else {
                        line += this.recordedTimer[count] + " ";
                    }
                    if (this.recordedUser[count] === undefined) {
                        line += "会議参加者: ";
                    } else {
                        line += this.recordedUser[count] + ": ";
                    }
                    line += this.recordedText[count];
                    lines.push(line + "\n");
                }
                resultText = lines.join('');
                resultText = resultText.replace('\r\n', '\n');
                var formData = new FormData();
                formData.append("user_id", self.user_id);
                formData.append("text_name", this.realname);
                formData.append("text_result", resultText);
                formData.append("time_display", this.timeRecordValue);
                formData.append("participants_display", this.nameRecordValue);
                const headers = { Authorization: "Bearer " + self.userIdToken };
                axios
                    .post(
                        process.env.VUE_APP_API_ENDPOINT +
                        "/texta-basic-api/save_realtime",
                        formData,
                        { headers: headers }
                    )
                    .then(function (response) { })
                    .catch((error) => { })
                    .finally(() => {
                        this.currentRecordedText = Object.assign({}, this.recordedText);
                        this.saveRecordedText(Object.values(this.currentRecordedText));
                        this.tempTimeRecordValue = this.timeRecordValue;
                        this.tempNameRecordValue = this.nameRecordValue;
                        this.loading = false;
                        this.resultDisabled = true;
                    });
            } else {
                alert("議事録作成済みの為、内容を編集できません。")
                this.cancelTextContent();
                this.loading = false;
            }
        },
        resultModal() {
            this.$modal.show('realtime-result-edit');
        },
        modalClose() {
            this.textFilename = this.currentTextFileName;
            this.$modal.hide('realtime-result-edit');
        },
        isHighlightInView(container, highlight) {
            const containerRect = container.getBoundingClientRect();
            const highlightRect = highlight.getBoundingClientRect();
            return (highlightRect.top >= containerRect.top && highlightRect.bottom <= containerRect.bottom);
        },
        scrollToHighlight() {
            this.$nextTick(() => {
                const container = document.querySelector(".record-content");
                const highlight = document.querySelector(".record-text-input-highlight");
                if (highlight && this.isHighlightInView(container, highlight)) {
                    const containerRect = container.getBoundingClientRect();
                    const highlightRect = highlight.getBoundingClientRect();
                    const offset = highlightRect.top - containerRect.top + container.scrollTop;
                    const scrollY = offset - (containerRect.height - highlightRect.height) * 1 / 2;
                    container.scrollTo({
                        top: scrollY,
                        behavior: "smooth"
                    });
                }
            })
        },
        updateCurrentTime(event) {
            this.currentTime = event.target.currentTime;
        },
        between(x, min, max) {
            if (!max) {
                return x >= min;
            }
            return x >= min && x < max;
        },
        playAudioForRow(n) {
            let self = this;
            if (this.isAudioSaved) {
                const audio = document.getElementById("player");
                audio.pause();
                audio.currentTime = self.recordedStart[n - 1];
                if (this.resultDisabled) {
                    audio.play();
                } else if (!this.resultDisabled && self.playAudioValue) {
                    const audioStart = self.recordedStart[n - 1];
                    const audioEnd = self.recordedEnd[n - 1];
                    if(self.audioTimeOutID) {
                        clearTimeout(self.audioTimeOutID)
                    }
                    audio.play();
                    if(audioEnd) {
                        const duration = (audioEnd - audioStart) * 1000;
                        self.audioTimeOutID = setTimeout(() => {
                            audio.pause();
                        }, duration);
                    }
                }
            }
        },
        getRowClass(n) {
            let self = this;
            let start = self.recordedStart[n - 1];
            let end = self.recordedEnd[n - 1];
            if (this.resultDisabled && (this.isAudioSaved)) {
                if (self.between(this.currentTime, start, end)) {
                    return "record-text-input-highlight";
                } else {
                    return "record-text-input-pointer";
                }
            } else {
                return "record-text-input";
            }
        },
        async showAudioResult(fileName) {
            const self = this;
            const api_result = await self.getAudio(fileName);
            if (api_result && api_result.status) {
                self.isAudioSaved = api_result.status;
                return api_result.data;
            } else {
                return '';
            }
        },
        // gcs から音声を取得
        async getAudio(fileName) {
            const self = this;
            return new Promise((okFunc /* ngFunc */) => {
                const params = {
                    file_name: fileName,
                    user_id: self.user_id,
                };
                const headers = { Authorization: "Bearer " + self.userIdToken };
                axios
                    .post(
                        process.env.VUE_APP_API_ENDPOINT +
                        "/texta-basic-api/get_audio",
                        params,
                        { headers: headers }
                    )
                    .then((response) => {
                        okFunc({
                            status: response.data.status,
                            data: response.data.data,
                        });
                    })
                    .catch((res) => {
                        okFunc({ status: false });
                    });
            });
        },
        
        async getTranscriptionInfo(fileName) {
            const self = this;
            const params = {
                file_name: fileName,
                user_id: self.user_id,
            };
            this.userIdToken = await firebase.auth().currentUser.getIdToken();
            const headers = { Authorization: "Bearer " + self.userIdToken };
            await axios
                .post(
                    process.env.VUE_APP_API_ENDPOINT +
                    "/texta-basic-api/get_transcription_info",
                    params,
                    { headers: headers }
                )
                .then((response) => {
                    const time_display = response.data.data['time_display'];
                    const participants_display = response.data.data['participants_display'];
                    this.timeRecordValue = time_display;
                    this.nameRecordValue = participants_display;
                    this.tempTimeRecordValue = time_display;
                    this.tempNameRecordValue = participants_display;
                })
                .catch((res) => {
                });
        },
        downloadTextRealtime() {
            this.dlText = "";
            let lines = [];
            for (let count = 0; count < this.recordedCount; count++) {
                let line = '';

                if (this.timeRecordValue) {
                    line += this.recordedTimer[count] + " ";
                }

                if (this.nameRecordValue) {
                    if (this.recordedUser[count] === undefined) {
                        line += "会議参加者: ";
                    } else {
                        line += this.recordedUser[count] + ": ";
                    }
                }

                line += this.recordedText[count];

                lines.push(line + "\n");
            }
            this.dlText = lines.join('');
            var blob = new Blob([this.dlText], { type: "text/plain" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            if (this.textFilename == "") {
                let now = new Date();
                let year = now.getFullYear();
                let month = ("0" + (now.getMonth() + 1)).slice(-2);
                let day = ("0" + now.getDate()).slice(-2);
                let hour = ("0" + now.getHours()).slice(-2);
                let minute = ("0" + now.getMinutes()).slice(-2);
                let second = ("0" + now.getSeconds()).slice(-2);
                this.textFilename = "" + year + month + day + hour + minute + second;
            }
            link.download = this.textFilename + ".txt";
            link.click();
        },
        async reloadRealtime() {
            this.resetSpeechSituation();
            window.location.href = '/';
        },
        async reloadResult() {
            this.$router.go(-1);
        },
        // リアルタイムの音声保存
        async downloadAudioRealtime() {
            let self = this;
            if (this.textFilename == "") {
                let now = new Date();
                let year = now.getFullYear();
                let month = ("0" + (now.getMonth() + 1)).slice(-2);
                let day = ("0" + now.getDate()).slice(-2);
                let hour = ("0" + now.getHours()).slice(-2);
                let minute = ("0" + now.getMinutes()).slice(-2);
                let second = ("0" + now.getSeconds()).slice(-2);
                this.textFilename = "" + year + month + day + hour + minute + second;
            }
            self.downloadLink = document.getElementById("download-audio");
            self.downloadLink.href = this.audioUrl;
            self.downloadLink.download = this.textFilename + ".webm";
            self.downloadLink.click();
        },
        async showResult() {
            if (this.textFilename === "") {
                this.textFilename = this.recordedTime;
            }
            await this.editFileName();
        },
        async editFileName() {
            const params = {
                user_id: this.user_id,
                old_name: this.currentTextFileName + "-" + this.recordedTime,
                new_name: this.textFilename + "-" + this.recordedTime,
            };

            const headers = { Authorization: "Bearer " + this.userIdToken };
            this.loading = true;
            axios
                .post(
                    process.env.VUE_APP_API_ENDPOINT +
                    "/texta-basic-api/edit_filename",
                    params,
                    { headers: headers }
                )
                .then(async (response) => {
                    if (response.status === 200) {
                        this.currentTextFileName = this.textFilename;
                        this.setFileName(this.currentTextFileName);
                        this.setRealname(this.currentTextFileName + "-" + this.recordedTime);
                        if(this.isAudioSaved) {
                            this.audioUrl = await this.showAudioResult(this.currentTextFileName + "-" + this.recordedTime);
                            // ページの再生のHTML要素にURLを埋め込む
                            // audioタグに転送
                            document.getElementById("player").src = this.audioUrl;
                            // audioタグのplayer_textsクラスに転送
                            var audio_tags = document.getElementsByClassName("player_texts");
                            for (var i = 0; i < this.recordedCount; i++) {
                                var start = this.recordedStart[i];
                                var end = this.recordedEnd[i];
                                audio_tags[i].src = this.audioUrl + `#t=${start},${end}`;
                            }
                        }
                        this.$modal.hide('realtime-result-edit');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
            // 編集テキストの取得
        async showEditTextResult() {
            const self = this;
            const api_result = await self.getEditText();
            if (!api_result) {
                alert("ダウンロードエラー(Error02)\nテキストのダウンロードに失敗しました。結果一覧から文字起こしが正常に終了しているか確認してください。");
            }
        },
    
        // gcs からテキストを取得
        async getEditText() {
            const self = this;
            return new Promise((okFunc /* ngFunc */) => {
                const params = {
                    file_name: self.realname,
                    user_id: self.user_id,
                };
                const headers = { Authorization: "Bearer " + self.userIdToken };
                axios
                .post(
                    process.env.VUE_APP_API_ENDPOINT +
                    "/texta-basic-api/edit_text_download",
                    params,
                    { headers: headers }
                )
                .then((response) => {
                    okFunc({
                        status: true,
                    });
                    this.setRecordedStart(response.data.recorded_start);
                    this.setRecordedEnd(response.data.recorded_end);
                    this.setRecordedCount(response.data.recorded_count);
                    this.saveRecordedText(response.data.recorded_text);
                    this.setRecordedTimer(response.data.recorded_timer);
                    this.setRecordedUser(response.data.recorded_user);
                })
                .catch((res) => {
                    okFunc({ status: false });
                });
            });
        },
        // ユーザートークンの取得
        async renewUserIdToken() {
            this.userIdToken = await firebase.auth().currentUser.getIdToken();
        },
        isShowNameCheckBox() {
            const result = !this.resultDisabled;
            return result;
        },
        isShowTimeCheckBox() {
            const result = !this.resultDisabled;
            return result;
        },
        isShowAudioCheckBox() {
            const result = !this.resultDisabled && this.isAudioSaved;
            return result;
        },
        isShowAudio() {
            const result = this.isAudioSaved;
            return result;
        },
    },
};
</script>

<style scoped>
.time {
    display: inline-block;
    color: red;
}

@keyframes blink {
    50% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

table {
    text-align: left;
    width: 100%;
}

td:nth-child(1) {
    font-weight: bold;
    width: 12rem;
}

td:nth-child(2) .form_group {
    margin-top: 0.1rem;
}

.edit-wrapper td:nth-child(1) {
  vertical-align: -1rem;
}

.edit-wrapper-audio {
  padding-top: 35px;
}

.edit-wrapper-textarea {
  width: 50px;
  padding-bottom: 10px;
}

.record-content {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 0 0.5rem;
    background: lightgray;
    max-height: 40vh;
    overflow-y: auto;
}

.record-content-flex {
    display: flex;
    margin: 0.5rem 0;
}

.record-control {
    color: rgb(0, 174, 255);
    display: flex;
    width: 100%;
}

.record-text-time {
    width: 85px;
    padding: 1rem;
    border-radius: 10px;
    margin: 0 0 0 2px;
    font-weight: bold;
}

.record-text-input {
    padding: 1rem;
    width: 100%;
    background: #fff;
    margin: 0 0 0 2px;
    border-radius: 10px;
}

.record-text-input-pointer {
    cursor: pointer;
    outline: none;
    padding: 1rem;
    width: 100%;
    background: #fff;
    margin: 0 0 0 2px;
    border-radius: 10px;
}

.record-text-input-highlight {
    cursor: pointer;
    outline: none;
    padding: 1rem;
    width: 100%;
    background: #d0f7ff;
    color: #0a0a0a;
    margin: 0 0 0 2px;
    border-radius: 10px;
}

.record-name-input {
    border-radius: 10px;
    padding: 1rem;
    margin: 0 0 0 2px;
    background: #fff;
    width: 175px;
}

/* ========================= */
/* =   以下クラウドワークス   = */
/* ========================= */

.form_group {
    display: inline-block;
    margin: 0.7rem 0;
    position: relative;
}

.checkbox_group {
    display: flex;
    column-gap: 3rem;
    margin-left: 2rem;
}

.form_control,
.form_control-radio,
.form_control-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.form_control-select {
    display: block;
    position: relative;
    padding-left: calc(1.8rem + 1rem);
    border-style: solid;
    background-image: linear-gradient(90deg,
            #00a4c9 0%,
            #00a4c9 50%,
            #4067a3 100%);
    /* background-color: #00a4c9; */
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 10px;
}

.form_control-select option {
    color: black;
    background: white;
}

.form_control-label {
    display: block;
    position: relative;
    padding-left: calc(1.8rem + 1rem);
    cursor: pointer;
}

.form_control-label::before,
.form_control-label::after {
    content: "";
    position: absolute;
    z-index: 1;
}

.form_control-label::before {
    top: 0.4rem;
    left: 0;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.1rem solid #dcdcdc;
    background-color: #f6f6f6;
}

:disabled+.form_control-label {
    cursor: not-allowed;
    opacity: 0.5;
}

:disabled+.form_control-label:hover {
    opacity: 0.5;
}

.form_control-label:hover {
    opacity: 0.8;
}

.form_control-radio+.form_control-label::before,
.form_control-radio+.form_control-label::after {
    border-radius: 100%;
}

.form_control-checkbox+.form_control-label::before,
.form_control-checkbox+.form_control-label::after {
    border-radius: 0;
}

.form_control-radio:checked+.form_control-label::before,
.form_control-checkbox:checked+.form_control-label::before {
    border-color: #00a4c9;
    background-color: #fff;
}

.form_control-radio:checked+.form_control-label::after,
.form_control-checkbox:checked+.form_control-label::after {
    top: 0.8rem;
    left: 0.4rem;
    width: 1rem;
    height: 1rem;
    background-color: #00a4c9;
}

.blocked .form_control-radio:checked+.form_control-label::after,
.blocked .form_control-checkbox:checked+.form_control-label::after {
    top: 0.7rem;
}

.result-modal-body {
    padding: 2rem;
    text-align: left !important;

    .finished-message {
        margin-bottom: 2rem;
    }

    .text-name-input {
        border-style: solid;
        width: 100%;
    }

    .text-name-input-error {
        color: red;
    }
}

.modal-footer {
    padding-bottom: 2rem;
}

.text-right {
    text-align: right;
}

.result-text {
    width: 100%;

    .result-edit {
        float: right;

        .btn-display {
            margin-left: 0.5rem;
        }
    }
}

.btn-blocked {
    background-image: linear-gradient(#ccc, #ccc);
    box-shadow: none;
    border: 1px solid #ddd;
    color: #fff
}

.main {
    padding: 1rem 6rem 5.0rem 6rem;
    -webkit-box-flex: 1;
    flex: 1;
    position: relative;
}

.heading-wrapper {
    margin: -3rem -3rem 1.5rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.box+.btn_column {
    margin-top: 1.5rem;
}
</style>