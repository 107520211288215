<template>
  <div id="all">
    <vue-element-loading
      background-color="rgba(255, 255, 255, .7)"
      :active="loading" 
      is-full-screen 
      spinner="line-scale"
      color="#00a4c9"
    />
    <MyHeader @reset="onReset" @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <div v-if="upload_loading" class="modal">
        <div class="loading-box">
          <div class="uploading-message">
            <h3>
              <font-awesome-icon icon="spinner" spin />アップロード中...{{
                progress_rate
              }}%
            </h3>
          </div>
        </div>
      </div>
      <div v-if="file_loading" class="modal">
        <div class="loading-box">
          <div class="uploading-message">
            <h3>
              <font-awesome-icon icon="spinner" spin />ファイル読み込み中...
            </h3>
          </div>
        </div>
      </div>
      <main class="main">
        <div class="box form_column">
          <div class="form_content">
            <dl class="form_list">
              <dt>文字起こし残り時間</dt>
              <dd>{{ remainingTime }}秒</dd>
            </dl>
            <dl class="form_list">
              <dt>ファイルの再生時間</dt>
              <dd>{{ audio_time }}秒</dd>
            </dl>
          </div>
        </div>
        <div class="box">
          <p>録音済みの音声ファイルをボックス内にアップロードしてください。</p>
          <div class="form_column">
            <div class="form_upload">
              <form>
                <div
                  v-if="situation == 0"
                  class="form_upload-inner"
                  @dragleave.prevent
                  @dragover.prevent
                  @drop.prevent="selectedFileTrans"
                >
                  <i
                    class="iconfont iconfont-cloud-upload"
                    aria-hidden="true"
                  ></i>
                  <span class="form_upload-text">
                    ここにファイルをドラッグ＆ドロップ
                    <br />または
                  </span>
                  <label for="file-input">
                    <input
                      @change="selectedFileTrans"
                      type="file"
                      name="file"
                      class="file-input"
                      id="file-input"
                    />
                    <div class="btn-primary">
                      <span>ファイルを選択</span>
                    </div>
                  </label>
                  <span class="form_upload-text">
                    使用できる拡張子は mp3, wav, ogg, flac, aac, mp4, webm,
                    m4aです
                    <br />※
                    現在、120MB以上のファイルは文字起こしに失敗する場合があります。
                    <br />ファイルを分割して文字起こししてください。
                  </span>
                </div>
                <div v-else-if="situation > 2" class="form_upload-inner">
                  <i class="iconfont iconfont-check" aria-hidden="true"></i>
                  <span class="form_upload-text"
                    >アップロードが完了しました。</span
                  >
                </div>
                <div v-else class="form_upload-inner">
                  <i class="iconfont iconfont-save" aria-hidden="true"></i>
                  <span class="form_upload-text">{{ filename }}</span>
                </div>
              </form>
              <p class="form_upload-text">言語を選択してください</p>
              <select name="language" v-model="language" class="btn-primary">
                <option value="ja-JP" selected>日本語</option>
                <option value="en-US">English(英語)</option>
                <option value="zh-CN">普通话(中国語)</option>
                <option value="zh-HK">粤語（香港）</option>
                <option value="zh-TW">國語（台湾）</option>
                <option value="ko-KR">한국의(韓国語)</option>
                <option value="hi-IN">हिन्दी(ヒンディー語)</option>
                <option value="es-ES">español(スペイン語)</option>
                <option value="ru-RU">русский(ロシア語)</option>
                <option value="pt-BR">português(ポルトガル語)</option>
                <option value="fr-FR">français(フランス語)</option>
                <option value="de-DE">Deutsch(ドイツ語)</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="situation == 1" class="btn_column">
          <button
            @click="uploadAudioFile"
            type="button"
            class="btn-primary btn-lg"
          >
            <i class="iconfont iconfont-reading" aria-hidden="true"></i>
            <span>文字起こし開始</span>
          </button>
        </div>
        <footer class="footer">
          <p class="footer_copyright">©Texta. Developed by TECHNODIGITAL.</p>
        </footer>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import 'firebase/storage';
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import toggleFunctions from "../mixin/toggleFunctions";
import UsageTime from "../mixin/usageTime";

export default {
  name: "FileTranscription",
  mixins: [toggleFunctions, UsageTime],
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      situation: 0, // 0:none, 1:select, 2:start, 3:finish
      language: "ja-JP",
      upload_loading: false,
      file_loading: false,
      text_loading: false,
      file_downloading: false,
      script_show: false,
      filename: "",
      splited_filename: "",
      filesize: 0,
      ext: "",
      filenamePlusTime: "",
      record_situation: 0, // 0:before, 1:start, 2:finish, 3:decide
      record_count: 0,
      timerId: null,
      startTime: 0, // 開始時刻
      elapsedTime: 0, // 経過時間
      timerToadd: 0, // 経過時間保持
      record_filename: "",
      localstream: "",
      mediaRecorder: null,
      recordedChunks: [],
      blob: null,
      remainingTime: null,

      // people: "1",
      // summary: [],
      script: "",
      uploadFile: "",
      audio_time: "--- ",
      progress_rate: "",
      user_id: firebase.auth().currentUser.uid,
      loading: false,
    };
  },
  async created() {
    // 無料アカウントの場合はリアルタイムに移動
    this.loading = true;
    let userSubscription = await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(`${this.user_id}`)
        .get();
    if (userSubscription.data().subscription.item_plan == "free" &&
      !((userSubscription.data().subscription.status.behavior == "canceled") &&
      (userSubscription.data().subscription.status.invoice.seconds > Date.now() / 1000))) {
      alert("フリープランではこちらの機能をご利用いただけません\nご利用いただくにはプランをアップグレートしてください")
      this.$router.push("/");
    }
    this.userIdToken = await firebase.auth().currentUser.getIdToken();

    if (!navigator.mediaDevices) {
      alert("mediaDevices is unavailable");
      // 「お使いのデバイスは録音に対応していません」と表示
    }
    // 利用時間の確認
    const userState = await this.getUsageTime();
    this.remainingTime = parseInt(userState.time);

    // 現在文字起こし中のファイルの時間取得
    const proceeding_time = await this.getProceedingTime(this.userIdToken);
    this.remainingTime = this.remainingTime - proceeding_time;
    this.loading = false;
  },
  computed: {
    min: function() {
      let m = Math.floor(this.elapsedTime / 60000);
      return ("0" + m).slice(-2);
    },
    sec: function() {
      let s = Math.floor((this.elapsedTime % 60000) / 1000);
      return ("0" + s).slice(-2);
    },
  },
  methods: {
    selectedFileTrans: function(e) {
      e.preventDefault();
      let self = this;

      self.file_loading = true;
      //inputから来たらe.target.files、ドロップ&ドラッグから来たらe.dataTransfer.files
      let files = e.target.files ? e.target.files : e.dataTransfer.files;
      self.uploadFile = files[0];
      self.filename = files[0].name;
      self.filesize = files[0].size;
      let filename = self.filename;
      if (self.filename.length > 40) {
        alert("ファイル名が長すぎます。40字以内で記入してください。");
        self.onReset();
        return false;
      }
      let type = filename.split(".");
      let available = [
        "mp3",
        "wav",
        "ogg",
        // "flv",
        "flac",
        "aac",
        // "wma",
        // "aiff",
        "mp4",
        "webm",
        "m4a",
      ];
      self.ext = type.pop().toLowerCase();

      // 再生時間取得
      const audio = new Audio();
      const file = files[0];
      const fileURL = URL.createObjectURL(file);
      audio.src = fileURL;
      audio.controls = true;

      audio.onloadeddata = () => {
        audio.currentTime = 7 * 24 * 60 * 1000;
        audio.onseeked = () => {
          self.audio_time = Math.ceil(audio.duration);
          if (!Number.isInteger(self.audio_time)) {
            alert(
              "音声時間を検出することができません。ファイルが破損していないかご確認ください。"
            );
          } else {
            audio.onseeked = undefined;
            const diff_time = self.remainingTime - self.audio_time;
            if (diff_time < 0) {
              alert(
                "利用可能時間を超過しています\n" +
                  "音声ファイルの総時間： " +
                  self.audio_time
              );
              self.onReset();
            }
          }
        };
      };
      if (!available.includes(self.ext)) {
        alert("対応していないファイル形式(拡張子)です");
        self.onReset();
      } else {
        self.splited_filename = type.join(".");
        self.situation = 1;
      }
      if (self.filesize > 120000000) {
        alert("120MB以上の音声ファイルは文字起こしができません。音声ファイルを分割して実行してください。")
        self.onReset();
      }
      self.file_loading = false;
    },

    uploadAudioFile(e) {
      let self = this;

      let now = new Date();
      let year = now.getFullYear();
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let day = ("0" + now.getDate()).slice(-2);
      let hour = ("0" + now.getHours()).slice(-2);
      let minute = ("0" + now.getMinutes()).slice(-2);
      let second = ("0" + now.getSeconds()).slice(-2);
      let time = "" + year + month + day + hour + minute + second;
      self.filenamePlusTime =
        self.splited_filename + "-" + time + "." + self.ext;
      self.situation = 2;

      let storage = firebase.app().storage(process.env.VUE_APP_STORAGE_PATH);
      let dataRef = storage.ref(
        `/${self.user_id}/original_audio/${self.filenamePlusTime}`
      );
      self.upload_loading = true;
      let uploadTask = dataRef.put(self.uploadFile);

      uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          self.progress_rate = parseInt(progress);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        function(error) {
          // アップロード失敗時の処理
          self.upload_loading = false;
          alert(
            "不明なエラー(Error00)\n文字起こしに失敗しました。サービスが混み合っている可能性があります。時間をあけて再度お試しください。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
          self.situation = 1;
        },
        function() {
          // GCSにアップロード後、文字起こし開始処理を行う
          const headers = { Authorization: "Bearer " + self.userIdToken };
          let formData = new FormData();
          formData.append("file_name", self.filenamePlusTime);
          formData.append("user_id", self.user_id);
          formData.append("audio_time", self.audio_time);
          formData.append("language", self.language);
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT +
                "/file-transcription/upload_and_transcript",
              formData,
              { headers: headers }
            )
            .then(function(res) {
              // アップロード成功時の処理
              self.upload_loading = false;
              self.situation = 3;
            })
            .catch(function(error) {
              // アップロード失敗時の処理
              self.upload_loading = false;
              alert(
                "不明なエラー(Error00)\n文字起こしに失敗しました。サービスが混み合っている可能性があります。時間をあけて再度お試しください。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
              );
              self.situation = 1;
            });
        }
      );
    },

    onReset() {
      const self = this;
      self.file_loading = false;
      self.audio_time = "--- ";
      self.situation = 0;
      self.script_show = false;
      self.filename = "";
      self.splited_filename = "";
      self.ext = "";
      self.filenamePlusTime = "";
      self.record_situation = 0;
      self.record_count = 0;
      self.record_filename = "";
      self.localstream = "";
      self.mediaRecorder = null;
      self.recordedChunks = [];
      self.blob = null;
      self.script = "";
    },
  },
};
</script>

<style scoped>
.file-input {
  display: none;
}
.form_upload form,
.record form {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
}
.record-inner {
  -webkit-box-flex: 1;
  flex: 1;
  padding: 2rem;
  border: 0.1rem dashed rgba(0, 164, 201, 0.5);
  border-radius: 0.5rem;
  text-align: left;
}
.record-inner ol li {
  list-style-position: outside;
  padding-left: 2rem;
  text-indent: -2rem;
  font-size: 1.5rem;
}
.record-inner ol li + li {
  margin-top: 2rem;
}
.time {
  margin-left: 4rem;
  display: inline-block;
  color: red;
}
.red-circle {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 1.4rem;
  background-color: red;
  line-height: 1.4rem;
  opacity: 0;
}
.blinking {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  50% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

.record-inner audio {
  width: 100%;
}
.filename-input {
  position: relative;
  left: 4.2rem;
}
.filename-input label {
  display: inline-block;
  position: relative;
  margin-right: 1.2rem;
}
.filename-input input {
  border: 0.1rem solid gray;
  border-radius: 0.2rem;
  width: 60%;
  padding: 0 1rem;
  vertical-align: 1%;
}

.blocked {
  pointer-events: none;
  color: #ccc;
}
.blocked p {
  color: #ccc;
}
.blocked .red-circle {
  background-color: #ccc;
}
.blocked input {
  border-color: #ccc;
  color: #ccc;
}
.blocked ::placeholder {
  color: #ccc;
}

/* ▼ uploadingモーダル ▼ */
.modal {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 25rem;
  width: calc(100vw - 25rem);
  height: 100%;
}
.loading-box {
  display: flex;
  position: absolute;
  top: 42rem;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 90%;
  margin: auto;
  border-radius: 24px;
  background-color: rgba(0, 164, 201, 1);
}
.uploading-message {
  display: block;
  margin: auto;
  font-size: 18px;
  color: #222;
}
.uploading-message h3 {
  margin: 48px;
}

.text_loading {
  margin-left: 4rem;
}
.text_loading span {
  font-size: 1.6rem;
}

/* ========================= */
/* =   以下クラウドワークス   = */
/* ========================= */

.form_column {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form_upload,
.record {
  background-color: rgba(0, 164, 201, 0.04);
  text-align: center;
  width: 100%;
  min-height: 26rem;
  padding: 2rem;
  /* margin-right: 4.27%; */
  border-radius: 0.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.form_upload .iconfont {
  color: rgba(0, 164, 201, 0.2);
  font-size: 65px;
  font-size: 6.5rem;
}
.is-disabled .form_upload .iconfont {
  color: rgba(0, 164, 201, 0.7);
  font-size: 50px;
  font-size: 5rem;
}
.form_upload-inner {
  -webkit-box-flex: 1;
  flex: 1;
  /* width: 100%; */
  border: 0.1rem dashed rgba(0, 164, 201, 0.5);
  border-radius: 0.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.is-disabled .form_upload-inner {
  border: 0;
  cursor: not-allowed;
}
.form_upload-text {
  color: #00a4c9;
  display: block;
  margin: 0.4rem 0;
  font-size: 15px;
  font-size: 1.5rem;
}
.is-disabled .form_upload-text {
  margin: 1.5rem 0 0;
}

.form_content {
  text-align: left;
  -webkit-box-flex: 1;
  flex: 1;
}

.form_list dt {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
}

.is-disabled .form_list dt {
  opacity: 0.5;
}

.form_list dd {
  font-size: 13px;
  font-size: 1.3rem;
}

.form_list + .form_list {
  margin-top: 3rem;
}

.form_group {
  display: inline-block;
  margin: 0.7rem 0;
  position: relative;
}

.form_column .form_group {
  margin-right: 3rem;
}

.form_control,
.form_control-radio,
.form_control-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form_control-label {
  display: block;
  position: relative;
  padding-left: calc(1.8rem + 1rem);
  cursor: pointer;
}

.form_control-label::before,
.form_control-label::after {
  content: "";
  position: absolute;
  z-index: 1;
}

.form_control-label::before {
  top: 0;
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid #dcdcdc;
  background-color: #f6f6f6;
}

:disabled + .form_control-label {
  cursor: not-allowed;
  opacity: 0.5;
}

:disabled + .form_control-label:hover {
  opacity: 0.5;
}

.form_control-label:hover {
  opacity: 0.8;
}

.form_control-radio + .form_control-label::before,
.form_control-radio + .form_control-label::after {
  border-radius: 100%;
}

.form_control-checkbox + .form_control-label::before,
.form_control-checkbox + .form_control-label::after {
  border-radius: 0;
}

.form_control-radio:checked + .form_control-label::before,
.form_control-checkbox:checked + .form_control-label::before {
  border-color: #00a4c9;
  background-color: #fff;
}

.form_control-radio:checked + .form_control-label::after,
.form_control-checkbox:checked + .form_control-label::after {
  top: 0.4rem;
  left: 0.4rem;
  width: 1rem;
  height: 1rem;
  background-color: #00a4c9;
}

.reading .result_lead {
  margin: 2.5rem 0 2rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.reading .result_lead > *:not(:last-of-type) {
  margin-right: 8rem;
}

.reading .result_lead-item {
  padding: 0.5rem 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.reading .result_lead-item dt {
  margin-right: 1.5rem;
  font-size: 15px;
  font-size: 1.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.reading .result_lead-item dt::after {
  content: "";
  display: inline-block;
  width: 4rem;
  height: 0.1rem;
  border-top: 0.1rem dotted #00a4c9;
  margin-left: 1rem;
}

.reading .result_lead-item dd {
  font-weight: bold;
}

.reading .result_display {
  width: 100%;
  text-align: left;
  opacity: 1;
  visibility: visible;
  height: auto;
  padding-top: 3rem;

  overflow: hidden;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.reading .result_display.is-open {
  opacity: 1;
  visibility: visible;
  height: auto;
  padding-top: 3rem;
}

.reading .result_display-heading {
  font-weight: normal;
  border-bottom: 0.1rem dashed #00a4c9;
  padding-bottom: 1rem;
  margin-bottom: 4rem;
  font-size: 16px;
  font-size: 1.6rem;
}

.form_control-select {
  display: block;
  position: relative;
  padding-left: calc(1.8rem + 1rem);
  border-style: solid;
  border-radius: 1.2rem;
  background-color: #00a4c9;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
}
.form_control-select option {
  color: black;
  background: white;
}

select.btn-primary {
  text-align-last: center; 
}

select option {
  color: black;
  background: white;
}
</style>
