<template>
  <div class="error-body">
    <p class="error-message">{{ error_message }}</p>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  components: {},
  data() {
    return {};
  },
  props: {
    error_message: {
      type: String,
      defalult: ""
    }
  },
  created: function() {},
  computed: {},
  methods: {}
};
</script>

<style scoped>
.error-body {
  text-align: center;
}
.error-message {
  color: red;
  font-size: 20px;
  text-align: center;
}
</style>