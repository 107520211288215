<template>
    <Form v-slot="{ handleSubmit, meta }" ref="form" :as="tag">
      <slot :handleSubmit="handleSubmit" :valid="meta.valid" />
    </Form>
  </template>
  
  <script>
  import { Form } from 'vee-validate'
  export default {
    name: 'ValidationObserver',
    components: {
      Form,
    },
    props: {
      tag: {
        type: String,
      },
    },
    methods: {
      reset() {
        return this.$refs.form.reset()
      },
      validate() {
        return this.$refs.form.validate()
      }
    }
  }
  </script>