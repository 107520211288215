<template>
  <div id="all">
    <vue-element-loading
      background-color="rgba(255, 255, 255, .7)"
      :active="loading"
      is-full-screen
      spinner="line-scale"
      color="#00a4c9"
    />
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">メンバー登録</h2>
          </div>
          <div class="content">
            <validation-observer v-slot="{ handleSubmit, valid }">
              <form
                class="form"
                @submit.prevent="
                handleSubmit(memberRegister)"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="form-group row">
                      <label for="horizontal-name-input" class="col-sm-4 col-form-label">
                        ユーザー名
                        <span class="badge badge-danger px-2 py-1 align-middle">必須</span>
                      </label>
                      <div class="col-wrap">
                        <validation-provider
                          v-slot="{ errors }" 
                          name="ユーザー名" 
                          rules="required|max:50"
                          v-model="member.memberName">
                          <input
                            class="form-control mb-1"
                            type="text"
                            placeholder="ユーザー名"
                            v-model="member.memberName"
                          />
                          <span v-if="errors" class="editable-error">
                          {{
                            errors[0]
                          }}
                          </span>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="horizontal-document-input" class="col-sm-4 col-form-label">
                        メールアドレス
                        <span class="badge badge-danger px-2 py-1 align-middle">必須</span>
                      </label>
                      <div class="col-wrap">
                        <validation-provider
                          v-slot="{ errors }"
                          v-model="member.memberEmail"
                          name="メールアドレス" 
                          rules="required|email">
                          <input
                            class="form-control mb-1"
                            type="email"
                            placeholder="メールアドレス"
                            v-model="member.memberEmail"
                          />
                          <span v-if="errors" class="editable-error">
                          {{
                            errors[0]
                          }}
                          </span>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="horizontal-document-input" class="col-sm-4 col-form-label">
                        パスワード
                        <span class="badge badge-danger px-2 py-1 align-middle">必須</span>
                      </label>
                      <div class="col-wrap col-wrap">
                        <validation-provider
                          v-slot="{ errors }"
                          v-model="member.memberPassword"
                          name="パスワード"
                          rules="required|min:8">
                          <input
                            class="form-control mb-1 col-sm-12"
                            placeholder="パスワード"
                            type="password"
                            v-model="member.memberPassword"
                          />
                          <span v-if="errors" class="editable-error col-12">
                          {{
                            errors[0]
                          }}
                          </span>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="horizontal-document-input" class="col-sm-4 col-form-label">
                        パスワード（確認）
                        <span class="badge badge-danger px-2 py-1 align-middle">必須</span>
                      </label>
                      <div class="col-wrap col-wrap">
                        <validation-provider
                          v-model="member.confirmPassword"
                          v-slot="{ errors }"
                          :rules="'required|min:8|confirmed:' + member.memberPassword"
                          name="パスワード（確認）">
                          <input
                            class="form-control mb-1 col-sm-12"
                            placeholder="パスワード（確認）"
                            type="password"
                            v-model="member.confirmPassword"
                          />
                          <span v-if="errors" class="editable-error col-12">
                          {{
                            errors[0]
                          }}
                          </span>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="btn_column">
                    <button
                      type="button" 
                      class="btn-display btn-primary"
                      :class="{ 'blocked btn-blocked': !valid }"
                      :disabled="!valid" 
                      @click="memberRegister($event)">
                      <span>登録</span>
                    </button>
                    <router-link to="/member_list">
                      <button type="button" class="btn-display btn-primary">
                        <span>キャンセル</span>
                      </button>
                    </router-link>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// official
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import axios from "axios";
// modules
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import { mapState } from "vuex";
// mixin
import toggleFunctions from "@/components/mixin/toggleFunctions";
import Authority from "@/components/mixin/authority.js";

export default {
  name: "RegistMember",
  mixins: [toggleFunctions, Authority], //toggleSidebar呼び出しのため
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {            
      currentUser: firebase.auth().currentUser,
      member: {
        memberName: "",
        memberEmail: "",
        memberPassword: "",
        confirmPassword: "",
      },
      loading: false,
      userIdToken: "",
      corpMstIdByFirebaseUserid: [],
      memberOperationAuthority: null,
      corpId: '',
      membersCount: '',
      maximumMembersCount: '',
    };
  },
  async mounted() {
    this.userIdToken = await firebase.auth().currentUser.getIdToken();
    this.loading = true;
    // ユーザ操作権限の有無チェック
    this.memberOperationAuthority = await this.getMemberOperationAuthority(this.userIdToken);
    if(!Boolean(this.memberOperationAuthority['authorities']['user_operation_authority'])) {
      alert("権限がありません。");
      this.$router.push("/");
    }else {
      this.loading = false;
    }
  },
  computed: {
    ...mapState([
        "memberId",
    ]),
  },
  methods: {
    // ユーザートークンの取得
    async renewUserIdToken() {
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
    },
    // メンバー追加処理
    async memberRegister(event) {
      event.preventDefault();
      this.loading = true;
      this.renewUserIdToken();

      // FirebaseUIDから企業ID取得
      this.corpMstIdByFirebaseUserid = await this.getCorpMstIdByMemberId(this.userIdToken, this.memberId);
      this.corpId = this.corpMstIdByFirebaseUserid['data'];

      const headers = { Authorization: "Bearer " + this.userIdToken };
      const params = {
        email: this.member.memberEmail,
        displayName: this.member.memberName,
        password: this.member.memberPassword,
        member_id: this.memberId
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/regist_member",
          params,
          { headers: headers }
        )
        .then((response) => {
          if(response.data.status) {
            alert("メンバーを登録しました。");
            this.$router.replace("/member_list");
          }else if(response.data.error_code == 'USER_NOT_HAVE_AUTHORITY'){
            alert("権限がないため、新規登録できません。");
          }else if(response.data.error_code == 'MEMBERS_LIMIT_EXCEEDED'){
            alert("メンバー登録枠の上限に達したため、新規登録できません。");
          }else if(response.data.error_code == 'ALREADY_EXISTS'){
            alert("このメールアドレスは既に別のアカウントで使用されています。");
          }else {
            alert("メンバーの登録に失敗しました。");
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
input {
  background: #eee;
  width: 500px;
  height: 50px;
  display: inline-flex;
  padding-left: 10px;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}
.badge-danger {
  color: #fff;
  background-color: #f46a6a
}
.btn_column {
  padding: 15px 0 15px 60px;
  width: 500px;
  display: inline-flex;
}
.blocked {
  pointer-events: none;
  color: #ccc;
}
.btn-blocked {
  background-image: linear-gradient(#ccc, #ccc);
  box-shadow: none;
  border: 1px solid #ddd;
  color: #fff
}
.form-group {
  margin-bottom: 3%;
}
.col-wrap {
  display:flex;
  flex-flow: column;
  margin:0 0 1em;
  align-items: center;
}
.editable-error {
  margin: 5px 0 0;
  color: #cc0000;
}
.card-body-editable-error {
  margin-bottom: 20px;
}
</style>
