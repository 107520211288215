<template>
  <div id="all">
    <div class="wrapper">
      <form class="reset-password">
        <div class="title">
          <h1>パスワードをリセット</h1>
        </div>
        <div class="input-box">
          メールアドレス
          <input type="email" placeholder="" v-model="email" />
        </div>
        <div class="btn-column">
          <button @click="resetPassword" type="submit" class="send-btn">
            送信
          </button>
        </div>
        <div>
          <p>
            <router-link to="/signin" class="link">サインイン</router-link>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    resetPassword: function(e) {
      e.preventDefault();
      let self = this;
      var auth = firebase.auth();
      var emailAddress = self.email;

      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function() {
          // Email sent.
          alert("パスワードリセットのメールを送信しました");
        })
        .catch(function(error) {
          // An error happened.
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  padding: 24px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
}
.reset-password {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-box {
  align-items: stretch;
  padding: 20px 20px 0;
  font-size: 12px;
}
input {
  display: block;
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid gray;
}
input:focus {
  border-bottom: 1px solid blue;
  outline: none;
}

.btn-column {
  display: flex;
  padding: 20px 20px 0;
  justify-content: flex-end;
}
.send-btn {
  background: #3f51b5;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  height: 36px;
  line-height: 35px;
  padding: 1px 16px 0;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
}
.link {
  text-decoration: underline #00a4c9;
}
</style>
