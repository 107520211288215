import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

export default {
  data() {
    return {};
  },
  methods: {
    getUsageTime() {
      const user = firebase.auth().currentUser;
      return firebase
        .firestore()
        .collection("usage_time")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (Number.isInteger(doc.data().time)) {
            return doc.data();
          } else {
            return doc
              .data()
              .time.get()
              .then((doc) => {
                return doc.data();
              });
          }
        })
        .catch((err) => {
          return false;
        });
    },
    async getProceedingTime(userIdToken) {
      const self = this;
      const headers = { Authorization: "Bearer " + userIdToken };
      const response = await axios.get(
        process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/get_proceeding_time",
        { headers: headers }
      );
      const proceeding_time = response.data.proceeding_time;
      return parseInt(proceeding_time);
    },
    // firestoreの新規レコード作成
    async createUsageTime() {
      const self = this;
      const user = firebase.auth().currentUser;
      const data = {
        time: 0,
        type: 0,
      };
      const res = await firebase
        .firestore()
        .collection("usage_time")
        .doc(user.uid)
        .set(data);
      return res;
    },
    // 利用時間の追加
    async addUsageTime(additionalTime) {
      const self = this;
      const user = firebase.auth().currentUser;
      const currentTime = await firebase
        .firestore()
        .collection("usage_time")
        .doc(user.uid)
        .get()
        .then((doc) => {
          return doc.data().time;
        })
        .catch((err) => {
          return false;
        });
      const res = await firebase
        .firestore()
        .collection("usage_time")
        .doc(user.uid)
        .set({
          time: currentTime + additionalTime,
        }, {
          merge: true
        })
        .then(() => {})
        .catch((err) => {
          return false;
        });
      return res;
    },
  },
};
