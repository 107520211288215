export default {
    methods: {
      // サイドバーの開閉
      toggleSidebar: function() {
        this.$refs.sidebar.toggleSide();
      },
  
      // modal
      modalSwitch: function(judge, modal_name) {
        if (judge == true) {
          this.$refs.l_modal.show(modal_name);
        } else {
          this.$refs.l_modal.hide(modal_name);
        }
      },
  
      // reload
      reload: function() {
        location.reload();
      }
    }
  };
  