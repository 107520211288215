import { createApp, h, configureCompat } from 'vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Firebase from "./firebase.js";
import "./assets/stylesheet/reset.css";
import "./assets/stylesheet/common.css";
import "./assets/stylesheet/icon.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import {
  faSpinner,
  faStop,
  faPause,
  faTrash,
  faEdit,
  faPlayCircle,
  faHeadset,
  faComments,
  faLanguage,
  faFileSignature,
  faTimesCircle,
  faDownload,
  faEye,
  faEyeSlash,
  faSave,
  faUndoAlt,
  faInfoCircle,
  faExternalLinkAlt,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import {
  faStopCircle,
  faPauseCircle,
  faCopy,
} from "@fortawesome/free-regular-svg-icons";
import TextareaAutosize from "vue-textarea-autosize";
import { modalPlugin, Modal } from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';
import VueElementLoading from "vue-element-loading";
import ValidationProvider from "./components/validations/ValidationProvider.vue";
import ValidationObserver from "./components/validations/ValidationObserver.vue";
import "./vee-validate";

library.add(
  faSpinner,
  faStop,
  faPause,
  faStopCircle,
  faPauseCircle,
  faTrash,
  faEdit,
  faHeadset,
  faComments,
  faLanguage,
  faFileSignature,
  faPlayCircle,
  faExternalLinkAlt,
  faTimesCircle,
  faCopy,
  faDownload,
  faEye,
  faEyeSlash,
  faSave,
  faUndoAlt,
  faInfoCircle,
  faEllipsisV
);
Firebase.init();

const app = createApp({
  render: () => h(App)
});

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)
app.component('font-awesome-layer-text', FontAwesomeLayersText)
app.component('Modal', Modal)
app.component("VueElementLoading", VueElementLoading)
app.component("ValidationObserver", ValidationObserver)
app.component("ValidationProvider", ValidationProvider)
app.use(router)
app.use(store)
app.use(TextareaAutosize)
app.use(modalPlugin)

configureCompat({
  MODE: 3,
});

app.mount('#app');
