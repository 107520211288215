export default {
  data() {
    return {};
  },
  methods: {
    toggle: function(node) {
      node.isOpen = !node.isOpen;
    },
    toggleSideSwitch: function() {
      this.$emit("sidebar");
      this.is_close = !this.is_close;
    },
    toggleSide: function() {
      this.is_close = !this.is_close;
    },
    //サイドバーの開け閉め
    toggleSidebar: function() {
        this.$refs.sidebar.toggleSide();
    },
    toggleUID: function() {
      this.uid_show = !this.uid_show;
    },
  }
};