import axios from "axios";
import "firebase/auth"
import "firebase/firestore"
import firebaseConfig from "../../../src/firebase";

export default {
  data() {
    return {};
  },
  methods: {
    async getIsInitialValified(userIdToken) {
      const idToken = userIdToken;
      const firebaseApiKey = process.env.VUE_APP_FIREBASE_API_KEY;
      try {
        const response = await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${firebaseApiKey}`, { idToken: idToken })

        if (response.data && response.data.users && response.data.users.length > 0) {
          const initialEmail = response.data.users[0].initialEmail;
          if (initialEmail) {
            return false;
          } else {
            return true;
          }
        } else {
          console.error('Error response:');
          return false;
        }
      } catch (error) {
        return false;
      }
    },
  }
};
