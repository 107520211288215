<template>
  <div>
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="wrapper">
          <div class="heading-inner">
            利用時間の追加
          </div>
          <div class="card plan_1">
            <div class="card-content">
              <h1 class="card-title">1時間: 300円</h1>
              <p class="card-text">1時間単位での追加が可能です。追加したい時間を入力してください。</p>
              <div class="card-form">
                <input type="number" v-model="quantity"/> 時間
              </div>
            </div>
            <div class="card-link">
              <a class="btn-display btn-primary" @click="sendAddingInformation">
                決定
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";

import StripeFunc from "@/components/mixin/StripeFunc.js";

export default {
  mixins: [StripeFunc],
  name: "StripeAddUsageTime",
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      // base
      currentUser: firebase.auth().currentUser,
      stripe_fs_info: "",
      quantity: 0,
      usageTimeUpdating: false,
    };
  },
  async created() {
    let self = this;
    self.stripe_fs_info = await self.getFSinfo(self.currentUser.uid);
  },
  computed: {},
  beforeRouteLeave(to, from, next) {
    if (this.quantity !== 0) {
      const answer = window.confirm(
        "編集が保存されていませんが、保存せずに遷移しますか？"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next()
    }
  },
  methods: {
    toggleSidebar: function() {
      this.$refs.sidebar.toggleSide();
    },
    sendAddingInformation() {
      let self = this
      if (self.isNumber(self.quantity) && self.quantity > 0){
        const quantity = self.quantity
        self.quantity = 0
        self.$router.push({ name: 'StripeAddSubscription', query: { quantity:quantity } })
      } else {
        alert("0以上の整数値で時間を入力してください")
      }
    },
    // 0以上の整数のみ許すバリデーション
    isNumber(val){
      let regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
      return regexp.test(val);
    }
  },
};
</script>

<style scoped>
input {
  background: #eee;
  width: 4rem;
  height: 3rem;
  display: flex;
  padding-left: 10px;
}
.wrapper {
  position: relative;
  margin: 6vw auto;
  max-width: 40vw;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  flex-direction: column;
}
.card {
  margin: 30px auto;
  width: 350px;
  background: #fff;
}
.card-img {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  height: auto;
}
.title {
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}
.card-form{
  display: flex;
}
.card-link {
  text-align: center;
  padding: 20px;
}
.card-link a {
  text-decoration: none;
  margin: 0 10px;
}
</style>
