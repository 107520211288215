<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">テキスト整形ルール</h1>
        <button @click="$emit('close')" class="close-button">
          × 
        </button>
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>ルール</th>
              <th>整形前</th>
              <th>整形後</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>太字</td>
              <td>
                **ABC**<br>
                __abc__
              </td>
              <td>
                <strong>ABC</strong><br>
                <strong>abc</strong>
              </td>
            </tr>
            <tr>
              <td>斜体</td>
              <td>
                *ABC*<br>
                _abc_
              </td>
              <td>
                <em>ABC</em><br>
                <em>abc</em>
              </td>
            </tr>
            <tr>
              <td>太字&斜体</td>
              <td>
                ***ABC***<br>
                ___abc___
              </td>
              <td>
                <em><strong>ABC</strong></em><br>
                <em><strong>abc</strong></em>
              </td>
            </tr>
            <tr>
              <td>取り消し線</td>
              <td>
                ~~ABC~~
              </td>
              <td>
                <del>ABC</del>
              </td>
            </tr>
            <tr>
              <td>リンク</td>
              <td>
                [TEXTA](https://texta.cloud/)
              </td>
              <td>
                <a href="https://texta.cloud/">TEXTA</a>
              </td>
            </tr>
            <tr>
              <td>外部参照リンク</td>
              <td>
                [TEXTA]: https://texta.cloud/<br>
                [TEXTAを見る][TEXTA]
              </td>
              <td>
                <a href="https://texta.cloud/">TEXTAを見る</a>
              </td>
            </tr>
            <tr>
              <td>箇条書き</td>
              <td>
                - リスト１<br>
                &nbsp;&nbsp;- リスト１－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;- リスト１－１－１<br>
                + リスト２<br>
                &nbsp;&nbsp;+ リスト２－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;+ リスト２－１－１<br>
                * リスト３<br>
                &nbsp;&nbsp;* リスト３－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;* リスト３－１－１<br>
              </td>
              <td>
                <ul>
                  <li>
                    リスト１
                    <ul>
                      <li>
                        リスト１－１
                        <ul>
                          <li>リスト１－１－１</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    リスト２
                    <ul>
                      <li>
                        リスト２－１
                        <ul>
                          <li>リスト２－１－１</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    リスト３
                    <ul>
                      <li>
                        リスト３－１
                        <ul>
                          <li>リスト３－１－１</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>番号付き箇条書き</td>
              <td>
                1. リスト１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－１－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－２<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－２－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－２－２<br>
                1. リスト２<br>
                &nbsp;&nbsp;&nbsp;&nbsp;1. リスト２－１<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト２－１－１<br>
              </td>
              <td>
                <ol>
                  <li>
                    リスト１
                    <ol>
                      <li>
                        リスト１－１
                        <ol>
                          <li>リスト１－１－１</li>
                        </ol>
                      </li>
                      <li>
                        リスト１－２
                        <ol>
                          <li>リスト１－２－１</li>
                          <li>リスト１－２－２</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    リスト２
                    <ol>
                      <li>
                        リスト２－１
                        <ol>
                          <li>リスト２－１－１</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>見出し</td>
              <td>
                # 見出し１<br>
                ## 見出し２<br>
                ### 見出し３
              </td>
              <td>
                <h1>見出し１</h1>
                <h2>見出し２</h2>
                <h3>見出し３</h3>
              </td>
            </tr>
            <tr>
              <td>引用</td>
              <td>
                > 引用<br>
                >> 多重引用
              </td>
              <td>
                <blockquote>
                  引用
                  <blockquote>
                    多重引用
                  </blockquote>
                </blockquote>
              </td>
            </tr>
            <tr>
              <td>水平線</td>
              <td>
                ___<br>
                ***<br>
                ---
              </td>
              <td>
                <hr><br>
                <hr><br>
                <hr>
              </td>
            </tr>
            <tr>
              <td>表</td>
              <td>
                |&nbsp;AAAAA&nbsp;|&nbsp;BBBBB&nbsp;|&nbsp;CCCCC&nbsp;|<br>
                |---|---|---|<br>
                |&nbsp;a&nbsp;|&nbsp;b&nbsp;|&nbsp;c&nbsp;|<br>
                |&nbsp;d&nbsp;|&nbsp;e&nbsp;|&nbsp;f&nbsp;|<br>
                <br>
                |&nbsp;左揃え&nbsp;|&nbsp;中央揃え&nbsp;|&nbsp;右揃え&nbsp;|<br>
                |:---|:---:|---:|<br>
                |&nbsp;a&nbsp;|&nbsp;b&nbsp;|&nbsp;c&nbsp;|<br>
                |&nbsp;d&nbsp;|&nbsp;e&nbsp;|&nbsp;f&nbsp;|<br>
              </td>
              <td>
                <table>
                  <thead>
                    <tr>
                      <th>AAAAA</th>
                      <th>BBBBB</th>
                      <th>CCCCC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>a</td>
                      <td>b</td>
                      <td>c</td>
                    </tr>
                    <tr>
                      <td>d</td>
                      <td>e</td>
                      <td>f</td>
                    </tr>
                  </tbody>
                </table>
                <br>
                <table>
                  <thead>
                    <tr>
                      <th align="left">左揃え</th>
                      <th align="center">中央揃え</th>
                      <th align="right">右揃え</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="left">a</td>
                      <td align="center">b</td>
                      <td align="right">c</td>
                    </tr>
                    <tr>
                      <td align="left">d</td>
                      <td align="center">e</td>
                      <td align="right">f</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>コード</td>
              <td>
                ```<br>
                print 'hello'<br>
                ```<br>
                ~~~<br>
                print 'hello'<br>
                ~~~<br>
                先頭に半角スペース4個<br>
                &nbsp;&nbsp;&nbsp;&nbsp;print 'hello'
              </td>
              <td>
                <p>
                  <pre><code>print 'hello'</code></pre>
                </p><br>
                <p>
                  <pre><code>print 'hello'</code></pre>
                </p><br>
                <p>
                  <pre><code>print 'hello'</code></pre>
                </p>
              </td>
            </tr>
            <tr>
              <td>インラインコード</td>
              <td>
                これは`インラインコード`です
              </td>
              <td>
                これは<code>インラインコード</code>です
              </td>
            </tr>
            <tr>
              <td>チェックリスト</td>
              <td>
                - [ ] リスト１<br>
                - [x] リスト２
              </td>
              <td>
                <ul>
                  <li>
                    <input type="checkbox" disabled>&nbsp;リスト１
                  </li>
                  <li>
                    <input type="checkbox" disabled checked>&nbsp;リスト２
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  text-align: initial !important;
  padding: 0 !important;
  overflow-y: initial !important;
  --base-size-16: 0 !important;
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
  height: 95vh;
  width: 90vw;
  max-width: 1080px;
  overflow: hidden;
  position: relative; 
}

.modal-header {
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-image: linear-gradient(90deg, #00a4c9, #4067a3);
  color: #fff;
}

.modal-title {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
}

.close-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.close-button:hover {
  outline: 0;
  background-color: rgba(255, 255, 255,.2);
  -webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255,.2);
  box-shadow: 0 0 0 4px rgba(255, 255, 255,.2);
}

.table-container {
  overflow-x: auto;
  height: calc(100% - 50px);
}

.table-container > table {
  border-collapse: collapse;
  width: 90%;
  margin: 20px auto 20px auto;
}

.table-container > table > thead > tr > th,
.table-container > table > tbody > tr > td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-container > table > thead > tr > th {
  background-color: #f2f2f2;
}

.table-container > table > thead > tr > th:first-child {
  width: 20%;
}

.table-container > table > thead > tr > th:last-child {
  width: 30%;
}
</style>