<template>
  <div id="all">
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">パスワード変更</h2>
          </div>
          <div class="content">
            <div class="dummy">
              <input type="text" v-model="email" />
            </div>
            <div class="profile">
              <p class="profile-title">現在のパスワード</p>
              <input
                type="password"
                v-model="password"
                autocomplete="current-password"
                required
              />
            </div>
            <div class="profile">
              <p class="profile-title">
                新しいパスワード (12文字以上かつ『英大文字』『英小文字』『数字』『記号』の4種のうち3種以上必須)
              </p>
              <input
                type="password"
                v-model="new_password"
                autocomplete="new-password"
                minlength="12"
                required
              />
            </div>
            <div class="profile">
              <p class="profile-title">新しいパスワード（確認）</p>
              <input
                type="password"
                v-model="new_password_sub"
                minlength="12"
                required
              />
            </div>
            <div class="btn_column">
              <button
                @click="updateProfilePassword"
                type="button"
                class="btn-display btn-primary"
              >
                <span>保存</span>
              </button>
              <router-link to="/account">
                <button type="button" class="btn-display btn-primary">
                  <span>キャンセル</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";
import toggleFunctions from "../mixin/toggleFunctions";
import authentication from "../mixin/authentication";

export default {
  name: "EditName",
  mixins: [toggleFunctions,authentication], //toggleSidebar呼び出しのため
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      email: firebase.auth().currentUser.email,
      password: "",
      new_password: "",
      new_password_sub: "",
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    updateProfilePassword: function(e) {
      if (this.new_password == this.new_password_sub) {
        const judge = this.validatePassword(this.new_password);
        if (!judge["status"]) {
          alert(judge["message"]);
          return false;
        } else {
        const user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          this.password
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            user
              .updatePassword(this.new_password)
              .then(() => {
                alert("パスワードが更新されました");
                this.$router.replace("/account");
              })
              .catch((error) => {
                if (error.code == "auth/weak-password") {
                  alert(
                    "パスワードが弱いです。12文字以上かつ英字と数字を混ぜてください。"
                  );
                } else {
                  alert("パスワードの更新に失敗しました！");
                }
              });
          })
          .catch((error) => {
            alert("現在のパスワードが誤っています");
          });
        } 
      } else {
        alert("新しいパスワードが一致していません。");
      }
    },
  },
};
</script>

<style scoped>
input {
  background: #eee;
  width: 500px;
  height: 50px;
  display: inline-flex;
  padding-left: 10px;
}
.dummy input {
  width: 0;
  height: 0;
}

.btn_column {
  padding: 15px 0 15px 60px;
  width: 500px;
  display: inline-flex;
}
.profile {
  padding: 0 0 15px 60px;
}
.profile-title {
  font-weight: bold;
  font-size: 2rem;
}
.profile-value {
  font-size: 1.8rem;
}
</style>
