<template>
  <div>
    <MyHeader @sidebar="toggleSidebar" />
    <modal
      name="addCard"
      modal-class="modal modal-lg"
      wrapper-class="modal-wrapper"
      :enable-close="false"
    >
      <div class="modal-content">
        <div class="modal-header">
          <p>少々お待ちください...</p>
        </div>
        <div class="modal-body">
          <vue-element-loading
            background-color="rgba(255, 255, 255, .7)"
            :active="is_loading" 
            is-full-screen 
            spinner="line-scale"
            color="#00a4c9"
          />
        </div>
      </div>
    </modal>
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="wrapper">
          <div class="heading-inner">クレジットカード情報の追加</div>
          <div class="card plan_1">
            <div class="card-content">
              <form method="post" id="payment-form">
                <div class="form-row">
                  <!-- ここでエレメントのマウント -->
                  <label for="card-element"
                    >クレジットカードを入力してください</label
                  >
                  <div id="card-element">
                    <!-- ここに stripe elements が入る -->
                  </div>

                  <!-- element のエラーが入る -->
                  <div id="card-errors" role="alert"></div>
                  <error-message :error_message="error_message"></error-message>
                </div>
              </form>
            </div>
            <div class="card-link">
              <br />
              <button class="btn-display btn-primary" @click="createStripeCustomer">クレジットカードの登録</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// official
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
// modules
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";
import ErrorMessage from "@/components/modules/ErrorMessage.vue";

// mixin
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import StripeFunc from "@/components/mixin/StripeFunc.js";

export default {
  mixins: [ArrangeDate, StripeFunc, BaseFunctions],
  name: "",
  components: {
    MyHeader,
    MySidebar,
    ErrorMessage,
  },
  data: () => ({
    // base
    error_message: "",
    currentUser: firebase.auth().currentUser,

    // 判定
    is_loading: true,
    on_change: false,

    // props
    stripe: "",
    plan_name: "",

    cardElement: "",
    // カード情報の登録
    sources: {},
    newCreditCard: {},
  }),
  async created() {
    let self = this;
    let stripe_fs_info = await self.getFSinfo(self.currentUser.uid);
    self.stripe_fs_info = stripe_fs_info;
    self.next_plan = self.$route.query.next_plan;

    if (self.currentUser == void 0) {
      self.on_change = false;
      self.$router.push("/account");
    }
    // stripe インスタンス作成
    this.getStripe().then((res) => {
      // stripe と elements のインスタンス作成
      let stripe = res;
      this.stripe = stripe;
      let elements = res.elements();

      var style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };

      // card Element のインスタンスを作成
      var card = elements.create("card", { style: style });

      // 要素にマウント
      card.mount("#card-element");

      // イベントリスナーを追加する
      card.addEventListener("change", (event) => {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
        this.on_change = !event.empty;
      });
      // card element をメモリに残しておく
      this.card = card;
    });
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.on_change === true) {
      const answer = window.confirm(
        "編集が保存されていませんが、保存せずに遷移しますか？"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next()
    }
  },
  methods: {
    // stripe_customer collection に ユーザのdocument を追加する
    async addFSCustomer(token, stripe_res) {
      let self = this;
      let sub_free = {
        id: "",
        amount: 0,
        plan_id: "free",
        item_plan: "free",
        status: { behavior: "free", invoice: "" },
      };
      let db_result = await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(self.currentUser.uid)
        .set(
          {
            uid: self.currentUser.uid,
            stripe_id: stripe_res.data.id,
            email: self.currentUser.email,
            token: token,
            source: token.id,
            subscription: sub_free,
          },
          { merge: true }
        );
      return db_result;
    },

    // カードの登録（stripe側、fs側にデータを追加する）
    async createStripeCustomer() {
      const self = this;
      self.is_loading = true;
      self.show();

      // 1. tokenの取得
      const get_token = await self.getToken();
      if (get_token.error !== void 0) {
        self.is_loading = false;
        self.hide();
        return "error";
      }

      // 2. stripe 側に登録
      const addStripeCustomer = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("addStripeCustomer");
      const stripe_res = await addStripeCustomer({
        email: self.currentUser.email,
        token: get_token.token,
      });
      if (stripe_res.data.error !== void 0) {
        // セキュリティーコードエラー
        if (stripe_res.data.error.code == "incorrect_cvc") {
          self.error_message = "セキュリティコードが間違えています";
        }
        // セキュリティーコードエラー
        else if (stripe_res.data.error.code == "incorrect_expiry") {
          self.error_message = "有効期限が間違っています";
        }
        // カードが決済に失敗
        else if (stripe_res.data.error.code == "card_declined") {
          self.error_message = "このカードは使用できません";
        }
        // 処理中のエラー
        else if (stripe_res.data.error.code == "processing_error") {
          self.error_message = "処理中にエラーが発生しました";
        } else if (stripe_res.data.error.code == "expired_card") {
          self.error_message = "カードの有効期限が間違っています";
        } else if (stripe_res.data.error) {
          self.error_message =
            "エラーが発生しました。もう一度入力情報を確かめてください";
        }
        self.is_loading = false;
        self.hide();
        return "error";
      }

      // 3. firestore に登録
      let add_firestore = await this.addFSCustomer(get_token.token, stripe_res);
      if (add_firestore !== void 0) {
        self.is_loading = false;
        self.hide();
        return "error";
      }

      self.is_loading = false;
      self.hide();
      // 5. 全部通ると遷移
      self.on_change = false;
      self.$router.push({
        name: "StripeSubscription",
        query: {
          next_plan: self.next_plan,
        },
      });
    },

    show() {
      this.$modal.show("addCard");
    },
    hide() {
      this.$modal.hide("addCard");
    },
  },
};
</script>
<style scoped>
.test {
  margin: 10px 10px 10px 10px;
  display: block;
}
.card--format {
  justify-content: center;
  margin: 48px;
  max-width: 980px;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f1f1f1;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.wrapper {
  position: relative;
  margin: 6vw auto;
  width: 40vw;
  max-width: 60vw;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  flex-direction: column;
}
.card {
  margin: 30px auto;
  width: 30vw;
  background: #fff;
  border-radius: 5px;
}
.card-img {
  border-radius: 5px 5px 0 0;
  max-width: 100%;
  height: auto;
}
.card-content {
  padding: 20px;
}
.title {
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}
.card-text {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}
.card-link {
  text-align: center;
  color: white;
  padding: 20px;
}
.card-link a {
  text-decoration: none;
  color: #0bd;
  margin: 0 10px;
}

/* modal */
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.modal-content {
  height: 250px;
}

.modal-header {
  height: 20%;
  padding: 5px 25px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.modal-header p {
  height: 20%;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}
.modal-body {
  text-align: center;
  height: 60%;
  padding: 5px 25px;
}
.modal-footer {
  position: relative;
  height: 20%;
  padding: 5px 25px;
  border-top: 1px solid #ddd;
}
.footer-left {
  color: #ea352d;
  position: absolute;
  top: 50%;
  left: 13%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.footer-right {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
</style>
