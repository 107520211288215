import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

export default {
  data() {
    return {};
  },
  methods: {
    isLongPassword(password) {
      const regexp = /[A-Za-z0-9!-\/:-@[-`{-~]{12,100}$/i;
      if (regexp.test(password)) {
        return true;
      } else {
        return false;
      }
    },
    isNumberPw(password) {
      const regexp = /\d/g;
      if (regexp.test(password)) {
        return true;
      } else {
        return false;
      }
    },
    isLowercasePw(password) {
      const regexp = /[a-z]/g;
      if (regexp.test(password)) {
        return true;
      } else {
        return false;
      }
    },
    isUppercasePw(password) {
      const regexp = /[A-Z]/g;
      if (regexp.test(password)) {
        return true;
      } else {
        return false;
      }
    },
    isSymbolPw(password) {
      const regexp = /[!-\/:-@[-`{-~]/gi;
      if (regexp.test(password)) {
        return true;
      } else {
        return false;
      }
    },
    checkInfoFillup(uid) {
      const db = firebase.firestore();
      let docRef = db.collection("stripe_customers").doc(uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let userData = doc.data();
            // console.log("Document data:", doc.data());
            // function NoneEmpty(arr) {
            //   for (var i = 0; i < arr.length; i++) {
            //     if (arr[i] === "") return false;
            //   }
            //   return true;
            // }
            // var isAllFillUp = NoneEmpty(Object.values(userData.questionnaire));

            if (userData.questionnaire) {
              this.$router.push("/");
            } else {
              this.$router.push({
                name: "updateInfo",
                params: { userData: JSON.stringify(userData) },
              });
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    validatePassword(password) {
      const isLong = this.isLongPassword(password); // 長さ(12文字以上)
      if (!isLong) {
        return {
          status: false,
          message: "パスワード12文字以上で設定してください",
        };
      }

      const isNumber = this.isNumberPw(password); // 数字
      const isLowercase = this.isLowercasePw(password); // 小文字
      const isUppercase = this.isUppercasePw(password); // 大文字
      const isSymbol = this.isSymbolPw(password); // 記号
      const judgeList = [isNumber, isLowercase, isUppercase, isSymbol];

      var messageList = [];
      for (let i = 0; i < judgeList.length; i++) {
        var jugde_bool = judgeList[i];
        if (!jugde_bool) {
          if (i == 0) {
            messageList.push("・半角数字");
          }
          if (i == 1) {
            messageList.push("・半角英子文字");
          }
          if (i == 2) {
            messageList.push("・半角英大文字");
          }
          if (i == 3) {
            messageList.push("・記号 !-/:-@[-`{-~");
          }
        }
      }

      const messageNum = messageList.length;
      if (messageNum > 1) {
        var return_message =
          `パスワードに以下の文字種を残り` +
          (messageNum - 1) +
          "種類含めてください\n";
        for (let i = 0; i < messageNum; i++) {
          return_message += messageList[i] + "\n";
        }
        return { status: false, message: return_message };
      } else {
        return { status: true };
      }
    },
    async signIn(e) {
      const self = this;
      // var userObj = firebase.auth().currentUser;
      e.preventDefault();
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          let userObj = firebase.auth().currentUser;
          this.checkInfoFillup(userObj.uid);
        })
        .catch((error) => {
          if (error.code == "auth/invalid-email") {
            alert("メールアドレスまたはパスワードが異なります。");
          } else if (error.code == "auth/user-not-found") {
            alert("アカウントが見つかりません。");
          } else if (error.code == "auth/wrong-password") {
            alert("メールアドレスまたはパスワードが異なります。");
          } else if (error.code == "auth/user-disabled") {
            self.error_message = "このメールアドレスは以前使われていたようです";
            self.show();
          } else if(error.code === 'auth/too-many-requests') {
            alert("ログイン試行回数が上限を超えたため一時的にアカウントが無効化されました。一定時間後に再度お試しいただくか、パスワードをリセットすることで復元することができます。");
          } else {
            alert(error.message);
          }
          throw error;
        });
    },
    async updateInfo(e) {
      e.preventDefault();
      let user = firebase.auth().currentUser;
      // firestoreへの確認
      await this.updateFirestore(
        user.uid,
        this.company_name,
        this.company_size,
        this.position,
        this.occupation
      ).catch((error) => {
        alert("項目の登録ができませんでした");
        throw error;
      });

      // すべての処理が完了したらルート画面へ移動
      alert("アカウントの更新が完了しました！", user.email);
      this.$router.replace("/");
    },
    async signUp(e) {
      e.preventDefault();
      if (this.password == this.password_sub) {
        // パスワードのバリデーション
        const judge = this.validatePassword(this.password);
        if (!judge["status"]) {
          alert(judge["message"]);
          return false;
        }

        // firebaseのユーザー登録
        await firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .catch((error) => {
            if (error.code == "auth/email-already-in-use") {
              alert(
                "このメールアドレスは登録済み、または以前使用されています。\n再ログインすることで利用の再開が可能です。"
              );
            } else if (error.code == "auth/weak-password") {
              alert("6文字以上かつ英字と数字を混ぜてください。");
            } else if (error.code == "auth/invalid-email") {
              alert(
                "メールアドレスは'***@***.com'、'***@***.ne.jp'、'***@***.co.jp'などの形にしてください"
              );
            } else {
              alert(error.message);
            }
          });

        let user = firebase.auth().currentUser;

        // firestoreへの確認
        await this.registerFirestore(
          user.uid,
          this.company_name,
          this.company_size,
          this.position,
          this.occupation
        ).catch((error) => {
          alert("項目の登録ができませんでした");
          throw error;
        });

        // 名前の登録
        await user
          .updateProfile({
            displayName: this.name,
          })
          .catch((error) => {
            alert("名前が登録できませんでした");
            throw error;
          });

        // 認証メール送信
        await user.sendEmailVerification().catch((error) => {
          alert("確認メールが送れませんでした");
          throw error;
        });

        // すべての処理が完了したらルート画面へ移動
        alert("アカウントの新規作成が完了しました！", user.email);
        this.$router.replace("/");
      } else {
        alert("パスワードが一致していません");
      }
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then((user) => {
          alert("サインアウトしました");
          this.$router.push("/signin");
        })
        .catch((error) => {
          alert("サインアウトに失敗しました");
        });
    },
    async registerFirestore(
      uid,
      company_name,
      company_size,
      position,
      occupation
    ) {
      // stripe_info埋める
      const current_time = Math.floor(Date.now() / 1000);
      const company_size_num = {
        "1. 0-100人": 1,
        "2. 100-1000人": 2,
        "3. 1000-10000人": 3,
        "4. 10000人以上": 4,
      };
      const occupation_num = {
        "1. 営業": 1,
        "2. 経理": 2,
        "3. 企画・管理": 3,
        "4. 事務・アシスタント": 4,
        "5. 販売・サービス職": 5,
        "6. 専門職（コンサルや監査法人など）": 6,
        "7. 金融系専門職": 7,
        "8. 公務員・教員・農林水産関連職": 8,
        "9. 技術職": 9,
        "10. 医療系専門職": 10,
        "11. クリエイター・クリエイティブ職": 11,
        "12. その他": 12,
      };
      const stripe_fs_info = {
        subscription: {
          item_plan: "free",
          status: {
            behavior: "",
            before_plan: "",
            invoice: firebase.firestore.Timestamp.now(),
            updated_at: current_time,
          },
          card: "",
        },
        questionnaire: {
          company_name: company_name,
          company_size: company_size_num[company_size],
          position: position,
          occupation: occupation_num[occupation],
        },
      };
      await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(uid)
        .set(stripe_fs_info);
    },
    async updateFirestore(
      uid,
      company_name,
      company_size,
      position,
      occupation
    ) {
      const company_size_num = {
        "1. 0-100人": 1,
        "2. 100-1000人": 2,
        "3. 1000-10000人": 3,
        "4. 10000人以上": 4,
      };
      const occupation_num = {
        "1. 営業": 1,
        "2. 経理": 2,
        "3. 企画・管理": 3,
        "4. 事務・アシスタント": 4,
        "5. 販売・サービス職": 5,
        "6. 専門職（コンサルや監査法人など）": 6,
        "7. 金融系専門職": 7,
        "8. 公務員・教員・農林水産関連職": 8,
        "9. 技術職": 9,
        "10. 医療系専門職": 10,
        "11. クリエイター・クリエイティブ職": 11,
        "12. その他": 12,
      };
      const stripe_fs_info = {
        questionnaire: {
          company_name: company_name,
          company_size: company_size_num[company_size],
          position: position,
          occupation: occupation_num[occupation],
        },
      };
      await firebase
        .firestore()
        .collection("stripe_customers")
        .doc(uid)
        .set(stripe_fs_info, {
          merge: true
        });
    },
  },
};
