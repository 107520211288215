import AllRules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { configure, defineRule } from "vee-validate"
import ja from '@vee-validate/i18n/dist/locale/ja.json'


Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule])
})

configure({
    generateMessage: localize({
      ja,
    }),
  })

localize('ja');

