import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"; // 追加
import store from "./store";

// ToDo: firebaseの認証情報を変更する
var config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: "texta-prod.firebaseapp.com",
  projectId: "texta-prod",
  storageBucket: "texta-prod.appspot.com",
  messagingSenderId: "766681913555",
  appId: "1:766681913555:web:1171646900f96c7a581c26",
  measurementId: "G-Y1ZVJEBPW1"
};

export default {
  // 初期化
  init() {
    firebase.initializeApp(config);
    // ログアウトするまで認証状態を保持
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    // firestore初期化
    firebase.firestore().settings({ merge: true }); // timestampsInSnapshots: true });
  },
  onAuth() {
    firebase.auth().onAuthStateChanged((user) => {
      user = user ? user : {};
      store.commit("onAuthStateChanged", user);
    });
  },
};
