<template>
  <div id="all">
    <MyHeader @sidebar="toggleSidebar"/>
    <div class="container">
      <MySidebar ref="sidebar"/>
      <main class="main">
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">アカウント名変更</h2>
          </div>
          <div class="content">
            <div class="profile">
              <p class="profile-title">現在のアカウント名</p>
              <p class="profile-value">{{ user.displayName }}</p>
            </div>
            <div class="profile">
              <p class="profile-title">新しいアカウント名</p>
              <input type="text" v-model="name" required>
            </div>
            <div class="dummy">
              <input type="text">
            </div>
            <div class="profile">
              <p class="profile-title">現在のパスワード</p>
              <input type="password" v-model="password" required>
            </div>
            <div class="btn_column">
              <button @click="updateProfile" type="button" class="btn-display btn-primary">
                <span>保存</span>
              </button>
              <router-link to="/account">
                <button type="button" class="btn-display btn-primary">
                  <span>キャンセル</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import MyHeader from '../globals/MyHeader.vue';
import MySidebar from '../globals/MySidebar.vue';
import toggleFunctions from "../mixin/toggleFunctions";

export default {
  name: 'EditName',
  mixins: [toggleFunctions], //toggleSidebar呼び出しのため
  components: {
    MyHeader,
    MySidebar
  },
  data() {
    return {
      name: "",
      password: ""
    }
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    }
  },
  methods: {
    updateProfile: function(e) {
      const user = firebase.auth().currentUser;
      var credential = firebase.auth.EmailAuthProvider.credential(user.email, this.password)
      if (!this.name || this.name != user.displayName) {
        user.reauthenticateWithCredential(credential).then(() => {
          user.updateProfile({
            displayName: this.name
          }).then(() => {
            alert("アカウント名が更新されました");
            this.$router.replace('/account');
          }).catch((error) => {
            alert("アカウント名の更新に失敗しました！");
          });
        }).catch((error) => {
          alert("パスワードが誤っています");
        })
      } else {
        alert("新しいアカウント名を入力してください")
      }
    }
  }
}
</script>

<style scoped>
input {
  background: #eee;
  width: 500px;
  height: 50px;
  display: inline-flex;
  padding-left: 10px;
}
.dummy input {
  width: 0;
  height: 0;
}

.btn_column {
  padding: 15px 0 15px 60px;
  width: 500px;
  display: inline-flex;
}
.profile {
  padding: 0 0 15px 60px;
}
.profile-title {
  font-weight: bold;
  font-size: 2.0rem;
}
.profile-value {
  font-size: 1.8rem;
}
</style>