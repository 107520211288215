import axios from "axios";
import "firebase/auth"
import "firebase/firestore"

export default {
  data() {
    return {
    };
  },
  methods: {
    // firebase_user_idからmember_msts.corp_mst_idを取得
    async getCorpMstIdByMemberId(userIdToken, memberId) {
      const headers = { Authorization: "Bearer " + userIdToken };
      const params = {
        member_id: memberId
      };
      try {
        const response = await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_corp_mst_id_by_member_id",
            params,
            { headers: headers }
          );
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },

    // member_mstsとmember_authority_mstsを内部結合したデータを取得
    // member_msts.firebase_authentication_uidが引数と等しい
    async getMemberOperationAuthority(userIdToken) {
      const headers = { Authorization: "Bearer " + userIdToken };
      try {
        const response = await axios
          .get(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_member_operation_authority_by_firebase_user_id",
            { headers: headers }
          );
        if (response.data) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    // メンバー登録上限取得
    async getMaximumMembersCountByCorpId(userIdToken, corpId) {
      const headers = { Authorization: "Bearer " + userIdToken };
      const params = {
        corp_id: corpId,
      };
      try {
        const response = await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_maximum_members_count_by_corp_id",
            params,
            { headers: headers }
          );
        if (response.data) {
          return response.data.maximum_members_count;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },

    // メンバー登録数取得
    async getMembersCountByCorpId(userIdToken, corpId) {
      const headers = { Authorization: "Bearer " + userIdToken };
      const params = {
        corp_id: corpId,
      };
      try {
        const response = await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_members_count_by_corp_id",
            params,
            { headers: headers }
          );
        if (response.data) {
          return response.data.members_count;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
  }
}
